import vendors from './analyticsVendors'
// const TRACKING_ENABLED = process.env.VUE_APP_TRACKING_ENABLED === 'true'
const TRACKING_DEBUG_MODE = process.env.VUE_APP_TRACKING_DEBUG_MODE === 'true'

/**
 * Identify user in required analytics platforms
 * @param {string} userId userId
 * @param {object} meta user traits
 */
function identify(userId, meta) {
  // if (TRACKING_ENABLED) {
  // }
  vendors.identifyLogRocket(userId, meta)
  if (meta) vendors.identifyCrisp(meta)
  // eslint-disable-next-line
  if (TRACKING_DEBUG_MODE) console.debug('identify()', userId, meta)
}

// /**
//  * Add user properties/attributes to analytics profiles
//  * @param {object} props
//  */
// function addUserProps(userId, props) {
//   if (TRACKING_ENABLED) vendors.addPropsSegment(userId, props)
//   // eslint-disable-next-line
//   if (TRACKING_DEBUG_MODE) console.debug('addUserProps()', userId, props)
// }

// /**
//  * Track an analytics event
//  * @param {string} event
//  * @param {object} meta
//  */
// function track(event, meta) {
//   if (TRACKING_ENABLED) vendors.trackSegment(`X: ${event}`, meta)
//   // eslint-disable-next-line
//   if (TRACKING_DEBUG_MODE) console.debug('track()', `X: ${event}`, meta)
// }

// /**
//  * Track an analytics pageview event
//  * @param {string} event
//  * @param {object} meta
//  */
// function page() {
//   if (TRACKING_ENABLED) vendors.pageSegment()
//   // eslint-disable-next-line
//   if (TRACKING_DEBUG_MODE) console.debug('page()')
// }

// /**
//  * Identify group
//  * @param {string} userId
//  * @param {string} groupId
//  * @param {object} traits
//  */
// function group(userId, groupId, traits) {
//   if (TRACKING_ENABLED) vendors.groupSegment(userId, groupId, traits)
//   // eslint-disable-next-line
//   if (TRACKING_DEBUG_MODE) console.debug('group()', userId, groupId, traits)
// }

export default {
  identify,
  // track,
  // addUserProps,
  // page,
  // group,
}
