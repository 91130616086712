<script>
import Login from './components/Login'
import Workspaces from './components/workspaces'
import Spinner from './components/Base/Spinner'
import Toast from './components/plugins/toast'
import NewVersionAlert from './components/NewVersionAlert'
import auth from '@/lib/auth'
import analytics from '@/lib/analytics'
import WorkspaceSetup from '@/views/onboarding/WorkspaceSetup'
import PlanChangeDrawer from '@/components/PlanChangeDrawer'

export default {
  name: 'App',
  components: {
    Login,
    Spinner,
    Workspaces,
    Toast,
    NewVersionAlert,
    WorkspaceSetup,
    PlanChangeDrawer,
  },
  data() {
    return {
      loading: true,
      email: '',
      loggedOut: false,
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    showWorkspacesList() {
      return this.$store.state.showWorkspacesList
    },
    activeState() {
      if (this.loading) return 'loading'
      else if (this.$route.meta.public) return 'app'
      else if (!this.loggedOut && this.user.email && this.$route.meta.onboarding) return 'onboarding'
      else if (this.loggedOut || !this.user.email) return 'login'
      else if (this.showWorkspacesList) return 'workspaces'
      else if (this.user.email && this.user.firstName && this.user.lastName) return 'app'
      else return 'workspaces'
    },
  },
  async created() {
    try {
      // Handle service worker refresh
      document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.loading) return
        this.loading = true
        window.location.reload()
      })

      // handle auth state
      this.$store.dispatch('USER_GET')
      if (this.user.id && this.user.email && this.user.idToken && this.user.workspace.id && this.user.workspace.name) {
        this.loading = false
        this.loggedOut = false
      }

      // Handle magic link login
      await auth
        .loginWithMagicLink()
        .then((result) => {
          // stripe query params
          if (result) this.$router.replace(this.$route.path)
        })
        .catch((err) => {
          this.$toastError(err.message)
        })

      // Handle auth state changes
      auth.onAuthStateChange(async (user) => {
        this.loading = true
        if (user) {
          // eslint-disable-next-line no-console
          // console.log('User is SIGNED IN', {
          //   email: user.email,
          //   uid: user.uid,
          // })

          const { uid } = user
          this.$store.commit('USER_SET', {
            ...this.$store.state.user,
            id: uid,
            idToken: await user.getIdToken(true),
          })
          await this.$store.dispatch('USER_GET')

          analytics.identify(uid, {
            email: user.email,
            name: `${this.$store.state.user.firstName} ${this.$store.state.user.lastName}`,
          })
          if (!this.user.workspaces.length && this.$route.path !== '/onboarding') {
            // this.$store.commit('SHOW_WORKSPACES_LIST')
            this.$router.push('/onboarding')
          }
          this.loggedOut = false
        } else {
          // eslint-disable-next-line no-console
          console.log('User is NOT signed in')
          if (!this.$route.meta.public) this.loggedOut = true
        }
        this.loading = false
      })

      // Preemptively refresh token periodically
      setInterval(() => {
        if (window.firebase.auth().currentUser) {
          window.firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((idToken) => {
              this.$store.commit('USER_SET', { ...this.$store.state.user, idToken })
            })
        }
      }, 60000)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      this.$toastError('There was an error authenticating you. Please try refreshing this page')
      this.loading = false
    }
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail
      this.updateExists = true
    },
    refreshApp() {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) {
        return
      }
      this.registration.waiting.postMessage('skipWaiting')
    },
    openWorkspace(ws) {
      this.$store.dispatch('USER_SET_WORKSPACE', ws)
      if (this.$route.path !== '/campaigns') this.$router.push('/campaigns')
      this.$store.commit('HIDE_WORKSPACES_LIST')
    },
  },
}
</script>

<template>
  <div id="app">
    <NewVersionAlert :visible="updateExists" @click="refreshApp" />
    <transition name="fade-in">
      <div
        v-if="activeState === 'loading'"
        class="flex items-center justify-center min-h-screen px-4 py-12 sm:px-6 lg:px-8"
      >
        <Spinner classes="h-8 w-8 text-blue-400" />
      </div>
    </transition>
    <transition name="fade-in">
      <Login v-if="activeState === 'login'" />
    </transition>
    <transition name="fade-in">
      <Workspaces v-if="activeState === 'workspaces'" @open="openWorkspace" />
    </transition>
    <transition name="fade-in">
      <WorkspaceSetup v-if="activeState === 'onboarding'" />
    </transition>
    <transition name="fade-in">
      <div v-if="activeState === 'app'">
        <router-view></router-view>
        <PlanChangeDrawer />
      </div>
    </transition>
    <Toast />
  </div>
</template>

<style src="./assets/tailwind.css"></style>

<style lang="scss">
// Fade in AND Out
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

// Only fade in
.fade-in-enter-active {
  transition: opacity 500ms;
}
.fade-in-enter /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

html {
  @apply bg-gray-50;
  font-size: 14px;

  /* Font Smoothing */
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'kern' 1, 'dlig' 1, 'opbd' 1, 'ss01' 1;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.form-input {
  @apply block w-full shadow-sm text-base rounded-md text-gray-700 transition-all ease-in-out duration-200 border-gray-200 outline-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 placeholder-gray-400;
}
</style>
