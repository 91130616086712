import api from '@/api/campaigns'

export default {
  state: {
    list: [],
  },
  mutations: {
    CAMPAIGNS_SET_LIST: (state, list) => {
      state.list = list || []
    },
  },
  actions: {
    CAMPAIGNS_GET_LIST: (store, { limit, offset, filterByStatus, query, orderBy, orderByDirection } = {}) =>
      api.getList(store.rootState.user.workspace.id, offset, limit, filterByStatus, query, orderBy, orderByDirection),
    CAMPAIGNS_GET: (store, id) => api.get(id),
    CAMPAIGNS_CREATE: (store, { type }) => api.create({ workspaceId: store.rootState.user.workspace.id, type }),
    CAMPAIGNS_CREATE_VARIANT: (store, { campaignId, type }) =>
      api.createVariant(store.rootState.user.workspace.id, campaignId, type),
    CAMPAIGNS_UPDATE_VARIANTS: (store, { variantId, js, content }) => api.updateVariant(variantId, js, content),
    CAMPAIGNS_SET_STATUS: (store, { id, active }) => api.updateStatus(id, active),
    CAMPAIGNS_SET_URL_MATCH: (store, { id, urlMatchType, urlMatchRule }) =>
      api.updateUrlMatchRule(id, urlMatchType, urlMatchRule),
    CAMPAIGNS_SET_NAME: (store, { id, name }) => api.updateName(id, name),
    CAMPAIGNS_DELETE: (store, id) => api.delete(id),
    CAMPAIGNS_SET_GOALS: (store, { campaignId, goals }) =>
      api.createGoals(store.rootState.user.workspace.id, campaignId, goals),
    CAMPAIGNS_DELETE_GOAL: (store, { campaignId, goalId }) => api.deleteGoal(campaignId, goalId),
    CAMPAIGNS_REFRESH_QUERY: (store, { params, id, days }) =>
      api.refreshQuery(store.rootState.user.workspace.id, params, id, days),
    CAMPAIGNS_GET_QUERY: (store, id) => api.getQuery(store.rootState.user.workspace.id, id),
    CAMPAIGNS_WATCH_QUERY: (store, { id, callback }) => api.watchQuery(store.rootState.user.workspace.id, id, callback),
  },
}
