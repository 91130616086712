<script>
import Spinner from '@/components/Base/Spinner'
import CampaignHeader from './components/header'
import Dropdown from '@/components/Base/Dropdown'
import lineChartUniques from './components/metrics/lineChartUniques'
import variantCR from './components/metrics/variantCR'
import BaseButton from '@/components/Base/Button'

export default {
  name: 'CampaignPerformance',
  components: {
    Spinner,
    CampaignHeader,
    Dropdown,
    lineChartUniques,
    variantCR,
    BaseButton,
  },
  data() {
    return {
      loading: false,
      campaign: {},
      timeRange: 7,
      loadingMetrics: false,
      selectedGoal: {},
    }
  },
  computed: {
    campaignId() {
      return this.$route.params.campaignId
    },
  },
  async created() {
    this.loading = true
    try {
      this.campaign = await this.$store.dispatch('CAMPAIGNS_GET', this.campaignId)
      this.selectedGoal = this.campaign.campaignGoals[0] || {}
      // this.getMetrics(7)
    } catch (err) {
      this.$toastError('There was an error opening this campaign')
      this.$router.push('/campaigns')
    }
    this.loading = false
  },
  methods: {
    async getCampaign(id) {
      this.campaign = await this.$store.dispatch('CAMPAIGNS_GET', id)
    },
    setGoal(goal, timeRange = 7) {
      this.loadingMetrics = true
      this.timeRange = timeRange
      this.selectedGoal = goal
      this.$nextTick(() => {
        this.loadingMetrics = false
      })
    },
  },
}
</script>

<template>
  <div class="min-h-screen bg-white pb-36">
    <div v-if="loading" class="p-32 text-center">
      <Spinner classes="h-8 w-8 text-teal-500 mx-auto" />
    </div>
    <div v-if="!loading">
      <CampaignHeader v-if="campaign" :campaign="campaign" @refresh="getCampaign(campaignId)" />

      <div class="px-12">
        <div class="my-5">
          <div class="flex items-center mb-3">
            <div class="flex-grow mb-5 text-2xl font-semibold tracking-tighter text-gray-700">
              Performance
            </div>
            <div class="flex items-center space-x-3">
              <Dropdown :loading="loadingMetrics" :label="`Last ${timeRange} days`" direction="left" size="sm">
                <button
                  class="flex items-center w-full px-3 py-2 space-x-2 text-sm font-medium text-gray-600 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                  @click="setGoal(selectedGoal, 7)"
                >
                  <svg
                    class="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                  </svg>
                  <div>Last 7 days</div>
                </button>
                <button
                  class="flex items-center w-full px-3 py-2 space-x-2 text-sm font-medium text-gray-600 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                  @click="setGoal(selectedGoal, 30)"
                >
                  <svg
                    class="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                  </svg>
                  <div>Last 30 days</div>
                </button>
              </Dropdown>

              <BaseButton
                v-if="!selectedGoal.event"
                theme="flat"
                size="sm"
                @click="$router.push(`/campaigns/view/${campaignId}/edit?step=GOALS`)"
              >
                <div class="flex items-center space-x-1">
                  <svg
                    class="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    ></path>
                  </svg>
                  <div>Campaign goal</div>
                </div>
              </BaseButton>
              <Dropdown v-else direction="left" size="sm">
                <template slot="label">
                  <div class="truncate" style="max-width: 250px;">{{
                    selectedGoal.event === 'Proof pageview'
                      ? `Visited ${selectedGoal.urlMatchRule}`
                      : `Performed event ${selectedGoal.event}`
                  }}</div>
                </template>
                <button
                  v-for="(g, i) in campaign.campaignGoals"
                  :key="i"
                  class="flex items-center w-full px-3 py-2 space-x-2 text-sm font-medium text-left text-gray-600 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                  @click="setGoal(g)"
                >
                  <div>
                    <div class="truncate" style="max-width: 250px;">
                      {{ g.event === 'Proof pageview' ? `Visited ${g.urlMatchRule}` : `Performed event ${g.event}` }}
                    </div>
                  </div>
                </button>
              </Dropdown>
            </div>
          </div>
          <lineChartUniques
            v-if="!loadingMetrics"
            :campaign-id="campaignId"
            :variants="campaign.campaignVariants"
            :time-range="timeRange"
          />
          <variantCR
            v-if="!loadingMetrics && selectedGoal && selectedGoal.event && campaign.campaignVariants"
            :campaign-id="campaignId"
            :goal-id="selectedGoal.id"
            :goal-event="selectedGoal.event"
            :goal-url-match-rule="selectedGoal.urlMatchRule"
            :variants="campaign.campaignVariants"
            :time-range="timeRange"
          />
          <div v-else>
            See variant performance by adding a goal to your campaign
          </div>
        </div>
        <!-- <div class="my-5">
          <div class="mb-5 text-2xl font-semibold tracking-tighter text-gray-700">
            People
          </div>
          <div class="font-medium text-center text-gray-400 rounded-lg p-36 bg-gray-50">
            <div class="flex items-center justify-center mt-3 space-x-3">
              <div class="px-3 py-2 border rounded-lg">Matched this campaign (show variant)</div>
              <div class="px-3 py-2 border rounded-lg">Completed a goal</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
