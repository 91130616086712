<script>
import BaseButton from '@/components/Base/Button'
import PlanCart from './planCart'

export default {
  name: 'Plans',
  components: {
    BaseButton,
    PlanCart,
  },
  data() {
    return {
      billingPeriod: 'monthly',
      selectedPlan: null,
      plans: [
        {
          label: 'Free',
          description: 'Social proof notifications for small sites',
          price: {
            monthly: 0,
            annually: 0,
          },
          features: [
            '500 monthly unique visitors',
            'Recent activity notifications',
            'A/B testing',
            '1 active campaign',
            '1 goal',
            '1 team member',
          ],
        },
        {
          label: 'Basic',
          description: 'Great for solo businesses just starting out',
          price: {
            monthly: 29,
            annually: 25,
          },
          features: [
            'Starting at 1,000 monthly unique visitors',
            'Additional themes + design options',
            'A/B testing',
            '3 active campaigns',
            '1 goal',
            '1 team member',
            'Email support',
          ],
        },
        {
          label: 'Pro',
          popular: true,
          description: 'Perfect for small businesses and startups',
          price: {
            monthly: 79,
            annually: 67,
          },
          features: [
            'Starting at 10,000 monthly unique visitors',
            'Hotstreaks & Live visitor count',
            'A/B testing',
            '5 active campaigns',
            '5 goals',
            '5 team members',
            'Email & Chat support',
            'Integrations: Zapier + custom webhooks',
          ],
        },
        {
          label: 'Business',
          description: 'Power features for established businesses',
          price: {
            monthly: 149,
            annually: 125,
          },
          features: [
            'Starting at 25,000 monthly unique visitors',
            'Advanced audience targeting',
            'A/B testing',
            '50 active campaigns',
            '10 goals',
            '10 team members',
            'Email & Chat support',
            'Additional integrations: Segment',
          ],
        },
      ],
    }
  },
}
</script>

<template>
  <div>
    <PlanCart :plan="selectedPlan" @close="() => (selectedPlan = null)" />
    <div class="">
      <div class="max-w-screen-xl px-4 pt-16 mx-auto sm:px-6 lg:px-8">
        <div class="sm:flex sm:flex-col sm:align-center">
          <h1 class="text-5xl font-extrabold leading-none text-gray-900 sm:text-center">Pricing Plans</h1>
          <p class="mt-5 text-xl leading-7 text-gray-500 sm:text-center"
            >Start building for free, then add a plan to go live. Cancel anytime.</p
          >
          <div class="relative flex self-center mt-6 bg-gray-100 rounded-lg sm:mt-8" style="padding: 2px;">
            <button
              type="button"
              :class="{ 'bg-white border-gray-200': billingPeriod === 'monthly' }"
              class="relative w-1/2 py-2 text-sm font-medium leading-5 text-gray-700 whitespace-no-wrap transition duration-150 ease-in-out border border-transparent rounded-md hover:text-gray-500 focus:outline-none focus:ring-blue-400 focus:ring-2 focus:ring-offset-2 focus:z-10 active:bg-gray-50 active:text-gray-800 sm:w-auto sm:px-8"
              @click="() => (billingPeriod = 'monthly')"
            >
              Monthly billing
            </button>
            <button
              type="button"
              :class="{ 'bg-white border-gray-200': billingPeriod === 'annually' }"
              class="relative w-1/2 py-2 text-sm font-medium leading-5 text-gray-700 whitespace-no-wrap transition duration-150 ease-in-out border border-transparent rounded-md hover:text-gray-500 focus:outline-none focus:ring-blue-400 focus:ring-2 focus:ring-offset-2 focus:z-10 active:bg-gray-50 active:text-gray-800 sm:w-auto sm:px-8"
              @click="() => (billingPeriod = 'annually')"
            >
              Yearly billing
            </button>
          </div>
        </div>
        <div
          class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4"
        >
          <div
            v-for="plan in plans"
            :key="plan.label"
            class="border border-gray-200 divide-y divide-gray-200 rounded-lg shadow-sm"
            :class="{ 'shadow-xl border-gray-300': plan.popular }"
          >
            <div class="p-6">
              <h2 class="text-lg font-medium leading-6 text-gray-900">
                {{ plan.label }}
                <span
                  v-if="plan.popular"
                  class="inline-block px-2 ml-2 text-sm font-semibold text-pink-500 rounded-full bg-pink-50"
                >
                  Most Popular
                </span>
              </h2>
              <p class="mt-4 text-sm leading-5 text-gray-500">{{ plan.description }}</p>
              <p class="mt-8">
                <span class="text-4xl font-extrabold leading-10 text-gray-900">${{ plan.price[billingPeriod] }}</span>
                <span v-if="plan.price[billingPeriod] > 0" class="text-base font-medium leading-6 text-gray-500"
                  >/mo</span
                >
              </p>
              <p class="mt-2 text-sm text-gray-400 uppercase">{{
                plan.price[billingPeriod] === 0 ? 'Free forever' : `Billed ${billingPeriod}`
              }}</p>
              <BaseButton classes="w-full mt-8" @click="() => (selectedPlan = plan)"
                >Select {{ plan.label }} plan</BaseButton
              >
            </div>
            <div class="px-6 pt-6 pb-8">
              <h3 class="text-xs font-medium leading-4 tracking-wide text-gray-900 uppercase">
                <div class="flex items-center">
                  <div>What's included</div>
                  <div
                    v-if="plan.price[billingPeriod] !== 0"
                    class="ml-2 -mt-px text-gray-300 transition-all duration-200 hover:text-gray-400"
                  >
                    <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </h3>
              <ul class="mt-6 space-y-4">
                <li v-for="(feature, i) in plan.features" :key="i" class="flex space-x-3">
                  <!-- Heroicon name: check -->
                  <svg
                    class="flex-shrink-0 w-5 h-5 text-green-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="text-sm leading-5 text-gray-500">{{ feature }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Logo cloud on brand -->
    <div class="mt-16 bg-purple-600">
      <div class="max-w-screen-xl px-4 py-16 mx-auto sm:py-20 sm:px-6 lg:px-8">
        <div class="lg:space-y-10">
          <h2 class="text-3xl font-extrabold leading-9 text-white">
            Some of the world's fastest growing companies use Proof
          </h2>
          <div class="flow-root mt-8 lg:mt-0">
            <div class="flex flex-wrap justify-between -mt-4 -ml-8 lg:-ml-4">
              <div class="flex flex-grow flex-shrink-0 mt-4 ml-8 lg:flex-grow-0 lg:ml-4">
                <img class="h-12" src="https://tailwindui.com/img/logos/tuple-logo-purple-200.svg" alt="Tuple" />
              </div>
              <div class="flex flex-grow flex-shrink-0 mt-4 ml-8 lg:flex-grow-0 lg:ml-4">
                <img class="h-12" src="https://tailwindui.com/img/logos/mirage-logo-purple-200.svg" alt="Mirage" />
              </div>
              <div class="flex flex-grow flex-shrink-0 mt-4 ml-8 lg:flex-grow-0 lg:ml-4">
                <img
                  class="h-12"
                  src="https://tailwindui.com/img/logos/statickit-logo-purple-200.svg"
                  alt="StaticKit"
                />
              </div>
              <div class="flex flex-grow flex-shrink-0 mt-4 ml-8 lg:flex-grow-0 lg:ml-4">
                <img
                  class="h-12"
                  src="https://tailwindui.com/img/logos/transistor-logo-purple-200.svg"
                  alt="Transistor"
                />
              </div>
              <div class="flex flex-grow flex-shrink-0 mt-4 ml-8 lg:flex-grow-0 lg:ml-4">
                <img
                  class="h-12"
                  src="https://tailwindui.com/img/logos/workcation-logo-purple-200.svg"
                  alt="Workcation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FAQ offset -->
    <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:py-20 lg:px-8">
      <div class="lg:grid lg:grid-cols-3 lg:gap-8">
        <div class="space-y-4">
          <h2 class="text-3xl font-extrabold leading-9 text-gray-900">
            Frequently asked questions
          </h2>
          <p class="text-lg leading-7 text-gray-500"
            >Can’t find the answer you’re looking for? Reach out to our
            <a href="#" class="font-medium text-purple-600 transition duration-150 ease-in-out hover:text-purple-500"
              >customer support</a
            >
            team.</p
          >
        </div>
        <div class="mt-12 lg:mt-0 lg:col-span-2">
          <dl class="space-y-12">
            <div class="space-y-2">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                Is there a free trial of the paid plans?
              </dt>
              <dd class="text-base leading-6 text-gray-500">
                Yes! There is a 14-day free trial for all paid plans. For needs beyond our pricing table, please contact
                us.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                What is the benefit of an annual plan?
              </dt>
              <dd class="text-base leading-6 text-gray-500">
                If you are on the monthly billing plan, your bill may fluctuate each month based on your website
                traffic. The annual plan allows you to purchase unique visitor allowance at a lower rate. Exceeding the
                allotted unique visitor count on annual plans may incur additional charges.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                What if I go over the monthly unique visitor allotment for my plan?
              </dt>
              <dd class="text-base leading-6 text-gray-500">
                You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate
                laboriosam fugiat.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                How can I estimate my monthly unique visitors?
              </dt>
              <dd class="text-base leading-6 text-gray-500">
                Nobody knows. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam
                fugiat.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
