<script>
import VueTagsInput from '@johmun/vue-tags-input'

export default {
  name: 'TagInput',
  components: {
    VueTagsInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tag: '',
    }
  },
  computed: {
    valueArray() {
      return this.value
        ? this.value.split(',').map((t) => {
            return { text: t }
          })
        : []
    },
  },
  methods: {
    handleTagChage(tags) {
      if (tags && tags.length)
        this.$emit(
          'input',
          tags
            .map((t) => t.text)
            .filter((t) => !!t)
            .join(',')
        )
    },
  },
}
</script>

<template>
  <vue-tags-input
    v-model="tag"
    :allow-edit-tags="false"
    :tags="valueArray"
    :placeholder="placeholder"
    :separators="[',']"
    @tags-changed="handleTagChage"
  />
</template>

<style lang="scss">
/* style the background and the text color of the input ... */
.vue-tags-input {
  @apply border m-0 shadow-sm border-gray-200 bg-white rounded-md transition-all ease-in-out duration-200;
}

.vue-tags-input.ti-focus {
  @apply ring-1 ring-blue-400 border-blue-400;
}

.vue-tags-input .ti-new-tag-input {
  @apply bg-transparent;
}

.vue-tags-input .ti-new-tag-input:focus {
  @apply border-transparent outline-none ring-0;
}

.vue-tags-input .ti-input {
  @apply border-none p-1 transition-none;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
  @apply border-none;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  @apply bg-white mt-2 rounded-md text-gray-600 font-normal text-base border border-gray-300 absolute z-10 p-2;
}

.vue-tags-input .ti-autocomplete .ti-item {
  @apply rounded-md p-1;

  &:hover {
    @apply bg-gray-50  text-gray-700;
  }
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #ebde6e;
  color: #283944;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder,
.vue-tags-input ::-moz-placeholder,
.vue-tags-input :-ms-input-placeholder,
.vue-tags-input :-moz-placeholder,
.vue-tags-input ::placeholder {
  @apply text-gray-400;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  @apply relative bg-blue-50 text-blue-400 font-medium text-base py-1 px-2 tracking-normal leading-4 rounded-md;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  @apply bg-red-500 text-white;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  @apply text-red-500;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  @apply line-through;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */

.vue-tags-input .ti-deletion-mark {
  @apply text-white;
}

.vue-tags-input .ti-tag:after {
  @apply absolute bg-black;
  transition: transform 0.2s;
  content: '';
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  transform: scaleX(0);
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}
</style>
