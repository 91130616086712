<script>
import Spinner from '@/components/Base/Spinner'
import CampaignHeader from './components/header'
import ContentStep from './components/stepContent'
import RulesStep from './components/stepRules'
import GoalsStep from './components/stepGoals'

export default {
  name: 'CampaignEditor',
  components: {
    Spinner,
    CampaignHeader,
    ContentStep,
    RulesStep,
    GoalsStep,
  },
  data() {
    return {
      loading: false,
      saving: false,
      dirty: false,
      campaign: null,
      activeVariant: null,
      code: '',
      activeStep: 'CONTENT',
      activeStepIsDirty: false,
    }
  },
  computed: {
    campaignId() {
      return this.$route.params.campaignId
    },
  },
  async created() {
    if (this.$route.query.step) this.activeStep = this.$route.query.step
    this.loading = true
    try {
      await this.getCampaign(this.campaignId)
    } catch (err) {
      this.$toastError('There was an error opening this campaign')
      this.$router.push('/campaigns')
    }
    this.loading = false
  },
  methods: {
    async getCampaign(id) {
      this.activeStepIsDirty = false
      this.campaign = await this.$store.dispatch('CAMPAIGNS_GET', id)
    },
    async done() {
      this.$router.push(`/campaigns/view/${this.campaignId}`)
    },
    openStep(step) {
      if (!this.activeStepIsDirty) {
        this.activeStep = step
        this.getCampaign(this.campaignId)
      } else {
        this.$toastWarning('There are unsaved changes')
      }
    },
  },
}
</script>

<template>
  <div class="min-h-screen bg-white pb-36">
    <div v-if="loading" class="p-32 text-center">
      <Spinner classes="h-8 w-8 text-teal-500 mx-auto" />
    </div>
    <div v-if="!loading">
      <CampaignHeader v-if="campaign" :campaign="campaign" @done="done" @refresh="getCampaign(campaignId)" />

      <div class="px-12">
        <ContentStep
          :active="activeStep === 'CONTENT'"
          :campaign="campaign"
          @refresh="getCampaign(campaignId)"
          @dirtyStatusChange="activeStepIsDirty = true"
          @openStep="openStep('CONTENT')"
        />

        <RulesStep
          :active="activeStep === 'RULES'"
          :campaign="campaign"
          @refresh="getCampaign(campaignId)"
          @dirtyStatusChange="activeStepIsDirty = true"
          @openStep="openStep('RULES')"
        />

        <GoalsStep
          :active="activeStep === 'GOALS'"
          :campaign="campaign"
          @refresh="getCampaign(campaignId)"
          @dirtyStatusChange="activeStepIsDirty = true"
          @openStep="openStep('GOALS')"
        />

        <!-- <Panel :active="activeStep === 'GOALS'" :clickable="true" @click="openStep('GOALS')">
          <div
            :class="{ 'text-neutral-700': activeStep === 'GOALS', 'text-gray-500': activeStep !== 'GOALS' }"
            class="flex items-center space-x-6 text-2xl font-semibold tracking-tighter transition-all duration-200 group-hover:text-neutral-700"
          >
            <div>Goals</div>
            <div class="px-2 py-2 text-sm font-medium tracking-tight text-gray-400 rounded-lg">
              No goals set
            </div>
          </div>
        </Panel> -->
        <!--
        <Panel :active="activeStep === 'LAUNCH'" :clickable="true" @click="openStep('LAUNCH')">
          <div
            :class="{ 'text-neutral-700': activeStep === 'LAUNCH', 'text-gray-500': activeStep !== 'LAUNCH' }"
            class="text-2xl font-semibold tracking-tighter transition-all duration-200 group-hover:text-neutral-700"
          >
            Set your campaign live
          </div>
        </Panel> -->
      </div>
    </div>
  </div>
</template>
