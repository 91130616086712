<script>
import ClickOutside from 'vue-click-outside'
import Avatar from '@/components/Base/Avatar'
import NewWorkspaceModal from '@/components/NewWorkspace'

export default {
  name: 'ProfileMenu',
  components: {
    Avatar,
    NewWorkspaceModal,
  },
  directives: {
    ClickOutside,
  },
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
      showNewWorkspaceModal: false,
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
      this.open = false
    },
    newWorkspace() {
      this.open = false
      this.showNewWorkspaceModal = true
    },
    openLink(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<template>
  <div
    v-click-outside="
      () => {
        open = false
      }
    "
    class="relative block w-full p-2 text-left"
  >
    <div>
      <NewWorkspaceModal :show="showNewWorkspaceModal" @close="showNewWorkspaceModal = false" />
      <button
        :class="classes"
        class="relative flex-shrink-0 block w-full p-3 text-left transition duration-150 ease-in-out rounded-lg bg-neutral-800 group focus:outline-none hover:bg-neutral-700 focus:ring-2 focus:ring-blue-900"
        @click="open = !open"
      >
        <div class="flex items-center w-full space-x-3">
          <div class="flex-initial">
            <Avatar size="2.4" :name="`${$user().firstName} ${$user().lastName}`" />
          </div>
          <div class="flex-grow">
            <div class="w-full text-base font-medium leading-5 text-gray-100 group-hover:text-white">
              <div class="block truncate" style="max-width: 115px;">{{ $user().firstName }} {{ $user().lastName }}</div>
            </div>
            <div
              class="mt-1 text-sm leading-4 tracking-tight transition duration-150 ease-in-out font-base text-neutral-400 group-hover:text-neutral-300"
            >
              <div class="block truncate" style="max-width: 115px;">
                {{ $user().workspace.name }}
              </div>
            </div>
          </div>
          <div class="relative flex-initial h-5">
            <svg
              class="absolute right-0 block w-5 h-5 text-neutral-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </button>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div v-if="open" class="absolute w-56 -mb-12 -ml-5 origin-bottom-left rounded-lg shadow-lg left-full bottom-full">
        <div class="bg-white rounded-lg ring-1 ring-black ring-opacity-5">
          <div class="px-4 py-3">
            <p class="text-sm leading-5 text-gray-500">
              Signed in as
            </p>
            <p class="text-sm font-medium leading-5 text-gray-700">
              <span class="block w-full truncate"> {{ $user().email }}</span>
            </p>
          </div>
          <div class="border-t border-gray-100"></div>
          <div class="p-2">
            <button
              class="flex items-center w-full px-3 py-2 font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-lg group hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-50 focus:text-gray-900"
              @click="goTo('/settings/personal')"
            >
              <svg
                class="w-5 h-5 mr-2 text-gray-500 group-hover:text-gray-600 group-focus:text-gray-500"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              Personal settings
            </button>
            <button
              class="flex items-center w-full px-3 py-2 font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-lg group hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-50 focus:text-gray-900"
              @click="goTo('/settings/workspace')"
            >
              <svg
                class="w-5 h-5 mr-2 text-gray-500 group-hover:text-gray-600 group-focus:text-gray-500"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                ></path>
                <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
              </svg>
              Workspace settings
            </button>
            <button
              class="flex items-center w-full px-3 py-2 font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-lg group hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-50 focus:text-gray-900"
              @click="goTo('/settings/team')"
            >
              <svg
                class="w-5 h-5 mr-2 text-gray-500 group-hover:text-gray-600 group-focus:text-gray-500"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path>
              </svg>
              Add members
            </button>
          </div>
          <div class="border-t border-gray-100"></div>
          <div class="p-2">
            <button
              class="flex items-center w-full px-3 py-2 leading-5 text-gray-600 transition duration-150 ease-in-out rounded-lg group hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-50 focus:text-gray-900"
              @click="$store.commit('SHOW_WORKSPACES_LIST')"
            >
              <svg
                class="w-5 h-5 mr-2 text-gray-500 group-hover:text-gray-600 group-focus:text-gray-500"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                ></path>
              </svg>
              Switch workspaces
            </button>
            <button
              class="flex items-center w-full px-3 py-2 leading-5 text-gray-600 transition duration-150 ease-in-out rounded-lg group hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-50 focus:text-gray-900"
              @click="newWorkspace"
            >
              <svg
                class="w-5 h-5 mr-2 text-gray-500 group-hover:text-gray-600 group-focus:text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              New workspace
            </button>
          </div>
          <div class="border-t border-gray-100"></div>
          <div class="p-2">
            <button
              class="w-full px-3 py-2 leading-5 text-left text-gray-600 transition duration-150 ease-in-out rounded-lg group hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-50 focus:text-gray-900"
              @click="openLink('https://www.notion.so/30a5c5e9c747428c8c7722098b5aca54')"
            >
              Support &amp; FAQ
            </button>
            <button
              class="w-full px-3 py-2 leading-5 text-left text-gray-600 transition duration-150 ease-in-out rounded-lg group hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-50 focus:text-gray-900"
              @click="$store.dispatch('USER_LOGOUT')"
            >
              Sign out
            </button>
          </div>
          <div class="border-t border-gray-100"></div>
          <div class="p-2">
            <div class="w-full px-3 py-2 text-xs leading-5 text-left text-gray-400"> v{{ $version() }} </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
