<script>
import Snippet from '@/components/Snippet'
import Spinner from '@/components/Base/Spinner'

export default {
  name: 'Home',
  components: {
    Snippet,
    Spinner,
  },
}
</script>

<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <nav aria-label="Progress">
      <ol class="bg-white divide-y divide-gray-300 ring-1 ring-black ring-opacity-5 md:flex md:divide-y-0">
        <li class="relative md:flex-1 md:flex">
          <!-- Completed Step -->
          <button class="flex items-center w-full group focus:outline-none">
            <span class="flex items-center px-6 py-4 font-medium">
              <span
                class="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-green-600 rounded-full group-hover:bg-green-800"
              >
                <!-- Heroicon name: check -->
                <svg
                  class="w-6 h-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span class="ml-4 font-medium text-green-700">Add the snippet</span>
            </span>
          </button>

          <div class="absolute top-0 right-0 hidden w-5 h-full md:block" aria-hidden="true">
            <svg class="w-full h-full text-gray-200" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path
                d="M0 -2L20 40L0 82"
                vector-effect="non-scaling-stroke"
                stroke="currentcolor"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </li>

        <li class="relative md:flex-1 md:flex">
          <!-- Current Step -->
          <button class="flex items-center w-full px-6 py-4 font-medium focus:outline-none">
            <span
              class="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-purple-600 rounded-full"
              aria-current="step"
            >
              <span class="text-purple-600">02</span>
            </span>
            <span class="ml-4 font-medium text-purple-600">Build a campaign</span>
          </button>

          <div class="absolute top-0 right-0 hidden w-5 h-full md:block" aria-hidden="true">
            <svg class="w-full h-full text-gray-200" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path
                d="M0 -2L20 40L0 82"
                vector-effect="non-scaling-stroke"
                stroke="currentcolor"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </li>

        <li class="relative md:flex-1 md:flex">
          <!-- Upcoming Step -->
          <button class="flex items-center w-full group focus:outline-none">
            <span class="flex items-center px-6 py-4 font-medium">
              <span
                class="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full group-hover:border-gray-400"
              >
                <span class="text-gray-500 group-hover:text-gray-900">03</span>
              </span>
              <span class="ml-4 font-medium text-gray-500 group-hover:text-gray-900">Set it live</span>
            </span>
          </button>
        </li>
      </ol>
    </nav>

    <div class="max-w-4xl px-4 py-20 mx-auto md:px-0">
      <div class="relative py-3 mb-20 sm:mx-auto">
        <div
          class="absolute inset-0 transform -skew-y-6 shadow-lg bg-gradient-to-r from-purple-400 to-purple-600 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"
        >
        </div>
        <div class="relative px-4 py-10 bg-white shadow-2xl sm:rounded-3xl sm:p-12">
          <div class="max-w-3xl mx-auto">
            <div class="">
              <h1 class="mb-2 text-3xl font-bold tracking-tight text-neutral-700">Add the Proof snippet</h1>
              <p class="mb-6 text-lg leading-7 text-gray-600">
                Paste into the bottom of your page as close to the closing
                <code class="inline-block px-1 font-semibold text-gray-600 bg-gray-100 rounded">&lt;/body&gt;</code>
                tag as possible or into your Google Tag Manager account.
              </p>
              <Snippet />
            </div>
          </div>
        </div>
      </div>

      <div class="relative py-8 my-6 bg-white rounded-lg pl-28 pr-9">
        <div class="absolute top-8 left-7">
          <div class="inline-block p-3 mb-3 text-center text-gray-300 bg-gray-100 rounded-full">
            <Spinner classes="w-8 h-8" />
          </div>
        </div>
        <div>
          <h3 class="mb-1 text-xl font-semibold tracking-tight">Waiting for your first page load</h3>
          <p class="text-gray-400">
            Once you have added the snippet to your pages you should see page load events showing up here. If you are
            having trouble please reach out to support for help.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
