import api from '@/api/user'
import auth from '@/lib/auth'
import analytics from '@/lib/analytics'

export default {
  state: {
    id: '',
    workspace: {},
    email: '',
    firstName: '',
    lastName: '',
    workspaces: [],
    invites: [],
    idToken: null,
    idTokenExpiration: null,
  },
  mutations: {
    USER_SET: (
      state,
      { id, email, firstName, lastName, workspace, workspaces, invites, idToken, idTokenExpiration }
    ) => {
      state.id = id
      state.email = email
      state.firstName = firstName
      state.lastName = lastName
      state.workspace = workspace || {}
      state.workspaces = workspaces || []
      state.invites = invites || []
      state.idToken = idToken
      state.idTokenExpiration = idTokenExpiration
    },
    USER_RESET: (state) => {
      state.id = ''
      state.email = ''
      state.firstName = ''
      state.lastName = ''
      state.workspace = {}
      state.workspaces = []
      state.invites = []
      state.idToken = null
      state.idTokenExpiration = null
    },
  },
  actions: {
    USER_GET: (store) => {
      const userId = store.state.id
      if (!userId) return
      return api.getUser(userId).then((user) => {
        // Decide which workspace to open
        if (store.state.workspace && store.state.workspace.id && store.state.workspace.name) {
          // Get the latest meta for the selected workspace
          const activeWorkspace =
            user.workspaces.find((w) => w.id === store.state.workspace.id) || store.state.workspace
          store.commit('USER_SET', { ...store.state, ...user, workspace: activeWorkspace })

          analytics.identify(userId, {
            workspaceName: activeWorkspace.name,
            domain: activeWorkspace.domain,
          })
        }
        // no workspaces assigned to user so the workspace list will open
        else store.commit('USER_SET', { ...store.state, ...user })

        return user
      })
    },
    USER_SET_WORKSPACE: (store, ws) => {
      store.commit('USER_SET', { ...store.state, workspace: ws })
    },
    USER_GET_INVITE: (store, id) => {
      return api.getWorkspaceInvite(id)
    },
    USER_GET_INVITES: (store, email) => {
      return api.getWorkspaceInvites(email).then((invites) => {
        store.commit('USER_SET', { ...store.state, invites: invites.list })
        return invites
      })
    },
    USER_ACCEPT_INVITE: (store, inviteId) => {
      return api.acceptWorkspacesInvite(inviteId)
    },
    USER_CREATE: (store, { userId, email, firstName, lastName }) => {
      return api.createUser(userId, email, firstName, lastName).then(async (user) => {
        store.commit('USER_SET', { ...store.state, id: user.id })
        return store.dispatch('USER_GET')
      })
    },
    USER_UPDATE: async (store, { firstName, lastName }) => {
      return api.updateUser(store.state.id, { firstName, lastName }).then(() => store.dispatch('USER_GET'))
    },
    USER_LOGIN: (store, { email, password }) => {
      return auth
        .login(email, password)
        .then(async ({ uid }) => {
          analytics.identify(uid, { email })
          const idToken = await auth.getIdToken()
          store.commit('USER_SET', { id: uid, email, idToken })
          const user = await api.getUser(uid)
          if (user && user.id && user.email && user.firstName)
            store.commit('USER_SET', { ...user, email, id: uid, idToken })
          else {
            const invites = await store.dispatch('USER_GET_INVITES', email)
            if (!invites.count) {
              // eslint-disable-next-line no-throw-literal
              throw {
                code: 'USER_NOT_FOUND',
                message: 'User not found! Please try a different email.',
              }
            }
          }
        })
        .catch((err) => {
          store.dispatch('USER_LOGOUT')
          throw err
        })
    },
    USER_SIGNUP: (store, { email, password }) => {
      return auth
        .signup(email, password)
        .then(async ({ uid }) => {
          analytics.identify(uid, { email })
          const idToken = await auth.getIdToken()
          store.commit('USER_SET', { id: uid, email, idToken })
          return { id: uid, email, idToken }
        })
        .catch((err) => {
          store.dispatch('USER_LOGOUT')
          throw err
        })
    },
    USER_LOGIN_WITH_GOOGLE: (store) => {
      return auth.loginWithGoogle().then(async ({ id, email, displayName, photoUrl }) => {
        const idToken = await auth.getIdToken()
        store.commit('USER_SET', { id, email, displayName, idToken })
        return { id, email, displayName, photoUrl }
      })
    },
    USER_LOGOUT: (store, payload) => {
      store.commit('USER_RESET')
      auth.logout()
    },
    USER_IS_LOGGED_IN: (store, payload) => {
      return auth.isLoggedIn()
    },
    WORKSPACE_CREATE: (store, { name, domain }) => {
      return api.createWorkspace(name, domain).then((ws) => {
        store.dispatch('USER_SET_WORKSPACE', ws)
        return ws
      })
    },
  },
}
