<script>
import BaseButton from '@/components/Base/Button'
import BaseTagInput from '@/components/Base/TagInput'
import Dropdown from '@/components/Base/Dropdown'
import Panel from './panel'

export default {
  name: 'RulesStep',
  components: {
    BaseButton,
    BaseTagInput,
    Dropdown,
    Panel,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    active: Boolean,
  },
  data() {
    return {
      dirty: false,
      saving: false,
      urlMatchType: 'ALL',
      urlMatchRule: '',
      matchTypeOptions: {
        ALL: {
          value: 'ALL',
          label: 'All URLs',
        },
        SIMPLE: {
          value: 'SIMPLE',
          label: 'URLs that match',
        },
        CONTAINS: {
          value: 'CONTAINS',
          label: 'URLs that contain',
        },
        // REGEX: {
        //   value: 'REGEX',
        //   label: 'URLs that match regex pattern',
        // },
      },
    }
  },
  computed: {
    tagInputPlaceholder() {
      if (this.urlMatchType === 'SIMPLE') return this.urlMatchRule ? 'or...' : 'https://tesla.com'
      else if (this.urlMatchType === 'CONTAINS') return this.urlMatchRule ? 'or...' : 'tesla.com/blog'
      else return 'tesla.com/blog'
    },
  },
  created() {
    this.urlMatchType = this.campaign.urlMatchType || 'ALL'
    this.urlMatchRule = this.campaign.urlMatchRule || ''
  },
  methods: {
    setUrlMatchType(val) {
      this.$emit('dirtyStatusChange')
      this.urlMatchType = val
      this.urlMatchRule = ''
      this.dirty = true
    },
    setUrlMatchRule(val) {
      this.$emit('dirtyStatusChange')
      this.urlMatchRule = val
      this.dirty = true
    },
    async saveChanges() {
      this.saving = true
      try {
        await this.$store.dispatch('CAMPAIGNS_SET_URL_MATCH', {
          id: this.campaign.id,
          urlMatchType: this.urlMatchType,
          urlMatchRule: this.urlMatchRule,
        })
        this.$emit('refresh')
        this.$toastSuccess(
          'Campaign saved',
          this.campaign.active ? 'Changes can take up to 60 seconds to deploy to our super fast servers globally' : ''
        )
        this.dirty = false
      } catch (err) {
        this.$toastError('There was a problem saving your campaign rules')
      }
      this.saving = false
    },
    cancel() {
      this.urlMatchType = this.campaign.urlMatchType || 'ALL'
      this.urlMatchRule = this.campaign.urlMatchRule || ''
      this.dirty = false
      this.$emit('refresh')
    },
  },
}
</script>

<template>
  <Panel :active="active" :clickable="true" @click="() => !active && $emit('openStep')">
    <div class="flex items-center">
      <div
        :class="{ 'text-neutral-700': active, 'text-gray-500': !active }"
        class="inline-flex items-center flex-grow space-x-6 text-2xl font-semibold tracking-tighter transition-all duration-200 group-hover:text-neutral-700"
      >
        <div>Rules</div>
        <div v-if="!active" class="px-2 py-2 text-sm font-medium tracking-tight text-gray-500 bg-gray-100 rounded-lg">
          Show on {{ matchTypeOptions[urlMatchType].label }}
          <span class="text-blue-400">{{ (campaign.urlMatchRule || '').split(',').join(' or ') }}</span>
        </div>
      </div>
      <div v-if="dirty" class="flex-initial">
        <BaseButton theme="flat" classes="mr-2" @click="cancel">Cancel</BaseButton>
        <BaseButton :disabled="!dirty" :loading="saving" @click="saveChanges">Save</BaseButton>
      </div>
    </div>

    <div v-if="active">
      <div class="flex items-start mt-3 space-x-2">
        <div class="flex items-center flex-initial space-x-2">
          <div class="text-gray-600">Show this campaign on</div>
          <div>
            <Dropdown size="sm" :label="matchTypeOptions[urlMatchType].label">
              <button
                v-for="o in matchTypeOptions"
                :key="o.value"
                class="flex items-center w-full px-3 py-2 space-x-2 text-sm font-medium text-gray-600 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                @click="setUrlMatchType(o.value)"
              >
                <div>{{ o.label }}</div>
              </button>
            </Dropdown>
          </div>
        </div>
        <div class="flex-grow">
          <BaseTagInput
            v-if="urlMatchType === 'SIMPLE' || urlMatchType === 'CONTAINS'"
            :value="urlMatchRule"
            :placeholder="tagInputPlaceholder"
            @input="setUrlMatchRule"
          />
          <input
            v-else-if="urlMatchType !== 'ALL'"
            placeholder="add rule"
            type="text"
            class="form-input"
            :value="urlMatchRule"
            @input="setUrlMatchRule"
          />
        </div>
      </div>
    </div>
  </Panel>
</template>
