var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative overflow-hidden font-bold text-center rounded-full",style:({ height: _vm.rem(_vm.size), width: _vm.rem(_vm.size) })},[(_vm.loading)?_c('div',{staticClass:"absolute inset-0 z-10 flex items-center bg-white"},[_c('Spinner',{attrs:{"classes":"h-7 w-7 mx-auto text-gray-200"}})],1):_vm._e(),(_vm.url && !_vm.img404)?_c('img',{staticClass:"absolute inset-0 z-10 bg-white",attrs:{"src":_vm.url},on:{"error":function($event){_vm.img404 = true}}}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"absolute inset-0 z-0 inline-flex items-center justify-center w-12 h-12 rounded-full",style:({
      height: _vm.rem(_vm.size),
      width: '100%',
      backgroundColor: _vm.stringToHslColor(_vm.name, _vm.saturation, _vm.lightness),
    })},[_c('span',{staticClass:"font-medium leading-none",style:({
        height: _vm.rem(_vm.size * 0.4),
        width: _vm.rem(_vm.size),
        fontSize: _vm.rem(_vm.size * 0.4),
        color: _vm.stringToHslColor(_vm.name, _vm.saturation, 30),
      })},[_vm._v(_vm._s(_vm.initials(_vm.name)))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }