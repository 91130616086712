<script>
import Spinner from '@/components/Base/Spinner'
import BaseChart from '@/components/Base/Chart'
import utils from '@/lib/utils'

export default {
  name: 'LineChartUniques',
  components: {
    Spinner,
    BaseChart,
  },
  props: {
    campaignId: {
      type: String,
      required: true,
    },
    variants: {
      type: Array,
      required: true,
    },
    timeRange: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      subscriptions: [],
      loading: false,
      rawData: {},
      labels: [],
      datasets: [],
    }
  },
  computed: {
    noData() {
      return !this.rawData || !this.rawData.result
    },
  },
  mounted() {
    this.getMetrics()
  },
  beforeDestroy() {
    this.subscriptions.map((s) => s.close())
  },
  methods: {
    async getMetrics() {
      const days = this.timeRange
      this.subscriptions.map((s) => s.close())
      this.loading = true
      this.$store
        .dispatch('CAMPAIGNS_REFRESH_QUERY', {
          params: { campaignId: this.campaignId },
          id: `campaignUniqueViews_${this.campaignId}_${days}`,
          days,
        })
        .then((data) => {
          if (data && data.result) {
            this.rawData = data
            this.labels = data.result.labels && data.result.labels.map((l) => utils.formatDate(l))
            this.datasets =
              data.result.datasets &&
              data.result.datasets.map((ds, i) => {
                return {
                  name: `Variant ${i + 1}`,
                  values: ds.values,
                }
              })
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
      const s = await this.$store.dispatch('CAMPAIGNS_WATCH_QUERY', {
        id: `campaignUniqueViews_${this.campaignId}_${days}`,
        callback: (data) => {
          if (data && data.result) {
            this.rawData = data
            this.labels = data.result.labels && data.result.labels.map((l) => utils.formatDate(l))
            this.datasets =
              data.result.datasets &&
              data.result.datasets.map((ds, i) => {
                return {
                  name: `Variant ${i + 1}`,
                  values: ds.values,
                }
              })
          }
        },
      })
      this.subscriptions.push(s)
    },
  },
}
</script>

<template>
  <div class="grid grid-cols-3 gap-3 mb-3">
    <div class="col-span-3 px-1 pt-3 pb-1 rounded-lg ring-gray-100 ring-2">
      <div v-if="loading" class="p-12 text-center">
        <Spinner classes="h-8 w-8 text-teal-500 mx-auto" />
      </div>
      <div v-else-if="!noData">
        <BaseChart id="uniques" title="Unique views" :labels="labels" :datasets="datasets" />
      </div>
      <div v-else class="flex items-center text-center p-28">
        <div class="px-4 py-2 mx-auto leading-4 rounded-full bg-gray-50">
          Nothing yet...
        </div>
      </div>
    </div>
  </div>
</template>
