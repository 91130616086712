<script>
import SidebarShell from '@/components/Layout/SidebarShell'

export default {
  name: 'Index',
  components: {
    SidebarShell,
  },
}
</script>

<template>
  <div>
    <SidebarShell>
      <router-view></router-view>
    </SidebarShell>
  </div>
</template>
