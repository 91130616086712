import api from './utils/client'
import restApi from './utils/restClient'
import _get from 'lodash/get'

const getUser = (id) => {
  return api
    .query(
      `getUser($id: String!) {
        users_by_pk(id: $id) {
          id
          firstName
          lastName
          email
          workspaces: workspaceUsers {
            workspace {
              id
              name
              domain
              pixelId
            }
          }
        }
      }`,
      {
        id,
      }
    )
    .then((res) => _get(res, 'users_by_pk', {}))
    .then((user) => {
      const workspaces = _get(user, 'workspaces', []).map((ws) => {
        return {
          id: ws.workspace.id,
          name: ws.workspace.name,
          domain: ws.workspace.domain,
          pixelId: ws.workspace.pixelId,
        }
      })

      return {
        ...user,
        workspaces,
        workspace: workspaces[0],
      }
    })
}

export default {
  getUser,
  getWorkspaceInvite: (id) => {
    return restApi.get(`${process.env.VUE_APP_FUNCTIONS_ENDPOINT}/_workspaces/getInvite?id=${id}`)
  },
  getWorkspaceInvites: (email) => {
    return restApi.get(`${process.env.VUE_APP_FUNCTIONS_ENDPOINT}/_workspaces/getInvites?email=${email}`)
  },
  acceptWorkspacesInvite: (inviteId) => {
    return restApi.post(`${process.env.VUE_APP_FUNCTIONS_ENDPOINT}/_workspaces/acceptInvite`, {
      inviteId,
    })
  },
  createUser: (userId, email, firstName, lastName) => {
    // return restApi.post(`${process.env.VUE_APP_FUNCTIONS_ENDPOINT}/_workspaces/userCreate`, {
    //   firstName,
    //   lastName,
    // })
    return api
      .mutate(
        `createUser($userId: String!, $email: String!, $firstName: String!, $lastName: String!) {
          insert_users(objects:[{id: $userId, email: $email, firstName: $firstName, lastName: $lastName}]) {
            affected_rows
          }
        }`,
        {
          userId,
          email,
          firstName,
          lastName,
        }
      )
      .then(() => getUser(userId))
  },
  updateUser: (userId, { firstName, lastName }) => {
    return api
      .mutate(
        `updateUser($userId: String!, $firstName: String!, $lastName: String!) {
          update_users(where: {id: {_eq: $userId}}, _set: {firstName: $firstName, lastName: $lastName}) {
            affected_rows
          }
        }`,
        {
          userId,
          firstName,
          lastName,
        }
      )
      .then(() => getUser(userId))
  },
  createWorkspace: (name, domain) => {
    return restApi.post(`${process.env.VUE_APP_FUNCTIONS_ENDPOINT}/_workspaces/workspaceCreate`, {
      name,
      domain,
    })
  },
  // updateUser: ({ id, name, url, matchType, matchRule, css, js, trackSPA = false }) => {
  //   return api
  //     .mutate(
  //       `updateUser($id: String!, $name: String!, $url: String!, $matchType: user_match_type!, $matchRule: String!, $js: String, $css: String, $trackSPA: Boolean) {
  //         update_users(where: {id: {_eq: $id}}, _set: {
  //           name: $name,
  //           url: $url,
  //           matchRule: $matchRule,
  //           matchType: $matchType,
  //           trackSPA: $trackSPA,
  //           js: $js,
  //           css: $css,
  //         }) {
  //           returning {
  //             id
  //           }
  //         }
  //       }`,
  //       {
  //         id,
  //         name,
  //         url,
  //         matchType,
  //         matchRule,
  //         js,
  //         css,
  //         trackSPA,
  //       }
  //     )
  //     .then((res) => res.insert_users && res.insert_users.returning && res.insert_users.returning[0])
  // },
}
