<script>
import statSig from '@/lib/statSig'
import Spinner from '@/components/Base/Spinner'
import tippy from 'tippy.js'

export default {
  name: 'VariantCR',
  components: {
    Spinner,
  },
  props: {
    campaignId: {
      type: String,
      required: true,
    },
    variants: {
      type: Array,
      required: true,
    },
    goalId: {
      type: String,
      required: true,
    },
    goalEvent: {
      type: String,
      required: true,
    },
    goalUrlMatchType: {
      type: String,
      default: '',
    },
    goalUrlMatchRule: {
      type: String,
      default: '',
    },
    timeRange: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      variantStats: [],
      statSig: null,
      loading: false,
      subscriptions: [],
    }
  },
  mounted() {
    this.watchMetrics()
    this.getMetrics()
    tippy('[data-tippy-content]')
  },
  beforeDestroy() {
    this.subscriptions.map((s) => s.close())
  },
  methods: {
    async watchMetrics() {
      const s = await this.$store.dispatch('CAMPAIGNS_WATCH_QUERY', {
        id: `GCR_${this.campaignId}_${this.goalId}_${this.timeRange}`,
        callback: (data) => {
          if (!data || !data.result) return
          this.variantStats = this.variants
            .map((v) => {
              return {
                ...v,
                ...(data.result.find((r) => v.id === r.variantId) || { uniques: 0, conversions: 0 }),
              }
            })
            .sort((a, b) => {
              let comparison = 0
              if (a.created_at > b.created_at) {
                comparison = 1
              } else if (a.created_at < b.created_at) {
                comparison = -1
              }
              return comparison
            })
          if (this.variantStats && this.variants.length > 1)
            this.statSig = statSig.getSignificance(this.variantStats[0], this.variantStats[1])
          this.loading = false
        },
      })
      this.subscriptions.push(s)
    },
    async getMetrics() {
      this.loading = true

      this.$store.dispatch('CAMPAIGNS_REFRESH_QUERY', {
        params: {
          campaignId: this.campaignId,
          goalEvent: this.goalEvent,
          goalUrlMatchRule: this.goalUrlMatchRule,
        },
        id: `GCR_${this.campaignId}_${this.goalId}_${this.timeRange}`,
        days: this.timeRange,
      })
    },
    getLift(control, variant) {
      if (isNaN(Math.round(((variant.gcr - control.gcr) / control.gcr) * 100))) return 0
      return Math.round(((variant.gcr - control.gcr) / control.gcr) * 100)
    },
  },
}
</script>

<template>
  <div class="flex flex-col mt-5">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div v-if="statSig" class="grid items-stretch grid-cols-5 mb-5 space-x-5">
          <div
            :class="{
              'bg-green-50 text-green-600': statSig.confidence >= 95,
              'bg-gray-50 text-gray-600': statSig.confidence < 95,
            }"
            class="flex items-center col-span-1 px-6 py-4 font-medium text-center rounded-lg"
          >
            <div class="mx-auto">
              <div class="flex items-center mb-1 space-x-3 font-normal uppercase opacity-60">
                <div>Stat sig</div>
                <div>
                  <svg
                    v-if="statSig.confidence >= 95"
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <svg
                    v-else
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="text-3xl font-bold tracking-tight">{{ statSig.confidence }}%</div>
            </div>
          </div>
          <div
            :class="{
              'bg-green-50 text-green-600': getLift(variantStats[0], variantStats[1]) >= 5,
              'bg-gray-50 text-gray-600':
                getLift(variantStats[0], variantStats[1]) >= 0 && getLift(variantStats[0], variantStats[1]) < 5,
              'bg-yellow-50 text-yellow-600': getLift(variantStats[0], variantStats[1]) < 0,
            }"
            class="flex items-center col-span-1 px-6 py-4 font-medium text-center rounded-lg"
          >
            <div class="mx-auto">
              <div class="flex items-center mb-1 space-x-3 font-normal uppercase opacity-60">
                <div>Lift</div>
                <div>
                  <svg
                    v-if="getLift(variantStats[0], variantStats[1]) > 0"
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <svg
                    v-if="getLift(variantStats[0], variantStats[1]) <= 0"
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="text-3xl font-bold tracking-tight">{{ getLift(variantStats[0], variantStats[1]) }}%</div>
            </div>
          </div>
          <div class="col-span-3 px-6 py-6 text-gray-500 rounded-lg bg-gray-50">
            <div class="text-lg font-semibold text-gray-600">Recommendation:</div>
            <div class="mt-1 leading-7">
              <span v-if="!isNaN(statSig.recommendedSampleSize)">{{ statSig.recommendation }}</span>
              <span v-else
                >Wait for more conversions on your selected goal before making a decision. Consider broadening your goal
                parameters.</span
              >
            </div>
          </div>
        </div>
        <div class="overflow-hidden rounded-lg ring-gray-100 ring-2">
          <div v-if="loading" class="flex items-center py-20 justify-items-center">
            <div class="mx-auto">
              <Spinner classes="h-8 w-8 text-blue-400" />
            </div>
          </div>
          <table v-if="!loading" class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                  Name
                </th>
                <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                  Created
                </th>
                <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                  <div class="flex items-center space-x-2 justify-items-end">
                    <div class="flex-grow">Uniques</div>
                    <div>
                      <svg
                        v-tippy="{ arrow: true }"
                        content="Unique users that viewed a variant"
                        class="w-4 h-4 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"
                        ></path></svg
                    ></div>
                  </div>
                </th>
                <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                  <div class="flex items-center space-x-2 justify-items-end">
                    <div class="flex-grow">Conversions</div>
                    <div>
                      <svg
                        v-tippy="{ arrow: true }"
                        content="Unique users that completed the selected goal"
                        class="w-4 h-4 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"
                        ></path></svg
                    ></div>
                  </div>
                </th>
                <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                  Conversion rate
                </th>
                <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                  Lift
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(v, i) in variantStats" :key="i">
                <td class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                  <div class="flex items-center">
                    <div
                      :style="{ backgroundColor: i === 0 ? '#8B5CF6' : '#10B981' }"
                      class="w-3 h-3 mr-3 rounded-full"
                    ></div>
                    <div>Variant {{ i + 1 }}</div>
                  </div>
                </td>
                <td class="px-6 py-5 text-sm font-normal text-gray-400 whitespace-nowrap">
                  {{ v.created_at | formatDate }}
                </td>
                <td class="px-6 py-5 text-right text-gray-500 whitespace-nowrap">
                  {{ v.uniques | formatNumber }}
                </td>
                <td class="px-6 py-5 text-right text-gray-500 whitespace-nowrap">
                  {{ v.conversions | formatNumber }}
                </td>
                <td class="px-6 py-5 text-right text-gray-500 whitespace-nowrap">
                  {{ (!isNaN(v.gcr) && Math.round(v.gcr * 100)) || 0 | formatNumber }}%
                </td>
                <td class="px-6 py-5 text-right text-gray-500 whitespace-nowrap">
                  <span
                    v-if="i === 1"
                    :class="{
                      'text-green-500 font-bold': getLift(variantStats[0], variantStats[1]) >= 5,
                      '': getLift(variantStats[0], variantStats[1]) < 5,
                    }"
                    >{{ getLift(variantStats[0], variantStats[1]) | formatNumber }}%</span
                  >
                  <span v-else>-</span>
                </td>
              </tr>
              <tr v-if="variantStats && variantStats.length > 1">
                <td colspan="2" class="px-6 py-5 text-sm text-gray-400 whitespace-nowrap"></td>
                <td class="px-6 py-5 text-sm text-right text-gray-400 whitespace-nowrap">
                  {{ variantStats.reduce((a, b) => a.uniques + b.uniques) | formatNumber }}
                </td>
                <td class="px-6 py-5 text-sm text-right text-gray-400 whitespace-nowrap">
                  {{ variantStats.reduce((a, b) => a.conversions + b.conversions) | formatNumber }}
                </td>
                <td class="px-6 py-5 text-sm text-right text-gray-400 whitespace-nowrap">
                  {{
                    Math.round(
                      (variantStats.reduce((a, b) => a.conversions + b.conversions) /
                        variantStats.reduce((a, b) => a.uniques + b.uniques)) *
                        100
                    ) | formatNumber
                  }}%
                </td>
                <td class="px-6 py-5 text-sm text-right text-gray-400 whitespace-nowrap"> </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
