import ms from 'ms'
import dayjs from 'dayjs'

/**
 * Get a twitter style relavent time from a timestamp
 * @param {number} ts
 */
const fromNow = (ts) => {
  if (!ts) return '?'
  ts = new Date(ts).getTime()
  const date = new Date(ts)
  const delta = Math.round((+new Date() - date) / 1000)
  // const minute = 60
  // const hour = minute * 60
  // const day = hour * 24
  // const week = day * 7

  if (delta < 15) {
    return 'just now'
  } else {
    const currentTime = new Date().getTime()
    const diff = currentTime - ts
    return ms(diff) + ' ago'
  }
}

const formatDate = (ts) => dayjs(ts).format('MMM D')

const getDelta = (ts, interval = 'seconds') => {
  if (!ts) return '?'
  ts = new Date(ts).getTime()
  const date = new Date(ts)
  const delta = Math.round((+new Date() - date) / 1000)

  const minute = 60
  const hour = minute * 60
  const day = hour * 24

  switch (interval) {
    case 'seconds':
      return delta
    case 'minute':
      return delta / minute
    case 'hour':
      return delta / hour
    case 'day':
      return delta / day
  }
}

/**
 * Add the thousands seperator to large number
 * @param {number} num
 * @returns {string}
 */
const thousandsSeperator = (num) => {
  if (!num) return num
  if (num > 999) {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'
  } else return num.toString()
}

function openTestUrl(url) {
  if (url.indexOf('?') !== -1) url = `${url}&wfTest`
  else url = `${url}?wfTest`

  var win = window.open(url, '_blank')
  win.focus()
}

/**
 * Check if string is a valid  URL
 * @param {string} url
 */
const isValidUrl = (url) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(url)
}

/**
 * Cleanup inconsistently formatted URLs
 * @param {string} url
 * @returns {string}
 */
const cleanPageURL = (url) => {
  if (!url) return ''
  // Add missing protocol
  let pageUrl = url.match('http[s]?://') ? url : `https://${url}`
  if (isValidUrl(pageUrl) || pageUrl.indexOf('http://localhost') !== -1) {
    // Remove query string
    pageUrl = new URL(pageUrl)
    return pageUrl.protocol + '//' + pageUrl.host + pageUrl.pathname
  } else return ''
}

export default {
  fromNow,
  formatDate,
  thousandsSeperator,
  openTestUrl,
  isValidUrl,
  cleanPageURL,
  getDelta,
}
