<script>
import BaseButton from '@/components/Base/Button'
import BaseChart from '@/components/Base/Chart'
import ProgressCircle from '@/components/Base/ProgressCircle'

export default {
  name: 'Subscription',
  components: {
    BaseButton,
    BaseChart,
    ProgressCircle,
  },
  props: {},
  methods: {
    editPlans() {
      this.$store.commit('PLAN_CHANGE_DRAWER_SET', 'plans')
    },
  },
}
</script>

<template>
  <div>
    <div class="my-8 overflow-hidden bg-white rounded-lg ring-1 ring-black ring-opacity-5">
      <div class="p-6">
        <h3 class="text-xl font-medium leading-6 text-neutral-700">Usage</h3>
        <p class="mt-2 leading-5 text-gray-500">
          See how much of your selected plan you have used.
        </p>
        <BaseChart id="test" type="line" />
        <div class="flex items-center p-5 mt-5 space-x-4 border-2 border-gray-100 rounded-lg">
          <div>
            <ProgressCircle :progress="35" :radius="80" :stroke="12" classes="text-blue-500" />
          </div>
          <div>
            Your workspace has used 3,500 of 10,000 unique visitors
          </div>
        </div>
      </div>
    </div>
    <div class="my-8 overflow-hidden bg-white rounded-lg ring-1 ring-black ring-opacity-5">
      <div class="p-6">
        <div class="flex items-start w-full">
          <div class="flex-grow">
            <h3 class="text-xl font-medium leading-6 text-neutral-700">Subscription</h3>
            <p class="mt-2 leading-5 text-gray-500">
              Make adjustments to your plan
            </p>
          </div>
          <div><BaseButton theme="secondary" @click="editPlans">Change</BaseButton></div>
        </div>
      </div>
    </div>

    <div class="my-8 overflow-hidden bg-white rounded-lg ring-1 ring-black ring-opacity-5">
      <div class="p-6">
        <h3 class="text-xl font-medium leading-6 text-neutral-700">Payment method</h3>
        <p class="mt-2 leading-5 text-gray-500">
          Set your preferred method of payment
        </p>
        <div class="py-6 mt-6 border-t border-gray-100 sm:flex sm:items-start sm:justify-between">
          <h4 class="sr-only">Visa</h4>
          <div class="sm:flex sm:items-start">
            <svg class="w-auto h-8 sm:flex-shrink-0 sm:h-6" viewBox="0 0 36 24" aria-hidden="true">
              <rect width="36" height="24" fill="#224DBA" rx="4" />
              <path
                fill="#fff"
                d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
              />
            </svg>
            <div class="mt-2 sm:mt-0 sm:ml-4">
              <div class="font-medium text-gray-900">
                Ending with 4242
              </div>
              <div class="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                <div>
                  Expires 12/20
                </div>
                <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">
                  &middot;
                </span>
                <div class="mt-1 sm:mt-0">
                  Last updated on 22 Aug 2017
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
            <BaseButton theme="secondary">Edit</BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
