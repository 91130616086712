<script>
import BaseButton from '@/components/Base/Button'
import Spinner from '@/components/Base/Spinner'

export default {
  name: 'Metrics',
  components: {
    BaseButton,
    Spinner,
  },
  data() {
    return {
      list: [],
      query: '',
      refreshing: false,
      loading: false,
      limit: 20,
      offset: 0,
      queriedCount: 0,
      totalCount: 0,
      editModal: false,
      searching: false,
      selectedProperty: null,
      updatingProperty: false,
    }
  },
  async created() {
    this.loading = true
    try {
      await this.getList()
    } catch (err) {
      this.$toastError('There was an error getting the metrics list')
    }
    this.loading = false
  },
  methods: {
    async getList(limit = 20, offset = 0, query) {
      const { list, queriedCount, totalCount } = await this.$store.dispatch('DATA_METRICS_GET_LIST', {
        limit,
        offset,
        query,
      })
      this.list = list
      this.offset = offset
      this.queriedCount = queriedCount
      this.totalCount = totalCount
    },
    changeListOffset(offset) {
      if (offset < 0 || offset >= this.queriedCount) return
      this.offset = offset
      this.getList(this.limit, offset, this.query)
    },
    async search(query) {
      this.searching = true
      this.query = query
      try {
        await this.getList(this.limit, 0, query)
      } catch (err) {
        this.$toastError('There was an error loading search results')
      }
      this.searching = false
    },
    async refresh() {
      this.refreshing = true
      try {
        await this.getList(20, this.offset, this.query)
      } catch (err) {
        this.$toastError('There was an error getting the metrics list')
      }
      this.refreshing = false
    },
    editProperty(property) {
      this.selectedProperty = property
      this.editModal = true
      this.$nextTick(() => {
        this.$refs.propertyLabel.focus()
      })
    },
    async updateProperty({ name, label }) {
      this.updatingProperty = true
      if (name === label) {
        this.editModal = false
        return
      }
      try {
        await this.$store.dispatch('DATA_PROPERTY_UPDATE', { propertyName: name, label })
        this.editModal = false
      } catch (err) {
        this.$toastError('There was a problem updating this property')
      }
      this.updatingProperty = false
    },
  },
}
</script>

<template>
  <div>
    <div class="flex items-center px-12 py-5">
      <div class="flex items-center flex-grow space-x-2">
        <svg class="w-6 h-6 text-pink-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <div>Create aggregated metrics to display publicly in your campaigns</div>
      </div>
      <div class="flex-initial text-right">
        <div class="flex items-center space-x-2">
          <div class="relative rounded-md shadow-sm">
            <input
              id="search"
              type="search"
              :value="query"
              class="block w-full text-gray-700 form-input"
              autocomplete="off"
              placeholder="Search"
              @input="(e) => search(e.target.value)"
            />
          </div>
          <!-- <div>
            <select
              id="integrationFilter"
              class="block w-full py-2 pl-3 pr-10 text-base leading-6 border-gray-300 form-select focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
            >
              <option value="ALL" selected>All integrations</option>
              <option value="CLIENT">Browser</option>
              <option value="SALESFORCE">Salesforce</option>
              <option value="CLEARBIT">Clearbit</option>
            </select>
          </div> -->
          <div>
            <BaseButton theme="flat" :loading="loading || refreshing || searching" @click="refresh">
              <div class="inline-flex items-center">
                <div class="mr-2">
                  <svg
                    class="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    ></path>
                  </svg>
                </div>
                <div>Refresh</div>
              </div>
            </BaseButton>
          </div>
          <div>
            <BaseButton :loading="loading || refreshing || searching" @click="refresh">
              <div class="inline-flex items-center">
                <!-- <div class="mr-2">
                  <svg
                    class="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    ></path>
                  </svg>
                </div> -->
                <div>New metric</div>
              </div>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <!-- LOADING INDICATOR -->
    <div v-if="loading" class="px-3 text-center py-36">
      <Spinner theme="light" />
    </div>
    <div v-if="!loading" class="flex flex-col px-12 pb-36">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow-xs sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr class="space-x-2">
                  <th
                    class="py-3 pl-6 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                  >
                    Name
                  </th>
                  <th
                    class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                  >
                    Label
                  </th>
                  <th
                    class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                  >
                    Integration
                  </th>
                  <th
                    class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                  >
                    Last tracked
                  </th>
                  <th
                    class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                  >
                    Total count
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="(property, i) in list"
                  :key="i"
                  class="space-x-2 cursor-pointer hover:bg-gray-50"
                  @click="editProperty(property)"
                >
                  <td class="py-4 pl-6 text-sm font-medium leading-5 text-gray-900 whitespace-no-wrap">
                    {{ property.name }}
                  </td>
                  <td class="py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">
                    {{ property.label }}
                  </td>
                  <td class="py-4 text-sm leading-5 text-gray-500 lowercase whitespace-no-wrap">
                    {{ property.integration || 'Browser' }}
                  </td>
                  <td class="py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">
                    {{ property.lastTracked | fromNow }}
                  </td>
                  <td class="py-4 text-sm font-medium leading-5 text-left whitespace-no-wrap">
                    {{ property.count | formatNumber }}
                  </td>
                </tr>
              </tbody>
            </table>
            <nav class="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
              <div class="flex-grow hidden sm:block">
                <p class="text-sm leading-5 text-gray-700">
                  Showing
                  <span class="font-medium">{{ queriedCount === 0 ? 0 : offset + 1 }}</span>
                  to
                  <span class="font-medium">{{ offset + limit > queriedCount ? queriedCount : offset + limit }}</span>
                  of
                  <span class="font-medium">{{ queriedCount | formatNumber }}</span>
                  properties
                </p>
              </div>
              <div class="flex items-center flex-initial space-x-2">
                <BaseButton
                  theme="secondary"
                  size="sm"
                  :disabled="offset - limit < 0"
                  @click="changeListOffset(offset - limit)"
                  >Previous</BaseButton
                >
                <BaseButton
                  theme="secondary"
                  size="sm"
                  :disabled="offset + limit >= queriedCount"
                  @click="changeListOffset(offset + limit)"
                  >Next</BaseButton
                >
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <!-- NAME CHANGE MODAL -->
    <div class="fixed inset-0 z-50 overflow-y-auto pointer-events-none">
      <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <transition
          enter-active-class="duration-300 ease-out"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-200 ease-in"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div v-if="editModal" class="fixed inset-0 transition-opacity pointer-events-auto" @click="editModal = false">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
        </transition>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

        <transition
          enter-active-class="duration-300 ease-out"
          enter-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
          enter-to-class="translate-y-0 opacity-100 sm:scale-100"
          leave-active-class="duration-200 ease-in"
          leave-class="translate-y-0 opacity-100 sm:scale-100"
          leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        >
          <div
            v-if="editModal"
            class="relative inline-block w-full max-w-2xl p-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl pointer-events-auto"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button
                type="button"
                class="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:text-gray-500"
                aria-label="Close"
                @click="editModal = false"
              >
                <!-- Heroicon name: x -->
                <svg
                  class="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="relative">
              <div class="absolute top-0 left-0 flex items-center justify-center bg-pink-100 rounded-full w-14 h-14">
                <svg
                  class="w-8 h-8 text-pink-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  ></path>
                </svg>
              </div>
              <div class="flex-grow ml-20 text-left">
                <h3 class="text-2xl font-medium leading-6 text-gray-900">
                  Edit property
                </h3>
                <!-- <div class="mt-2 mb-5">
                  <p class="leading-5 text-gray-500">
                    This label will be displayed in the audience builder dropdowns
                  </p>
                </div> -->

                <div class="mt-5">
                  <label for="propertyLabel" class="block space-x-2 font-medium leading-5 text-gray-700">
                    Label
                    <small class="inline-block text-sm text-gray-400">(what you see in the audience builder)</small>
                  </label>
                  <form class="relative mt-2 rounded-md shadow-sm" @submit.prevent="updateProperty(selectedProperty)">
                    <input
                      id="propertyLabel"
                      ref="propertyLabel"
                      class="block w-full text-gray-700 form-input"
                      placeholder="Something in plain english..."
                      :value="selectedProperty.label || selectedProperty.name"
                      @input="(e) => (selectedProperty.label = e.target.value)"
                    />
                    <button type="submit" class="hidden"></button>
                  </form>
                </div>
                <div class="mt-8 space-x-2 text-right">
                  <BaseButton theme="secondary" @click="editModal = false">Cancel</BaseButton>
                  <BaseButton :loading="updatingProperty" @click="updateProperty(selectedProperty)">Save</BaseButton>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
