import audiences from '@/views/audiences'
import audienceEditor from '@/views/audiences/audienceEditor'
import audienceView from '@/views/audiences/audienceView'

export default [
  {
    path: '/audiences',
    name: 'Audiences',
    component: audiences,
  },
  {
    path: '/audiences/view/:campaignId',
    name: 'Campaign Performance',
    component: audienceView,
  },
  {
    path: '/audiences/view/:campaignId/edit',
    name: 'Campaign Editor',
    component: audienceEditor,
  },
]
