import store from '@/store'

/**
 * Sign user into app
 * @param {string} email
 * @param {string} password
 */
function login(email, password) {
  return window.firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((userData) => {
      const { uid } = userData.user
      return { uid }
    })
}

function signup(email, password) {
  return window.firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((userData) => {
      const { uid } = userData.user
      return { uid }
    })
}

/**
 * Authenticate with Google
 */
function loginWithGoogle() {
  const provider = new window.firebase.auth.GoogleAuthProvider()
  return window.firebase
    .auth()
    .signInWithPopup(provider)
    .then(function (result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const idToken = result.credential.accessToken
      const user = result.user
      return { id: user.uid, email: user.email, displayName: user.displayName, photoUrl: user.photoURL, idToken }
    })
}

function isMagicLink() {
  return window.firebase.auth().isSignInWithEmailLink(window.location.href)
}

function loginWithMagicLink() {
  const email = window.localStorage.getItem('emailForSignIn')
  if (email && window.firebase.auth().isSignInWithEmailLink(window.location.href)) {
    return window.firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('Logged in with magic link')
        return true
      })
  } else return Promise.resolve()
}

/**
 * End user session
 */
function logout() {
  return window.firebase.auth().signOut()
}

/**
 * Change password
 * @param {string} password
 */
function updatePassword(password) {
  const user = window.firebase.auth().currentUser
  if (user) return user.updatePassword(password)
  else throw new Error('User is not logged in')
}

/**
 * Check if user is logged in
 */
function isLoggedIn() {
  const user = window.firebase.auth().currentUser
  return !!user && !!user.uid
}

/**
 * Returns a JSON Web Token (JWT) used to identify the user to a Firebase service.
 * https://firebase.google.com/docs/reference/js/firebase.User#getidtoken
 */
function getIdToken(forceRefresh) {
  const user = window.firebase.auth().currentUser
  if (user) return user.getIdToken(forceRefresh)
  else return store.state.member.idToken
}

function onAuthStateChange(callback) {
  window.firebase.auth().onAuthStateChanged(callback)
}

export default {
  login,
  signup,
  loginWithGoogle,
  loginWithMagicLink,
  isMagicLink,
  logout,
  updatePassword,
  isLoggedIn,
  getIdToken,
  onAuthStateChange,
}
