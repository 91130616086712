<script>
import Spinner from './Spinner'

export default {
  name: 'BaseButton',
  components: {
    Spinner,
  },
  props: {
    disabled: Boolean,
    loading: Boolean,
    classes: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    theme: {
      type: String,
      default: 'primary',
      validator: (val) => {
        return ['primary', 'secondary', 'flat', 'warning', 'danger', 'forward', 'dark'].indexOf(val) !== -1
      },
    },
  },
  data() {
    return {
      sizes: {
        xl: 'px-6 h-14 text-lg leading-5 tracking-tight',
        lg: 'px-8 py-4 text-lg leading-5 tracking-tight ',
        md: 'px-4 py-2 text-base leading-6',
        sm: 'px-3 py-2 text-sm leading-3',
      },
      themes: {
        primary: {
          container:
            'text-white bg-blue-400 shadow-sm border-transparent hover:bg-blue-300 focus:ring-blue-400 active:bg-blue-700',
          spinner: 'text-white bg-blue-400 rounded-lg',
        },
        secondary: {
          container:
            'text-gray-700 bg-white border-gray-300 shadow-sm hover:text-gray-500 focus:ring-blue-400 active:bg-gray-50 active:text-gray-800',
          spinner: 'text-gray-700 bg-white rounded-lg',
        },
        flat: {
          container:
            'text-gray-600 bg-gray-100 border-transparent hover:bg-gray-200 hover:text-gray-700 focus:ring-blue-400 active:bg-gray-100 active:text-gray-700',
          spinner: 'text-gray-600 bg-gray-100 rounded-lg',
        },
        warning: {
          container: 'text-red-700 border-transparent hover:bg-red-100 focus:ring-red-400 active:bg-red-100',
          spinner: 'text-red-600 bg-red-50 rounded-lg',
        },
        danger: {
          container:
            'text-white bg-red-600 border-transparent shadow-sm hover:bg-red-400 focus:ring-red-500 active:bg-red-700',
          spinner: 'text-white bg-red-600 rounded-lg',
        },
        forward: {
          container:
            'text-white bg-green-500 border-transparent shadow-sm hover:bg-green-400 focus:ring-green-400 active:bg-green-600',
          spinner: 'text-white bg-green-500 rounded-lg',
        },
        dark: {
          container:
            'text-white bg-neutral-700 border-transparent shadow-none hover:bg-neutral-600 focus:ring-offset-neutral-800 focus:ring-blue-400 focus:bg-neutral-800',
          spinner: 'text-white bg-neutral-700 rounded-lg',
        },
      },
    }
  },
  methods: {
    handleClick() {
      if (!this.disabled && !this.loading) this.$emit('click')
    },
  },
}
</script>

<template>
  <button
    :class="[
      sizes[size],
      {
        [themes[theme].container]: !disabled,
        'text-gray-400 bg-gray-100 border-transparent cursor-not-allowed opacity-75': disabled,
        'cursor-not-allowed': loading,
      },
      classes,
    ]"
    class="relative inline-flex items-center justify-center font-semibold transition duration-150 ease-in-out border rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2"
    @click="handleClick"
  >
    <transition name="fade">
      <div v-if="loading" class="absolute inset-0 flex items-center justify-center" :class="themes[theme].spinner">
        <Spinner classes="w-5 h-5" />
      </div>
    </transition>
    <slot />
  </button>
</template>
