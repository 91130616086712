<script>
export default {
  name: 'BasePill',
  components: {},
  props: {
    color: {
      type: String,
      default: 'gray',
    },
    size: {
      type: String,
      default: 'base',
    },
  },
  data() {
    return {
      sizes: {
        lg: 'px-3 py-1 text-sm',
        base: 'px-3 py-2 text-sm',
        sm: 'px-2 py-1 text-sm',
      },
    }
  },
}
</script>

<template>
  <div
    class="relative inline-flex items-center max-w-full font-medium leading-4 text-gray-500 bg-gray-100 rounded-lg select-none basePill"
    :class="[color, sizes[size]]"
  >
    <slot />
  </div>
</template>

<style lang="scss">
$blue600: #001eb7;
$blue500: #0026ea;
$blue400: #1e43ff;
$blue300: #516eff;
$blue100: #d9dfff;
$blue50: #eaedff;

$purple600: #7e04c9;
$purple500: #9d06fa;
$purple400: #b138fb;
$purple100: #eccefe;
$purple50: #f2defe;

$magenta600: #ad005e;
$magenta500: #e00079;
$magenta400: #ff1493;
$magenta100: #ffadd9;
$magenta50: #ffe0f1;

$gold600: #c97e04;
$gold500: #fa9d06;
$gold400: #fbb138;
$gold200: #fdd89c;
$gold50: #feecce;

$turquoise600: #006866;
$turquoise500: #009b97;
$turquoise400: #00cec9;
$turquoise100: #9bfffd;
$turquoise50: #cefffe;

$red600: #9f0214;
$red500: #d1031b;
$red400: #fc0b27;
$red100: #feb3bc;
$red50: #ffe5e8;

.basePill {
  // COLORS
  &.magenta {
    color: $magenta500;
    background-color: $magenta50;
  }
  &.gold {
    color: $gold600;
    background-color: $gold50;
  }
  &.turquoise {
    color: $turquoise600;
    background-color: $turquoise50;
  }
  &.purple {
    color: $purple600;
    background-color: $purple50;
  }
  &.blue {
    @apply text-blue-400 bg-blue-50;
  }
  &.green {
    @apply text-green-700 bg-green-100;
  }
  &.red {
    color: $red600;
    background-color: $red100;
  }
}
</style>
