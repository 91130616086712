import api from '@/api/settings'

export default {
  state: {},
  mutations: {
    // WORKSPACE_SET_USER_LIST: (state, { list, queriedCount }) => {
    //   state.users = list || []
    // },
  },
  actions: {
    WORKSPACES_GET_LIST: (store, query) => {
      return api.getList(query)
    },
    WORKSPACE_INVITE_USER: (store, email) => {
      return api.inviteUser(store.rootState.user.workspace.id, email)
    },
    WORKSPACE_GET_USERS: (store, { offset = 0, limit = 10, query } = {}) => {
      return api.getUsers(store.rootState.user.workspace.id, offset, limit, query)
    },
    WORKSPACE_REMOVE_USER: (store, email) => {
      return api.removeUser(store.rootState.user.workspace.id, email)
    },
    WORKSPACE_UPDATE: async (store, { name, domain }) => {
      return api.updateWorkspace(store.rootState.user.workspace.id, { name, domain }).then((workspace) => {
        return store.commit('USER_SET', { ...store.rootState.user, workspace })
      })
    },
    // WORKSPACE_CREATE: (store, { name, urlMatchRule, urlMatchType, logoUrl, description }) => {
    // return api.create({ name, urlMatchRule, urlMatchType, logoUrl, description }).then((list) => {
    //   store.commit('WORKSPACES_SET_LIST', list)
    // })
    // },
    // WORKSPACE_UPDATE: (store, { id, name, urlMatchRule, urlMatchType, logoUrl, description }) => {
    //   return api.update({ id, name, urlMatchRule, urlMatchType, logoUrl, description }).then((list) => {
    //     store.commit('WORKSPACES_SET_LIST', list)
    //   })
    // },
    // WORKSPACE_DELETE: (store, id) => {
    //   return api.delete(id).then((list) => {
    //     store.commit('WORKSPACES_SET_LIST', list)
    //   })
    // },
  },
}
