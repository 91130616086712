<script>
import BaseButton from '@/components/Base/Button'
import Spinner from '@/components/Base/Spinner'
import Pill from '@/components/Base/Pill'
import utils from '@/lib/utils'
import dayjs from 'dayjs'

export default {
  name: 'AudiencesList',
  components: {
    BaseButton,
    Spinner,
    Pill,
  },
  filters: {
    date(val) {
      if (utils.getDelta(val, 'hour') > 24) return dayjs(val).format('MMM D, YYYY')
      else return utils.fromNow(val)
    },
  },
  data() {
    return {
      list: [],
      query: '',
      loading: false,
      limit: 10,
      offset: 0,
      queriedCount: 0,
      queriedActiveCount: 0,
      queriedInactiveCount: 0,
      totalCount: 0,
      searching: false,
      filterByStatus: null,
      orderBy: 'updated_at',
      orderByDirection: 'desc',
      showNewCampaignModal: false,
    }
  },
  async created() {
    this.loading = true
    try {
      await this.getList()
    } catch (err) {
      this.$toastError('There was an error getting the campaigns list')
    }
    this.loading = false
  },
  methods: {
    async getList(limit = 20, offset = 0, filterByStatus, query, orderBy, orderByDirection) {
      const { list, queriedCount, totalCount, queriedActiveCount, queriedInactiveCount } = await this.$store.dispatch(
        'CAMPAIGNS_GET_LIST',
        {
          limit,
          offset,
          filterByStatus,
          query,
          orderBy,
          orderByDirection,
        }
      )
      this.list = list
      this.offset = offset
      this.queriedCount = queriedCount || 0
      this.queriedActiveCount = queriedActiveCount || 0
      this.queriedInactiveCount = queriedInactiveCount || 0
      this.totalCount = totalCount || 0
    },
    async sortList(property) {
      if (['updated_at', 'name', 'firstSetLive', 'type', 'active'].indexOf(property) === -1) return
      if (property === this.orderBy) this.orderByDirection = this.orderByDirection === 'asc' ? 'desc' : 'asc'
      else if (property === 'updated_at' && this.orderBy !== 'updated_at') this.orderByDirection = 'desc'
      else if (property === 'active' && this.orderBy !== 'active') this.orderByDirection = 'desc'
      else this.orderByDirection = 'asc'

      this.searching = true
      this.orderBy = property
      await this.getList(this.limit, this.offset, this.filterByStatus, this.query, property, this.orderByDirection)
      this.searching = false
    },
    async changeListOffset(offset) {
      this.searching = true
      if (offset < 0 || offset >= this.queriedCount) return
      this.offset = offset
      try {
        await this.getList(this.limit, offset, this.filterByStatus, this.query, this.orderBy, this.orderByDirection)
      } catch (err) {
        this.$toastError('There was an error loading results')
      }
      this.searching = false
    },
    async search(query) {
      this.searching = true
      this.query = query
      try {
        await this.getList(this.limit, 0, this.filterByStatus, query, this.orderBy, this.orderByDirection)
      } catch (err) {
        this.$toastError('There was an error loading search results')
      }
      this.searching = false
    },
    openCampaign(row) {
      this.$router.push(`/campaigns/view/${row.id}`)
    },
    async filterBy(status) {
      if (this.filterByStatus === status) return
      this.filterByStatus = status
      this.loading = true
      try {
        await this.getList(this.limit, this.offset, status, this.query, this.orderBy, this.orderByDirection)
      } catch (err) {
        this.$toastError('There was an error loading results')
      }
      this.loading = false
    },
    hoursFromNow(ts, roundUpToDays = false) {
      // Only show if campaign is active
      if (!ts) return '-'
      ts = new Date(ts).getTime()
      const date = new Date(ts)
      const minute = 60
      const hour = minute * 60
      const day = hour * 24

      // round to the nearest
      return Math.round((+new Date() - date) / 1000 / (roundUpToDays ? day : hour))
    },
    async clearFilters() {
      this.query = ''
      this.filterByStatus = null
      this.loading = true
      try {
        await this.getList(this.limit, 0, this.filterByStatus, this.query, this.orderBy, this.orderByDirection)
      } catch (err) {
        this.$toastError('There was an error loading search results')
      }
      this.loading = false
    },
    newAudience() {
      return this.$store
        .dispatch('AUDIENCES_CREATE')
        .then(({ id }) => {
          this.$router.push(`/campaigns/view/${id}/edit`)
        })
        .catch(() => {
          this.$toastError('There was an error creating your campaign')
        })
    },
  },
}
</script>

<template>
  <div class="px-6 xl:px-12">
    <!-- Header -->
    <div class="flex items-center">
      <div class="flex items-center flex-grow py-8 space-x-4 text-3xl font-bold tracking-tight text-neutral-700">
        <div class="p-3 bg-white rounded-full text-neutral-300">
          <svg class="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
            ></path>
          </svg>
        </div>
        <div>Audiences</div>
      </div>
      <div class="flex-initial text-right">
        <div class="flex items-center space-x-2">
          <div>
            <BaseButton @click="newAudience">
              <div class="inline-flex items-center">
                <div>New audience</div>
              </div>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <!-- Filters and Search -->
    <div class="mt-2 mb-4">
      <div class="flex items-center">
        <div class="flex items-center flex-grow space-x-4">
          <!-- Filters -->
          <div class="flex items-center bg-white rounded-md shadow-sm text-neutral-500">
            <!-- <div class="px-3 py-3 text-lg border-r border-gray-100 text-neutral-200">
              <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div> -->
            <div class="border-r border-gray-100">
              <button
                :class="{ 'text-blue-400': filterByStatus === null }"
                class="px-5 py-3 font-medium leading-4 transition-all duration-200 rounded-l-md hover:text-blue-400 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-400 group"
                @click="filterBy(null)"
              >
                <div class="flex items-center space-x-2">
                  <div>All</div>
                  <div
                    class="flex items-center h-6 px-2 text-xs font-semibold bg-gray-100 rounded-full group-focus:bg-blue-300 group-focus:text-white"
                    :class="{ 'text-white bg-blue-300': filterByStatus === null }"
                  >
                    <div class="mx-auto" style="height: 0.8rem;">{{ queriedCount }}</div>
                  </div>
                </div>
              </button>
            </div>
            <div class="border-r border-gray-100">
              <button
                :class="{ 'text-green-600': filterByStatus === 'LIVE' }"
                class="px-5 py-3 font-medium transition-all duration-200 hover:text-green-500 focus:z-10 focus:outline-none focus:ring-2 focus:ring-green-400"
                @click="filterBy('LIVE')"
              >
                <div class="flex items-center space-x-2">
                  <div>Live</div>
                  <div
                    class="flex items-center h-6 px-2 text-xs font-semibold bg-gray-100 rounded-full group-focus:bg-green-500 group-focus:text-white"
                    :class="{ 'text-white bg-green-500': filterByStatus === 'LIVE' }"
                  >
                    <div class="mx-auto">{{ queriedActiveCount }}</div>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button
                :class="{ 'text-gray-500': filterByStatus === 'INACTIVE' }"
                class="px-5 py-3 font-medium transition-all duration-200 rounded-r-md focus:z-10 hover:text-gray-600 focus:ring-2 focus:outline-none focus:ring-gray-400"
                @click="filterBy('INACTIVE')"
              >
                <div class="flex items-center space-x-2">
                  <div>Inactive</div>
                  <div
                    class="flex items-center h-6 px-2 text-xs font-semibold bg-gray-100 rounded-full group-focus:bg-gray-500 group-focus:text-white"
                    :class="{ 'text-white bg-gray-500': filterByStatus === 'INACTIVE' }"
                  >
                    <div class="mx-auto">{{ queriedInactiveCount }}</div>
                  </div>
                </div>
              </button>
            </div>
          </div>
          <!-- Search bar -->
          <div class="relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                v-if="!searching"
                class="w-5 h-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <Spinner v-else classes="h-5 w-5 text-blue-400" />
            </div>
            <input
              id="query"
              type="search"
              class="block w-full py-3 pl-10 text-lg text-gray-700 transition-all duration-200 border-transparent form-input"
              placeholder="Search"
              autocomplete="off"
              :value="query"
              @input="(e) => search(e.target.value)"
            />
          </div>
          <!-- Clear filters -->
          <div v-if="query || filterByStatus">
            <BaseButton theme="flat" size="sm" @click="clearFilters">
              <span class="flex items-center space-x-2">
                <div>
                  <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div>Clear filters</div>
              </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <!-- LOADING INDICATOR -->
    <div v-if="loading" class="flex items-center justify-center w-full text-center p-36">
      <Spinner classes="h-6 w-6 text-blue-400" />
    </div>

    <!-- List -->
    <div v-if="!loading && list.length" class="pb-36">
      <div class="min-w-full">
        <!-- Header Row -->
        <div
          class="grid grid-cols-7 gap-2 px-6 py-4 text-sm font-semibold leading-4 text-left select-none text-neutral-600"
        >
          <div class="col-span-3 cursor-pointer hover:text-neutral-700" @click="sortList('name')">
            Name
            <span
              :class="{ 'opacity-100': orderBy === 'name' }"
              class="inline-block ml-2 transform translate-y-1 opacity-0"
            >
              <svg
                v-if="orderByDirection === 'desc'"
                class="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
              </svg>
              <svg
                v-else
                class="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path>
              </svg>
            </span>
          </div>
          <div class="col-span-1 cursor-pointer hover:text-neutral-700" @click="sortList('active')">
            Status
            <span
              :class="{ 'opacity-100': orderBy === 'active' }"
              class="inline-block ml-2 transform translate-y-1 opacity-0"
            >
              <svg
                v-if="orderByDirection === 'desc'"
                class="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
              </svg>
              <svg
                v-else
                class="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path>
              </svg>
            </span>
          </div>
          <div class="col-span-1 cursor-pointer hover:text-neutral-700" @click="sortList('updated_at')">
            Last updated
            <span
              :class="{ 'opacity-100': orderBy === 'updated_at' }"
              class="inline-block ml-2 transform translate-y-1 opacity-0"
            >
              <svg
                v-if="orderByDirection === 'desc'"
                class="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
              </svg>
              <svg
                v-else
                class="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path>
              </svg>
            </span>
          </div>
          <div class="col-span-1 cursor-pointer hover:text-neutral-700" @click="sortList('type')">
            Type
            <span
              :class="{ 'opacity-100': orderBy === 'type' }"
              class="inline-block ml-2 transform translate-y-1 opacity-0"
            >
              <svg
                v-if="orderByDirection === 'desc'"
                class="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
              </svg>
              <svg
                v-else
                class="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path>
              </svg>
            </span>
          </div>
          <div class="col-span-1 cursor-pointer hover:text-neutral-700" @click="sortList('firstSetLive')">
            First set live
            <span
              :class="{ 'opacity-100': orderBy === 'firstSetLive' }"
              class="inline-block ml-2 transform translate-y-1 opacity-0"
            >
              <svg
                v-if="orderByDirection === 'desc'"
                class="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
              </svg>
              <svg
                v-else
                class="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path>
              </svg>
            </span>
          </div>
        </div>
        <!-- List rows -->
        <div class="space-y-2">
          <div v-for="row in list" :key="row.id" class="relative group">
            <button
              class="relative z-0 grid items-center w-full grid-cols-7 gap-2 px-6 py-5 text-left transition-all duration-200 bg-white rounded-md outline-none cursor-pointer focus:outline-none focus:ring-blue-400 focus:ring-2 focus:ring-offset-2 hover:shadow-lg hover:z-10 group"
              @click="openCampaign(row)"
            >
              <div class="col-span-3 whitespace-no-wrap transition-all duration-200">
                <div class="text-xl font-semibold tracking-tight text-neutral-600 group-hover:text-neutral-700">
                  <div class="w-full truncate">{{ row.name || 'Untitled' }}</div>
                </div>
              </div>
              <div class="col-span-1 font-medium leading-5 whitespace-no-wrap text-neutral-600">
                <Pill :color="row.active ? 'green' : row.firstSetLive ? 'magenta' : 'gray'" size="sm">
                  {{ row.active ? 'Live' : 'Draft' }}
                </Pill>
              </div>
              <div class="col-span-1 text-sm font-normal leading-5 whitespace-no-wrap text-neutral-600">
                <div class="flex items-center space-x-1">
                  <div>
                    <span
                      v-if="hoursFromNow(row.updated_at) < 1"
                      class="relative block w-4 h-4 bg-green-400 border-4 border-white rounded-full"
                    ></span>
                  </div>
                  <div>{{ row.updated_at | date }}</div>
                </div>
              </div>
              <div class="col-span-1 text-sm leading-5 whitespace-no-wrap text-neutral-600">
                {{ row.type }}
              </div>
              <div class="col-span-1 leading-5 whitespace-no-wrap text-neutral-600">
                <span v-if="row.firstSetLive && row.active"
                  >{{ hoursFromNow(row.firstSetLive, true) }} day{{
                    hoursFromNow(row.firstSetLive, true) === 1 ? '' : 's'
                  }}</span
                >
                <span v-else class="text-sm font-medium text-neutral-300">Never</span>
              </div>
            </button>
            <svg
              class="absolute z-20 w-5 h-5 text-gray-300 transition-all duration-200 transform -translate-y-1/2 opacity-0 pointer-events-none top-1/2 right-8 group-hover:opacity-100"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <nav class="flex items-center justify-between py-3 mt-5">
        <div class="flex-grow hidden sm:block">
          <p class="leading-5 text-neutral-500">
            Showing
            <span class="font-medium">
              <span v-if="filterByStatus === 'LIVE'">{{ queriedActiveCount === 0 ? 0 : offset + 1 }}</span>
              <span v-else-if="filterByStatus === 'INACTIVE'">{{ queriedInactiveCount === 0 ? 0 : offset + 1 }}</span>
              <span v-else>{{ queriedCount === 0 ? 0 : offset + 1 }}</span>
            </span>
            to
            <span class="font-medium">
              <span v-if="filterByStatus === 'LIVE'">{{
                offset + limit > queriedActiveCount ? queriedActiveCount : offset + limit
              }}</span>
              <span v-else-if="filterByStatus === 'INACTIVE'">{{
                offset + limit > queriedInactiveCount ? queriedInactiveCount : offset + limit
              }}</span>
              <span v-else>{{ offset + limit > queriedCount ? queriedCount : offset + limit }}</span>
            </span>
            of
            <span class="font-medium">
              <span v-if="filterByStatus === 'LIVE'">{{ queriedActiveCount | formatNumber }}</span>
              <span v-else-if="filterByStatus === 'INACTIVE'">{{ queriedInactiveCount | formatNumber }}</span>
              <span v-else>{{ queriedCount | formatNumber }}</span>
            </span>
            campaign{{ queriedCount === 1 ? '' : 's' }}
          </p>
        </div>
        <div class="flex items-center flex-initial space-x-2">
          <BaseButton theme="secondary" :disabled="offset - limit < 0" @click="changeListOffset(offset - limit)"
            >Previous</BaseButton
          >
          <BaseButton
            theme="secondary"
            :disabled="offset + limit >= queriedCount"
            @click="changeListOffset(offset + limit)"
            >Next</BaseButton
          >
        </div>
      </nav>
    </div>

    <!-- Empty state -->
    <div v-if="!loading && !list.length" class="mt-8 text-center bg-white rounded-lg p-36">
      <!-- <Illustrations :name="query ? 'noMatches' : 'noneInCategory'" /> -->
      <div class="text-xl font-bold text-neutral-600">
        {{ query ? 'Nothing matched your search' : 'Nothing to see here' }}
        <div class="mt-1 text-base font-normal text-neutral-500">
          <span v-if="query">Try broadening your query</span>
          <span v-else>No {{ filterByStatus === 'all' ? '' : filterByStatus }} campaigns yet</span>
        </div>
      </div>
    </div>
  </div>
</template>
