<script>
import NavIcons from './NavIcons'
import ProfileMenu from './ProfileMenu'

export default {
  name: 'SidebarShell',
  components: {
    NavIcons,
    ProfileMenu,
  },
  data() {
    return {
      admin: false,
      primaryNav: [
        // {
        //   name: 'setup',
        //   label: 'Setup',
        //   link: '/setup',
        // },
        {
          name: 'campaigns',
          label: 'Campaigns',
          link: '/campaigns',
          campaignCount: true,
        },
        {
          name: 'audiences',
          label: 'Audiences',
          link: '/audiences',
        },
        {
          name: 'data',
          label: 'Data',
          link: '/data',
        },
      ],
    }
  },
  computed: {
    campaignCount() {
      return this.$store.state.campaigns.list.length
    },
  },
  methods: {
    isActive(path) {
      return this.$route.path.indexOf(path) !== -1
    },
  },
}
</script>

<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Off-canvas menu for mobile -->
    <div class="md:hidden">
      <div class="fixed inset-0 z-40 flex">
        <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
        <div class="fixed inset-0">
          <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
        </div>
        <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
        <div class="relative flex flex-col flex-1 w-full max-w-xs bg-white rounded-lg">
          <div class="absolute top-0 right-0 p-1 -mr-14">
            <button
              class="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-gray-600"
              aria-label="Close sidebar"
            >
              <svg class="w-6 h-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
            <div class="flex items-center flex-shrink-0 px-4 text-white cursor-pointer" @click="$router.push('/setup')">
              <div class="p-2 bg-purple-900 rounded-full hover:bg-purple-600">
                <svg class="w-auto h-6" viewBox="0 0 86 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M40.6799 2.79643L34.1513 11.4609H33.5408L33.2889 12.6054L32.4664 13.6969L30.6181 3.63852L30.3169 2H28.651H15.6931H14.6959L14.0958 2.79643L3.40268 16.9877L2.85328 17.7169L3.04424 18.6096L14.5541 72.4183L14.8925 74H16.5099H29.9876H30.9848L31.5849 73.2036L38.1135 64.5391H38.7067L38.9693 63.4035L39.9333 62.1241L42.3728 72.4594L42.7364 74H44.3193H57.8342H58.8124L59.4129 73.2278L71.0787 58.2278L71.3698 57.8537L71.4619 57.3887L81.9619 4.38867L82.4351 2H80H67.0792H66.082L65.4819 2.79643L58.9533 11.4609H58.2845L58.0688 12.6347L57.491 13.4016L55.2935 3.56399L54.9441 2H53.3416H42.2772H41.28L40.6799 2.79643Z"
                    fill="#9E02FD"
                    stroke="#9E02FD"
                    stroke-width="4"
                  />
                  <path
                    d="M66.5768 3.6325L62.3741 26.1152L57.2482 3.55684L56.8944 2H55.2979H44.6305H43.0287L42.6788 3.56308L37.6473 26.0381L33.459 3.6325L33.1539 2H31.4931H19H16.518L17.0457 4.42523L28.1427 55.4252L28.4854 57H30.097H43.0912H44.6754L45.0381 55.4579L49.9486 34.5778L54.964 55.4669L55.3321 57H56.9088H69.9388H71.5515L71.8934 55.4239L82.9546 4.42392L83.4803 2H81H68.5427H66.8819L66.5768 3.6325Z"
                    fill="white"
                    stroke="#9E02FD"
                    stroke-width="4"
                  />
                </svg>
              </div>
              <div class="ml-3 font-bold text-gray-900">Proof</div>
            </div>
            <nav class="px-2 mt-5">
              <router-link
                v-for="(nav, i) in primaryNav"
                :key="i"
                :to="nav.link"
                :class="{ 'text-white': isActive(nav.link) }"
                class="flex items-center px-2 py-2 mb-1 text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out rounded-lg group focus:outline-none focus:bg-purple-100"
              >
                <NavIcons :name="nav.name" />
                {{ nav.label }}
              </router-link>
            </nav>
          </div>
          <div class="flex flex-shrink-0 p-4 border-t border-gray-200">
            <a href="#" class="flex-shrink-0 block group focus:outline-none">
              <div class="flex items-center">
                <div>
                  <img
                    class="inline-block w-10 h-10 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
                <div class="ml-3">
                  <p class="text-base font-medium leading-6 text-gray-700 group-hover:text-purple-800">
                    Dave Rogenmoser
                  </p>
                  <p
                    class="text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-700 group-focus:underline"
                  >
                    View profile
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </div>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="relative z-10 flex flex-col w-64 bg-neutral-900">
        <div class="flex flex-col flex-1 h-0 pt-8 pb-4 overflow-y-auto">
          <div
            class="flex items-center flex-shrink-0 px-8 text-white cursor-pointer select-none"
            @click="$route.path !== '/setup' && $router.push('/')"
          >
            <div>
              <svg class="w-auto h-9" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.8108 12.75H32C31.5016 15.1499 30.1747 17.3068 28.2441 18.8558C26.3135 20.4047 23.8976 21.2505 21.4054 21.25H0C0.498444 18.8501 1.82526 16.6932 3.75589 15.1442C5.68651 13.5953 8.10237 12.7495 10.5946 12.75H10.8108ZM32 8.5C31.5016 6.1001 30.1747 3.94316 28.2441 2.39422C26.3135 0.84529 23.8976 -0.000499695 21.4054 2.21485e-07H10.5946V8.5H32ZM10.5946 34C13.0868 34.0005 15.5027 33.1547 17.4333 31.6058C19.3639 30.0568 20.6907 27.8999 21.1892 25.5H10.5946V34Z"
                  fill="url(#brand_linear)"
                />
                <defs>
                  <linearGradient
                    id="brand_linear"
                    x1="16"
                    y1="2.21485e-07"
                    x2="16"
                    y2="34"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FF1493" />
                    <stop offset="0.39" stop-color="#FD576C" />
                    <stop offset="0.81" stop-color="#FC9847" />
                    <stop offset="1" stop-color="#FBB138" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <nav class="flex-1 mt-8">
            <router-link
              v-for="(nav, i) in primaryNav"
              :key="i"
              :to="nav.link"
              :class="{ 'text-neutral-400': !isActive(nav.link), 'text-white': isActive(nav.link) }"
              class="relative flex items-center px-8 py-3 mb-1 text-base font-medium leading-5 transition duration-150 ease-in-out rounded-lg group hover:text-white focus:outline-none"
            >
              <transition name="fade">
                <div v-if="isActive(nav.link)" class="absolute top-0 bottom-0 left-0 w-3 bg-blue-400 rounded-r"></div>
              </transition>
              <NavIcons :name="nav.name" />
              {{ nav.label }}
              <span
                v-if="nav.campaignCount && campaignCount > 0"
                class="inline-block px-3 py-1 ml-auto text-xs font-medium leading-4 transition duration-150 ease-in-out rounded-full"
              >
                {{ campaignCount }}
              </span>
            </router-link>
          </nav>
        </div>
        <div>
          <router-link
            to="/setup"
            :class="{ 'text-neutral-400': !isActive('/setup'), 'text-white': isActive('/setup') }"
            class="relative flex items-center px-8 py-3 mb-1 text-base font-medium leading-5 transition duration-150 ease-in-out rounded-lg group hover:text-white focus:outline-none"
          >
            <transition name="fade">
              <div v-if="isActive('/setup')" class="absolute top-0 bottom-0 left-0 w-3 bg-blue-400 rounded-r"></div>
            </transition>
            <NavIcons name="setup" />
            Setup
          </router-link>
          <router-link
            to="/settings"
            :class="{ 'text-neutral-400': !isActive('/settings'), 'text-white': isActive('/settings') }"
            class="relative flex items-center px-8 py-3 mb-1 text-base font-medium leading-5 transition duration-150 ease-in-out rounded-lg group hover:text-white focus:outline-none"
          >
            <transition name="fade">
              <div v-if="isActive('/settings')" class="absolute top-0 bottom-0 left-0 w-3 bg-blue-400 rounded-r"></div>
            </transition>
            <NavIcons name="settings" />
            Settings
          </router-link>
        </div>
        <ProfileMenu />
      </div>
    </div>
    <div class="flex flex-col flex-1 w-0 overflow-hidden">
      <div class="pt-1 pl-1 md:hidden sm:pl-3 sm:pt-3">
        <button
          class="-ml-0.5 -mt-0.5 h-12 w-12 group inline-flex items-center justify-center rounded-md text-gray-500 hover:text-purple-800 focus:outline-none focus:bg-purple-100 transition ease-in-out duration-150"
          aria-label="Open sidebar"
        >
          <svg class="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>
      <main
        class="relative flex-1 overflow-hidden overflow-y-auto rounded-l-lg focus:outline-none ring-4 ring-neutral-900"
        tabindex="0"
      >
        <slot></slot>
      </main>
    </div>
  </div>
</template>
