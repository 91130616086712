<script>
import BaseButton from '@/components/Base/Button'
import BaseDropdown from '@/components/Base/Dropdown'
import CodeEditor from '@/components/Base/CodeEditor'
import Panel from './panel'
import _get from 'lodash/get'
import campaignTypes from '@/lib/campaignTypes'
import bannerEditor from './contentEditor/banner'

export default {
  name: 'ContentStep',
  components: {
    BaseButton,
    BaseDropdown,
    CodeEditor,
    Panel,
    bannerEditor,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    active: Boolean,
  },
  data() {
    return {
      code: '',
      content: {},
      dirty: false,
      activeVariant: {},
      saving: false,
      campaignTypes,
      loadingVariant: false,
    }
  },
  created() {
    this.activeVariant = _get(this.campaign, 'campaignVariants[0]', {})
    this.code = _get(this.campaign, 'campaignVariants[0].js', '\n\n\n\n')
    this.content = this.activeVariant.content || {}
  },
  methods: {
    selectVariant(variant) {
      this.loadingVariant = true
      this.activeVariant = variant
      this.code = variant.js
      this.content = variant.content || { position: 'bottom', styleType: 'floating', bgColor: '#2563EB' }
      this.$nextTick(() => {
        this.loadingVariant = false
      })
    },
    handleCodeInput(value) {
      this.$emit('dirtyStatusChange')
      this.dirty = true
      this.code = value
    },
    handleContentInput(value) {
      this.$emit('dirtyStatusChange')
      this.dirty = true
      this.content = value
    },
    async newVariant(type) {
      try {
        await this.$store.dispatch('CAMPAIGNS_CREATE_VARIANT', {
          campaignId: this.campaign.id,
          type: type || 'CUSTOM_CODE',
        })
        this.dirty = true
        this.$toastSuccess('Added variant')
        this.$emit('refresh')
      } catch (err) {
        this.$toastError('There was an error adding a new variant')
      }
    },
    async saveChanges() {
      this.saving = true
      try {
        await this.$store.dispatch('CAMPAIGNS_UPDATE_VARIANTS', {
          variantId: this.activeVariant.id,
          js: this.code,
          content: this.content,
        })
        this.$emit('refresh')
        this.$toastSuccess(
          'Campaign saved',
          this.campaign.active ? 'Changes can take up to 60 seconds to deploy to our super fast servers globally' : ''
        )
        this.dirty = false
      } catch (err) {
        this.$toastError('Campaign failed to save')
      }
      this.saving = false
    },
    cancel() {
      this.code = this.activeVariant.js || ''
      this.dirty = false
      this.$emit('refresh')
    },
  },
}
</script>

<template>
  <Panel :active="active" :clickable="true" @click="() => !active && $emit('openStep')">
    <div class="flex items-center">
      <div
        :class="{ 'text-neutral-700': active, 'text-gray-500': !active }"
        class="inline-flex items-center flex-grow space-x-6 text-2xl font-semibold tracking-tighter transition-all duration-200 group-hover:text-neutral-700"
      >
        <div>Content</div>
        <div v-if="!active" class="px-2 py-2 text-sm font-medium tracking-tight text-gray-500 bg-gray-100 rounded-lg">
          <div v-if="campaign.campaignVariants.length < 2">{{ campaignTypes[campaign.campaignVariants[0].type] }}</div>
          <div v-if="campaign.campaignVariants.length > 1">{{ campaign.campaignVariants.length }} variants</div>
        </div>
      </div>
      <div v-if="dirty" class="flex-initial">
        <BaseButton theme="flat" classes="mr-2" @click="cancel">Cancel</BaseButton>
        <BaseButton :disabled="!dirty" :loading="saving" @click="saveChanges">Save</BaseButton>
      </div>
    </div>
    <div v-if="active">
      <nav class="hidden my-3 lg:py-2 lg:flex lg:space-x-2" aria-label="Global">
        <div v-if="campaign.campaignVariants.length > 1" class="flex items-center space-x-2">
          <BaseButton
            v-for="(v, i) in campaign.campaignVariants"
            :key="i"
            :theme="v.id === activeVariant.id ? 'primary' : 'flat'"
            size="sm"
            @click="selectVariant(v)"
          >
            <div class="flex items-center space-x-2">
              <div>{{ v.type === 'CONTROL' ? 'Control group' : `Variant ${i + 1}` }}</div>
            </div>
          </BaseButton>
        </div>
        <BaseDropdown v-if="campaign.campaignVariants.length < 2" size="sm" label="Run a new test">
          <button
            class="flex items-center w-full px-3 py-2 space-x-2 text-sm font-medium text-gray-600 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            @click="newVariant(activeVariant.type)"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
              ></path>
            </svg>
            <div>A/B test</div>
          </button>
          <button
            class="w-full px-3 py-2 text-sm text-left rounded-lg cursor-pointer focus:outline-none hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            @click="newVariant('CONTROL')"
          >
            <div class="flex items-center space-x-2 font-medium text-gray-600">
              <svg
                class="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                ></path>
              </svg>
              <div>Control group</div>
            </div>
          </button>
        </BaseDropdown>
      </nav>
      <div v-if="activeVariant.type !== 'CONTROL' && !loadingVariant">
        <CodeEditor v-if="activeVariant.type === 'CUSTOM_CODE'" :code="code" @input="handleCodeInput" />
        <bannerEditor
          v-else-if="activeVariant.type.indexOf('BANNER') !== -1"
          :content="content"
          @change="handleContentInput"
        />
      </div>
    </div>
  </Panel>
</template>
