<script>
import BaseButton from '@/components/Base/Button'
import Pill from '@/components/Base/Pill'
import Avatar from '@/components/Base/Avatar'
import Spinner from '@/components/Base/Spinner'

export default {
  name: 'TeamSettings',
  components: {
    BaseButton,
    Pill,
    Avatar,
    Spinner,
  },
  props: {},
  data() {
    return {
      email: '',
      loading: true,
      users: [],
      sendingInvite: false,
      removingUser: false,
      errorMsg: null,
    }
  },
  async created() {
    this.setList(await this.$store.dispatch('WORKSPACE_GET_USERS'))
    this.loading = false
  },
  methods: {
    setList({ users, invites }) {
      this.users = users
      this.invites = invites
    },
    async invite(newEmail) {
      if (this.users.map((u) => u.email).indexOf(newEmail) !== -1) {
        this.errorMsg = 'Whoops! That user already exists.'
        return
      }
      try {
        this.sendingInvite = true
        this.setList(await this.$store.dispatch('WORKSPACE_INVITE_USER', newEmail))
        this.$toastSuccess('Your invitation has been sent')
        this.email = ''
      } catch (err) {
        this.$toastError(err)
      }
      this.sendingInvite = false
    },
    async remove(email) {
      try {
        this.removingUser = true
        this.setList(await this.$store.dispatch('WORKSPACE_REMOVE_USER', email))
        this.$toastWarning('Team member removed')
      } catch (err) {
        this.$toastError('There was a problem removing this user')
      }
      this.removingUser = false
    },
  },
}
</script>

<template>
  <div>
    <!-- LOADING INDICATOR -->
    <transition name="fade">
      <div v-if="loading || removingUser" class="absolute top-4 left-4">
        <Spinner classes="h-8 w-8 text-teal-500" />
      </div>
    </transition>
    <transition name="fade-in">
      <div v-if="!loading" class="flex flex-col">
        <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div
            class="inline-block min-w-full overflow-hidden align-middle bg-white ring-1 ring-black ring-opacity-5 sm:rounded-lg"
          >
            <div class="grid grid-flow-col p-6">
              <div>
                <h3 class="text-xl font-medium leading-6 text-neutral-700">Team members</h3>
                <p class="mt-2 leading-5 text-gray-500">
                  Collaborate with others in your account
                </p>
                <div class="mt-6 mb-4">
                  <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Email</label>
                  <form class="relative flex items-center mt-1 space-x-3" @submit.prevent="invite(email)">
                    <div>
                      <input
                        id="email"
                        v-model="email"
                        required
                        type="email"
                        autocomplete="off"
                        class="block w-full form-input"
                        placeholder="tim@apple.com"
                        aria-describedby="email-description"
                      />
                    </div>
                    <div>
                      <BaseButton :loading="sendingInvite">
                        Send invite
                      </BaseButton>
                    </div>
                  </form>
                  <p v-if="errorMsg" class="mt-1 text-xs tracking-tight text-red-600">{{ errorMsg }}</p>
                </div>
              </div>
            </div>
            <table v-if="users.length || invites.length" class="min-w-full">
              <thead>
                <tr>
                  <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase">
                    Member
                  </th>
                  <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase">
                    Status
                  </th>
                  <th class="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr v-for="user in users" :key="user.id">
                  <td class="px-6 py-4 whitespace-no-wrap border-t border-gray-200">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 w-10 h-10">
                        <Avatar size="2.5" :name="user.firstName || user.email" />
                      </div>
                      <div class="ml-4">
                        <div class="font-medium leading-5 text-gray-900">
                          {{ user.firstName }} {{ user.lastName }}
                        </div>
                        <div class="text-sm leading-5 text-gray-500">{{ user.email }}</div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-t border-gray-200">
                    <Pill color="green" size="sm">Active</Pill>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap border-t border-gray-200"
                  >
                    <button
                      v-if="$user().id !== user.id"
                      class="p-1 text-gray-400 transition duration-150 ease-in-out rounded hover:text-gray-500 hover:bg-gray-100 focus:ring-blue-400 focus:outline-none"
                      @click="remove(user.email)"
                    >
                      <svg
                        class="w-4 h-4"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        ></path>
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr v-for="user in invites" :key="user.id">
                  <td class="px-6 py-4 whitespace-no-wrap border-t border-gray-200">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 w-10 h-10">
                        <Avatar size="2.5" :name="user.firstName || user.email" />
                      </div>
                      <div class="ml-4">
                        <div class="font-medium leading-5 text-gray-900">{{ user.email }}</div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-t border-gray-200">
                    <Pill color="purple" size="sm">Invite sent</Pill>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap border-t border-gray-200"
                  >
                    <button
                      class="p-1 text-gray-400 transition duration-150 ease-in-out rounded hover:text-gray-500 hover:bg-gray-100 focus:ring-blue-400 focus:ring-2 focus:outline-none"
                      @click="remove(user.email)"
                    >
                      <svg
                        class="w-4 h-4"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        ></path>
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
