<script>
export default {
  name: 'Toast',
  data() {
    return {
      styles: {
        success: {},
        error: {},
      },
    }
  },
  computed: {
    toast() {
      return this.$store.state.toast
    },
  },
}
</script>

<template>
  <div class="fixed bottom-0 left-0 right-0 z-40 flex items-center justify-center px-4 py-6 pointer-events-none sm:p-6">
    <transition
      enter-active-class="transition duration-300 ease-out transform"
      enter-class="translate-y-2 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-100 ease-in"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="toast && toast.type === 'primary'"
        class="w-full max-w-sm bg-blue-400 rounded-lg shadow-lg pointer-events-auto"
      >
        <div class="overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5">
          <div class="p-3">
            <div class="flex items-center">
              <div class="self-start flex-shrink-0">
                <svg class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="font-medium leading-5 text-white">
                  <span class="block w-full">{{ toast.msg }}</span>
                </p>
                <p v-if="toast.more" class="mt-1 text-sm leading-5 text-blue-200">
                  {{ toast.more }}
                </p>
              </div>
              <div class="flex flex-shrink-0 ml-4">
                <button
                  class="inline-flex p-1 text-blue-300 transition duration-150 ease-in-out rounded hover:bg-blue-500 focus:ring-blue-400 focus:ring-2 focus:ring-offset-2 hover:text-white focus:outline-none"
                  @click="$store.commit('TOAST_SET')"
                >
                  <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition
      enter-active-class="transition duration-300 ease-out transform"
      enter-class="translate-y-2 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-100 ease-in"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="toast && toast.type === 'success'"
        class="w-full max-w-sm bg-green-500 rounded-lg shadow-lg pointer-events-auto"
      >
        <div class="overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5">
          <div class="p-3">
            <div class="flex items-center">
              <div class="self-start flex-shrink-0">
                <svg class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="font-medium leading-5 text-white">
                  <span class="block w-full">{{ toast.msg }}</span>
                </p>
                <p v-if="toast.more" class="mt-1 text-sm leading-5 text-green-200">
                  {{ toast.more }}
                </p>
              </div>
              <div class="flex flex-shrink-0 ml-4">
                <button
                  class="inline-flex p-1 text-green-400 transition duration-150 ease-in-out rounded hover:bg-green-600 focus:ring-2 focus:ring-offset-2 focus:ring-green-500 hover:text-white focus:outline-none"
                  @click="$store.commit('TOAST_SET')"
                >
                  <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition
      enter-active-class="transition duration-300 ease-out transform"
      enter-class="translate-y-2 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-100 ease-in"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="toast && toast.type === 'error'"
        class="w-full max-w-sm bg-red-500 rounded-lg shadow-lg pointer-events-auto"
      >
        <div class="overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5">
          <div class="p-3">
            <div class="flex items-center">
              <div class="self-start flex-shrink-0">
                <svg class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="font-medium leading-5 text-white">
                  <span class="block w-full">{{ toast.msg }}</span>
                </p>
                <p v-if="toast.more" class="mt-1 text-sm leading-5 text-red-200">
                  {{ toast.more }}
                </p>
              </div>
              <div class="flex flex-shrink-0 ml-4">
                <button
                  class="inline-flex p-1 text-red-400 transition duration-150 ease-in-out rounded hover:bg-red-600 focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:text-white focus:outline-none"
                  @click="$store.commit('TOAST_SET')"
                >
                  <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition
      enter-active-class="transition duration-300 ease-out transform"
      enter-class="translate-y-2 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-100 ease-in"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="toast && toast.type === 'warning'"
        class="w-full max-w-sm bg-yellow-500 rounded-lg shadow-lg pointer-events-auto"
      >
        <div class="overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5">
          <div class="p-3">
            <div class="flex items-center">
              <div class="self-start flex-shrink-0">
                <svg class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="font-medium leading-5 text-white">
                  <span class="block w-full">{{ toast.msg }}</span>
                </p>
                <p v-if="toast.more" class="mt-1 text-sm leading-5 text-yellow-200">
                  {{ toast.more }}
                </p>
              </div>
              <div class="flex flex-shrink-0 ml-4">
                <button
                  class="inline-flex p-1 text-yellow-400 transition duration-150 ease-in-out rounded hover:bg-yellow-600 focus:ring-yellow-500 focus:ring-2 focus:ring-offset-2 hover:text-white focus:outline-none"
                  @click="$store.commit('TOAST_SET')"
                >
                  <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
