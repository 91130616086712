/** global window */
/**
 * Identify to LogRocket
 * @param {string} userId
 * @param {object} properties
 */
function identifyLogRocket(userId, properties = {}) {
  if (window.LogRocket) {
    try {
      window.LogRocket.identify(userId, properties)
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)
    }
  }
}

function identifyCrisp({ email, workspaceName, domain, name } = {}) {
  if (window.$crisp) {
    try {
      if (email) window.$crisp.push(['set', 'user:email', [email]])
      if (workspaceName) window.$crisp.push(['set', 'user:company', [workspaceName]])
      if (workspaceName && domain) window.$crisp.push(['set', 'user:company', [workspaceName, { url: domain }]])
      if (name) window.$crisp.push(['set', 'user:nickname', [name]])
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)
    }
  }
}

export default {
  identifyLogRocket,
  identifyCrisp,
}
