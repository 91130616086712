import campaigns from '@/views/campaigns'
import campaignEditor from '@/views/campaigns/campaignEditor'
import campaignPerformance from '@/views/campaigns/campaignPerformance'

export default [
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: campaigns,
  },
  {
    path: '/campaigns/view/:campaignId',
    name: 'Campaign Performance',
    component: campaignPerformance,
  },
  {
    path: '/campaigns/view/:campaignId/edit',
    name: 'Campaign Editor',
    component: campaignEditor,
  },
]
