<script>
import campaignTypes from '@/lib/campaignTypes'
import BaseButton from '@/components/Base/Button'

export default {
  name: 'NewCampaignModal',
  components: {
    BaseButton,
  },
  props: {
    showNewCampaignModal: Boolean,
  },
  data() {
    return {
      campaignTypes,
    }
  },
  methods: {
    selectType(type) {
      this.$emit('selectType', type)
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<template>
  <!-- New campaign modal -->
  <div class="fixed inset-0 z-50 overflow-y-auto pointer-events-none">
    <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
      <!-- Overlay -->
      <transition
        enter-active-class="duration-300 ease-out"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-if="showNewCampaignModal" class="fixed inset-0 transition-opacity pointer-events-auto" @click="close">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

      <transition
        enter-active-class="duration-300 ease-out"
        enter-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          v-if="showNewCampaignModal"
          class="relative inline-block w-full max-w-xl overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl pointer-events-auto"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="relative p-8">
            <div class="flex items-center">
              <h3 class="flex-grow text-xl font-bold leading-6 text-neutral-700">
                Select a campaign type
              </h3>
              <div>
                <button
                  type="button"
                  class="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:text-gray-500"
                  aria-label="Close"
                  @click="close"
                >
                  <svg
                    class="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>

            <div class="grid grid-cols-2 gap-3 mt-8 text-neutral-600">
              <BaseButton theme="secondary" size="xl" classes="py-9" @click="selectType(campaignTypes.BANNER_FLOATING)">
                <div class="flex items-center p-6 space-x-3 text-base">
                  <div class="flex-initial">
                    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.5" y="1.5" width="29" height="29" rx="3.5" stroke="currentColor" stroke-width="3" />
                      <rect x="5" y="5" width="22" height="9" rx="2" fill="currentColor" />
                    </svg>
                  </div>
                  <div class="flex-grow whitespace-nowrap">
                    Banner message
                  </div>
                </div>
              </BaseButton>
              <BaseButton
                theme="secondary"
                size="xl"
                classes="py-9"
                @click="selectType(campaignTypes.SOCIAL_PROOF_POPUP)"
              >
                <div class="flex items-center p-6 space-x-3 text-base">
                  <div class="flex-initial">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <div class="flex-grow whitespace-nowrap">
                    Social proof popup
                  </div>
                </div>
              </BaseButton>
              <BaseButton
                theme="secondary"
                size="xl"
                classes="py-9"
                @click="selectType(campaignTypes.INSERTED_CONTENT)"
              >
                <div class="flex items-center p-6 space-x-3 text-base">
                  <div class="flex-initial">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z"
                      ></path>
                    </svg>
                  </div>
                  <div class="flex-grow whitespace-nowrap">
                    Inserted content
                  </div>
                </div>
              </BaseButton>
              <BaseButton theme="secondary" size="xl" classes="py-9" @click="selectType(campaignTypes.CUSTOM_CODE)">
                <div class="flex items-center p-6 space-x-3 text-base">
                  <div class="flex-initial">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        d="M2 5a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm3.293 1.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L7.586 10 5.293 7.707a1 1 0 010-1.414zM11 12a1 1 0 100 2h3a1 1 0 100-2h-3z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <div class="flex-grow whitespace-nowrap">
                    Custom code
                  </div>
                </div>
              </BaseButton>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
