<script>
import BaseButton from './Base/Button'
export default {
  name: 'Login',
  components: {
    BaseButton,
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      userDataError: null,
      resetMode: false,
      signingInWithGoogle: false,
    }
  },
  mounted() {
    const email = window.localStorage.getItem('emailForSignIn')
    if (email) this.email = email
  },
  methods: {
    submit(email, password) {
      if (this.resetMode) return this.resetPassword(email)
      else return this.login(email, password)
    },
    resetPassword(email) {
      this.$toastError('Password reset coming soon')
    },
    async continueWithGoogle() {
      this.signingInWithGoogle = true
      try {
        await this.$store.dispatch('USER_LOGIN_WITH_GOOGLE')
        this.$router.push('/campaigns')
      } catch (err) {
        this.$toastError(err.message)
      }

      this.signingInWithGoogle = false
    },
    async login(email, password) {
      this.loading = true
      this.userDataError = null
      try {
        window.localStorage.setItem('emailForSignIn', email)
        if (this.$route.path === '/campaigns' || this.$route.path === '/login') this.$router.push('/campaigns')
        await this.$store.dispatch('USER_LOGIN', { email, password })
      } catch (err) {
        if (err && err.code === 'auth/user-not-found')
          this.userDataError = 'User not found, please try a different email'
        else if (err && err.code === 'auth/wrong-password') this.userDataError = 'Incorrect username & password'
        else if (err && err.message) this.userDataError = err.message
        else this.userDataError = 'There was a problem signing you in.  Please refresh the page and try again.'
      }
      this.loading = false
    },
  },
}
</script>

<template>
  <div class="flex min-h-screen bg-white">
    <div
      class="relative z-10 flex flex-col justify-center flex-1 px-4 py-12 bg-white ring-1 ring-black ring-opacity-5 sm:px-6"
    >
      <div class="max-w-sm mx-auto w-96">
        <div>
          <div class="flex items-center flex-shrink-0 select-none">
            <div>
              <svg class="w-auto h-9" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.8108 12.75H32C31.5016 15.1499 30.1747 17.3068 28.2441 18.8558C26.3135 20.4047 23.8976 21.2505 21.4054 21.25H0C0.498444 18.8501 1.82526 16.6932 3.75589 15.1442C5.68651 13.5953 8.10237 12.7495 10.5946 12.75H10.8108ZM32 8.5C31.5016 6.1001 30.1747 3.94316 28.2441 2.39422C26.3135 0.84529 23.8976 -0.000499695 21.4054 2.21485e-07H10.5946V8.5H32ZM10.5946 34C13.0868 34.0005 15.5027 33.1547 17.4333 31.6058C19.3639 30.0568 20.6907 27.8999 21.1892 25.5H10.5946V34Z"
                  fill="url(#brand_linear)"
                />
                <defs>
                  <linearGradient
                    id="brand_linear"
                    x1="16"
                    y1="2.21485e-07"
                    x2="16"
                    y2="34"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FF1493" />
                    <stop offset="0.39" stop-color="#FD576C" />
                    <stop offset="0.81" stop-color="#FC9847" />
                    <stop offset="1" stop-color="#FBB138" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <h2 class="mt-6 text-3xl font-extrabold leading-9 tracking-tight text-gray-900">
            {{ resetMode ? 'Reset password' : 'Sign in to your account' }}
          </h2>
          <!-- <p class="mt-2 text-sm leading-5 text-gray-600 max-w">
            {{ resetMode ? "We'll send you a reset link" : 'Secured by 256b encryption' }}
          </p> -->
        </div>

        <div v-if="!resetMode" class="mt-8">
          <BaseButton size="xl" classes="w-full" :loading="signingInWithGoogle" @click="continueWithGoogle">
            <span class="inline-flex items-center">
              <div>
                <svg
                  class="w-5 h-5"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="google"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 488 512"
                >
                  <path
                    fill="currentColor"
                    d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                    class=""
                  ></path>
                </svg>
              </div>
              <div class="ml-4">Continue with Google</div>
            </span>
          </BaseButton>

          <div class="relative mt-6">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center text-sm leading-5">
              <span class="px-2 text-gray-500 bg-white">
                Or sign in with your email
              </span>
            </div>
          </div>
        </div>

        <div class="mt-8">
          <div class="mt-6">
            <form @submit.prevent="submit(email, password)">
              <div>
                <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
                  Email address
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input id="email" v-model="email" type="email" required class="block w-full form-input" />
                </div>
              </div>

              <div v-if="!resetMode" class="mt-6">
                <label for="password" class="block text-sm font-medium leading-5 text-gray-700">
                  Password
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input id="password" v-model="password" type="password" required class="block w-full form-input" />
                </div>
              </div>

              <div class="flex items-center justify-between mt-6">
                <div class="text-sm leading-5">
                  <span
                    v-if="!resetMode"
                    class="font-medium text-blue-400 transition duration-150 ease-in-out cursor-pointer hover:text-blue-500 focus:outline-none focus:underline"
                    @click="resetMode = true"
                  >
                    Forgot your password?
                  </span>
                  <span
                    v-else
                    class="font-medium text-blue-400 transition duration-150 ease-in-out cursor-pointer hover:text-blue-500 focus:outline-none focus:underline"
                    @click="resetMode = false"
                  >
                    Back to sign in
                  </span>
                </div>
              </div>

              <div class="mt-6">
                <span class="block w-full rounded-md shadow-sm">
                  <BaseButton
                    :theme="resetMode ? 'primary' : 'secondary'"
                    :loading="loading"
                    type="submit"
                    classes="w-full"
                  >
                    {{ resetMode ? 'Send reset email' : 'Sign in' }}
                  </BaseButton>
                </span>
              </div>

              <p class="mt-6 text-sm text-center text-gray-400">
                Don't have an account yet?
                <router-link class="text-blue-400" to="/signup">Get started here</router-link>
              </p>
              <div class="relative">
                <p
                  v-if="userDataError"
                  class="absolute top-0 left-0 right-0 p-3 mt-4 text-sm font-medium tracking-tight text-center text-red-600 bg-red-200 rounded"
                >
                  {{ userDataError }}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="relative flex-1 hidden w-0 bg-neutral-800 lg:block"></div> -->
  </div>
</template>
