<script>
import BaseButton from '@/components/Base/Button'

export default {
  name: 'NewWorkspace',
  components: {
    BaseButton,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      name: '',
      domain: '',
      creatingWorkspace: false,
    }
  },
  methods: {
    async newWorkspace(name, domain) {
      if (!name || !domain) {
        this.$toastError('Please complete all fields')
        return
      }
      this.creatingWorkspace = true
      await this.$store
        .dispatch('WORKSPACE_CREATE', { name, domain })
        .then((ws) => {
          this.$toastSuccess(`New workspace created`, `${ws.name}`)
          this.$emit('close')
          if (this.$route.path !== '/setup') this.$router.push('/setup')
        })
        .catch(() => {
          this.$toastError('There was a problem creating the workspace')
        })
      this.creatingWorkspace = false
    },
  },
}
</script>

<template>
  <div
    class="fixed inset-x-0 bottom-0 z-20 px-4 pb-4 pointer-events-none sm:inset-0 sm:flex sm:items-center sm:justify-center"
  >
    <transition
      enter-active-class="duration-300 ease-out"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="show" class="fixed inset-0 transition-opacity pointer-events-auto" @click="$emit('close')">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
    </transition>
    <transition
      enter-active-class="duration-300 ease-out"
      enter-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      enter-to-class="translate-y-0 opacity-100 sm:scale-100"
      leave-active-class="duration-200 ease-in"
      leave-class="translate-y-0 opacity-100 sm:scale-100"
      leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
    >
      <div
        v-if="show"
        class="px-4 pt-5 pb-4 overflow-hidden transition-all transform bg-white rounded-lg shadow-xl pointer-events-auto sm:max-w-lg sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="mb-4 sm:flex sm:items-start">
          <div
            class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-pink-50 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg class="w-6 h-6 text-pink-500" viewBox="0 0 20 20" fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 id="modal-headline" class="mt-2 text-xl font-semibold leading-6 tracking-tight text-neutral-700">
              New workspace
            </h3>
            <div class="mt-2">
              <form
                class="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6"
                @submit.prevent="newWorkspace(name, domain)"
              >
                <div class="sm:col-span-3">
                  <label for="name" class="block text-sm font-medium leading-5 text-gray-700">
                    Name
                  </label>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      id="name"
                      v-model="name"
                      type="text"
                      autocomplete="off"
                      required
                      placeholder="Spacex"
                      :disabled="creatingWorkspace"
                      class="block w-full form-input"
                    />
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label for="domain" class="block text-sm font-medium leading-5 text-gray-700">
                    Domain
                  </label>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      id="domain"
                      v-model="domain"
                      type="text"
                      required
                      autocomplete="off"
                      placeholder="spacex.com"
                      :disabled="creatingWorkspace"
                      class="block w-full form-input"
                    />
                  </div>
                  <button type="hidden"></button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
          <span class="flex w-full rounded-md shadow-sm sm:w-auto">
            <BaseButton :loading="creatingWorkspace" @click="newWorkspace(name, domain)">
              Create
            </BaseButton>
          </span>
          <span class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto">
            <BaseButton theme="secondary" @click="$emit('close')">
              Cancel
            </BaseButton>
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>
