<script>
import { Chart } from 'frappe-charts/dist/frappe-charts.min.esm'

export default {
  name: '',
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'line',
    },
    title: {
      type: String,
      default: '',
    },
    labels: {
      type: Array,
      default: () => {
        return ['']
      },
    },
    datasets: {
      type: Array,
      default: () => {
        return [{ name: '', values: [] }]
      },
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    data() {
      return {
        labels: this.labels,
        datasets: this.datasets,
      }
    },
  },
  watch: {
    data(newVal) {
      if (this.chart) this.chart.update(newVal)
    },
  },
  mounted() {
    this.chart = new Chart(`#${this.id}`, {
      // or a DOM element,
      // new Chart() in case of ES6 module with above usage
      title: this.title,
      data: this.data,
      type: this.type, // or 'bar', 'line', 'scatter', 'pie', 'percentage'
      height: 250,
      colors: ['#743ee2', 'rgb(16, 185, 129)'],
      lineOptions: {
        regionFill: 1, // default: 0
      },
      axisOptions: {
        xAxisMode: 'tick', // default: 'span'
      },
    })
  },
}
</script>

<template>
  <div :id="id"></div>
</template>
