<script>
import Spinner from '@/components/Base/Spinner'

export default {
  name: 'Avatar',
  components: {
    Spinner,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '2.25',
    },
    loading: Boolean,
  },
  data() {
    return {
      saturation: 80,
      lightness: 90,
      img404: false,
    }
  },
  watch: {
    url() {
      this.img404 = false
    },
  },
  methods: {
    rem(size) {
      return size + 'rem'
    },
    stringToHslColor(str, s, l) {
      if (!str) str = this.randomLetters(5)
      var hash = 0
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }

      var h = hash % 360
      return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
    },
    initials(name) {
      try {
        if (name && name.split(' ').length >= 2) {
          return name
            .split(' ')
            .map((part) => {
              return part.slice(0, 1)
            })
            .slice(0, 2)
            .join('')
            .toUpperCase()
        } else if (name && name.length > 1) {
          return name.slice(0, 2).toUpperCase()
        } else {
          return name ? name.slice(0, 1).toUpperCase() : this.randomLetters()
        }
      } catch (e) {
        return this.randomLetters()
      }
    },
    randomLetters(length = 2) {
      var text = ''
      var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

      for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      }
      return text
    },
  },
}
</script>

<template>
  <div
    class="relative overflow-hidden font-bold text-center rounded-full"
    :style="{ height: rem(size), width: rem(size) }"
  >
    <div v-if="loading" class="absolute inset-0 z-10 flex items-center bg-white">
      <Spinner classes="h-7 w-7 mx-auto text-gray-200" />
    </div>
    <img v-if="url && !img404" :src="url" class="absolute inset-0 z-10 bg-white" @error="img404 = true" />
    <div
      v-if="!loading"
      class="absolute inset-0 z-0 inline-flex items-center justify-center w-12 h-12 rounded-full"
      :style="{
        height: rem(size),
        width: '100%',
        backgroundColor: stringToHslColor(name, saturation, lightness),
      }"
    >
      <span
        :style="{
          height: rem(size * 0.4),
          width: rem(size),
          fontSize: rem(size * 0.4),
          color: stringToHslColor(name, saturation, 30),
        }"
        class="font-medium leading-none"
        >{{ initials(name) }}</span
      >
    </div>
  </div>
</template>
