<script>
export default {
  name: 'Settings',
  components: {},
  props: {},
  data() {
    return {}
  },
  methods: {},
}
</script>

<template>
  <div class="pb-8">
    <!-- TOP NAV -->
    <div class="px-12 pt-8 bg-white border-b border-gray-200">
      <h1 class="mb-4 text-3xl font-bold tracking-tight text-neutral-700">Settings</h1>
      <div>
        <div class="">
          <nav class="flex -mb-px">
            <router-link
              to="/settings/workspace"
              active-class="text-blue-400 border-blue-400"
              class="px-1 py-4 font-medium leading-5 text-gray-500 whitespace-no-wrap border-b-2 border-transparent focus:outline-none hover:text-gray-700 hover:border-gray-300 focus:text-blue-500 focus:border-blue-400"
            >
              Workspace
            </router-link>
            <router-link
              to="/settings/personal"
              active-class="text-blue-400 border-blue-400"
              class="px-1 py-4 ml-8 font-medium leading-5 text-gray-500 whitespace-no-wrap border-b-2 border-transparent hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-blue-500 focus:border-blue-400"
            >
              Personal
            </router-link>

            <router-link
              to="/settings/team"
              active-class="text-blue-400 border-blue-400"
              class="px-1 py-4 ml-8 font-medium leading-5 text-gray-500 whitespace-no-wrap border-b-2 border-transparent hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-blue-500 focus:border-blue-400"
            >
              Team
            </router-link>

            <router-link
              to="/settings/billing"
              active-class="text-blue-400 border-blue-400"
              class="px-1 py-4 ml-8 font-medium leading-5 text-gray-500 whitespace-no-wrap border-b-2 border-transparent hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-blue-500 focus:border-blue-400"
            >
              Usage &amp; Billing
            </router-link>
          </nav>
        </div>
      </div>
    </div>

    <div class="max-w-3xl px-4 mx-auto my-8 xl:px-0">
      <router-view />
    </div>
  </div>
</template>
