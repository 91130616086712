// Source:
// https://codereview.stackexchange.com/questions/86242/calculate-ab-significance-with-control-and-treatment-objects

/**
 * Add the thousands seperator to large number
 * @param {number} num
 * @returns {string}
 */
const thousandsSeperator = (num) => {
  if (!num) return 0
  if (num > 999) {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'
  } else return num.toString()
}

/**
 * Calculate conversion rate
 * @param {object} t
 * @returns {number}
 */
const cr = (t) => {
  if (t.uniques === 0) {
    return 0
  }
  return t.conversions / t.uniques
}

/**
 * Calculation of score
 * @param {object} c
 * @param {object} t
 * @returns {any}
 */
const calcZScore = (c, t) => {
  var z = cr(t) - cr(c)
  var s = (cr(t) * (1 - cr(t))) / t.uniques + (cr(c) * (1 - cr(c))) / c.uniques
  return z / Math.sqrt(s)
}

/**
 * Calculate the Cumulative Normal Distribtion
 * @param {number} x
 * @returns {number}
 */
const cumNorDist = (x) => {
  const b1 = 0.31938153
  const b2 = -0.356563782
  const b3 = 1.781477937
  const b4 = -1.821255978
  const b5 = 1.330274429
  const p = 0.2316419
  const c = 0.39894228
  let t
  if (x >= 0.0) {
    t = 1.0 / (1.0 + p * x)
    return 1.0 - c * Math.exp((-x * x) / 2.0) * t * (t * (t * (t * (t * b5 + b4) + b3) + b2) + b1)
  } else {
    t = 1.0 / (1.0 - p * x)
    return c * Math.exp((-x * x) / 2.0) * t * (t * (t * (t * (t * b5 + b4) + b3) + b2) + b1)
  }
}

/**
 * Get recommended sample size for a test
 * https://gist.github.com/kdzwinel/201293d7e35981e87b40
 * @param {number} controlCR Your control group's expected conversion rate.
 * @param {number} lift The minimum relative change in conversion rate you would like to be able to detect.
 * @returns {number} sample size PER VARIATION
 */
const getSampleSize = (controlCR, lift) => {
  if (isNaN(controlCR) || isNaN(lift)) throw Error('Missing params')
  const significance = 0.95 // Statistical Significance

  const c = controlCR - controlCR * lift
  const p = Math.abs(controlCR * lift)
  const o = controlCR * (1 - controlCR) + c * (1 - c)
  // Check for 0 values later on when using Sample Size
  // if(p === 0){
  //   return -1
  // }
  const n = (2 * significance * o * Math.log(1 + Math.sqrt(o) / p)) / (p * p)

  return Math.round(n)
}

/**
 * Calculate the significance between Control and Variant
 * @param {object} control
 * @param {object} variant
 * @returns {object}
 */
function getSignificance(control, variant) {
  if (typeof control !== 'object' || typeof variant !== 'object') {
    throw Error('Input params must be objects')
  }
  const zScore = calcZScore(control, variant)
  const confidence = cumNorDist(zScore)
  const sampleSize = getSampleSize(cr(control), cr(variant) - cr(control))

  const result = {
    // controlCR: cr(control),
    // variantCR: cr(variant),
    lift: getLift(cr(control), cr(variant)),
    // zScore,
    confidence: isNaN(confidence) ? 0 : Number((confidence * 100).toPrecision(3)),
    // pValue: 1 - confidence,
    recommendedSampleSize: sampleSize,
    ready: control.uniques >= sampleSize && variant.uniques >= sampleSize,
    recommendation:
      control.uniques >= sampleSize && variant.uniques >= sampleSize
        ? 'Test complete'
        : `Wait for ~${
            sampleSize - variant.uniques
          } more visitors to each variant before declaring a winner (this can change if conversion rates fluctuate)`,
  }
  return result
}

// EXAMPLE:

// getSignificance(
//   { uniques: 15, conversions: 2 },
//   { uniques: 15, conversions: 3 }
// )

/**
 * Calculate the Lift for a goal
 * @param {string} controlCR
 * @param {string} variantCR
 * @returns {String}
 */
function getLift(controlCR, variantCR) {
  return thousandsSeperator(Number((((variantCR - controlCR) / controlCR) * 100).toPrecision(4)))
}

export default { getSignificance }
