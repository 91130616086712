<script>
import utils from '@/lib/utils'
import BaseButton from '@/components/Base/Button'
import WorkspaceInfo from './WorkspaceInfo'
import Survey from './Survey'

export default {
  name: 'WorkspaceSetup',
  components: {
    WorkspaceInfo,
    Survey,
    BaseButton,
  },
  data() {
    return {
      name: '',
      domain: '',
      creatingWorkspace: false,
    }
  },
  computed: {
    step() {
      if (!this.name && !this.domain) return 'WORKSPACE_INFO'
      else return 'SURVEY'
    },
  },
  created() {
    this.name = window.localStorage.getItem('workspaceNameForSignUp')
    this.domain = window.localStorage.getItem('workspaceDomainForSignUp')
  },
  methods: {
    cacheWorkspaceInfo({ name, domain }) {
      this.name = name
      this.domain = domain
      window.localStorage.setItem('workspaceNameForSignUp', name)
      window.localStorage.setItem('workspaceDomainForSignUp', domain)
    },
    async createWorkspace(product) {
      const url = utils.cleanPageURL(this.domain)
      if (!utils.isValidUrl(url)) return this.$toastError('Please enter a valid url.')

      this.creatingWorkspace = true
      try {
        await this.$store.dispatch('WORKSPACE_CREATE', {
          name: this.name,
          domain: url,
        })
        await this.$store.dispatch('USER_GET')

        // Ensure the new workspace was retrieved
        if (!this.$user().workspace.id) {
          await this.$store.dispatch('USER_GET')
          let count = 0
          this.interval = setInterval(() => {
            if (count < 2 && !this.$user().workspace.id) {
              this.$store.dispatch('USER_GET')
              count++
            } else {
              clearInterval(this.interval)
              this.creatingWorkspace = false
              if (this.$route.path !== '/setup') this.$router.replace('/setup')
            }
          }, 1000)
        } else {
          this.creatingWorkspace = false
          if (this.$route.path !== '/setup') this.$router.replace('/setup')
        }
      } catch (err) {
        this.$toastError('There was an error creating your workspace')
        this.creatingWorkspace = false
      }
    },
    cancel() {
      window.localStorage.removeItem('workspaceNameForSignUp')
      window.localStorage.removeItem('workspaceDomainForSignUp')
      this.$router.push('/signup')
    },
  },
}
</script>

<template>
  <div>
    <div class="absolute bottom-0 left-0 p-5">
      <BaseButton size="sm" theme="secondary" @click="cancel">
        <div class="flex items-center space-x-2">
          <div>
            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div>Cancel</div>
        </div>
      </BaseButton>
    </div>
    <div class="min-h-screen bg-white">
      <div class="max-w-screen-xl px-4 py-16 mx-auto sm:py-24 sm:px-6 lg:px-8">
        <div class="text-center">
          <nav class="flex items-center justify-center space-x-8">
            <p class="text-sm font-medium leading-5">Step {{ step === 'WORKSPACE_INFO' ? '2' : '3' }} of 3</p>
            <ul class="flex items-center space-x-5">
              <li>
                <span class="relative block w-6 h-4 bg-blue-400 border-4 border-gray-100 rounded-full"></span>
              </li>
              <li v-if="step === 'SURVEY'">
                <span class="relative block w-6 h-4 bg-blue-400 border-4 border-gray-100 rounded-full"></span>
              </li>

              <li>
                <span class="relative block w-6 h-3 bg-blue-400 rounded-full ring-2 ring-blue-400 ring-offset-2"></span>
              </li>

              <li v-if="step === 'WORKSPACE_INFO'">
                <span class="relative block w-6 h-4 bg-gray-300 border-4 border-gray-100 rounded-full"></span>
              </li>
            </ul>
          </nav>

          <p
            class="mt-8 text-3xl font-extrabold leading-10 text-neutral-700 sm:text-5xl sm:leading-none sm:tracking-tight"
          >
            {{ step === 'WORKSPACE_INFO' ? 'Tell us about your business' : 'Start your free trial' }}
          </p>
          <p class="max-w-xl mx-auto mt-3 text-xl leading-7 text-gray-500 md:mt-5">
            {{
              step === 'WORKSPACE_INFO'
                ? 'You will be up and running in no time'
                : '14 day free trial. No credit card required. Cancel anytime.'
            }}
          </p>
        </div>
      </div>

      <WorkspaceInfo v-if="step === 'WORKSPACE_INFO'" :name="name" :domain="domain" @submit="cacheWorkspaceInfo" />
      <Survey v-else-if="step === 'SURVEY'" :loading="creatingWorkspace" @submit="createWorkspace" />
    </div>
  </div>
</template>
