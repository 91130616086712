<script>
export default {
  name: 'ProgressCircle',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    radius: {
      type: Number,
      default: 100,
    },
    stroke: {
      type: Number,
      default: 20,
    },
    classes: {
      type: String,
      default: 'text-blue-500',
    },
  },
  data() {
    const normalizedRadius = this.radius - this.stroke * 2
    const circumference = normalizedRadius * 2 * Math.PI

    return {
      normalizedRadius,
      circumference,
    }
  },
  computed: {
    cleanProgress() {
      let progress = Number.isNaN(this.progress) || !this.progress || this.progress < 0 ? 0 : this.progress
      progress = progress >= 100 ? 100 : progress
      return progress
    },
    strokeDashoffset() {
      return this.circumference - (this.cleanProgress / 100) * this.circumference
    },
  },
}
</script>

<template>
  <div class="relative" :style="{ height: `${radius * 2}px`, width: `${radius * 2}px` }">
    <div class="rounded-full usageIndicator" :style="{ height: `${radius * 2}px`, width: `${radius * 2}px` }">
      <svg :height="radius * 2" :width="radius * 2" class="rounded-full" :class="classes">
        <circle
          stroke="#eaedff"
          :style="{ strokeDashoffset: strokeDashoffset }"
          :stroke-width="stroke"
          fill="transparent"
          :r="normalizedRadius"
          :cx="radius"
          :cy="radius"
        />
        <circle
          stroke="currentColor"
          :stroke-dasharray="circumference + ' ' + circumference"
          :style="{ strokeDashoffset: strokeDashoffset }"
          :stroke-width="stroke"
          fill="transparent"
          :r="normalizedRadius"
          :cx="radius"
          :cy="radius"
        />
      </svg>
    </div>
    <div class="absolute top-0 bottom-0 left-0 right-0 flex items-center">
      <div class="mx-auto text-3xl font-bold tracking-tighter text-neutral-800">
        {{ cleanProgress }}<span class="text-2xl font-thin text-neutral-600">%</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.usageIndicator {
  transition: stroke-dashoffset 500ms;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
