<script>
import BaseButton from '@/components/Base/Button'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'CampaignOptionsDropdown',
  components: {
    BaseButton,
  },
  directives: {
    ClickOutside,
  },
  props: {
    loading: Boolean,
    label: {
      type: String,
      default: 'More',
    },
    size: {
      type: String,
      default: 'md',
    },
    classes: {
      type: String,
      default: '',
    },
    direction: {
      type: String,
      default: 'right',
      validator: (val) => {
        return ['left', 'right'].indexOf(val) !== -1
      },
    },
  },
  data() {
    return {
      open: false,
    }
  },
}
</script>

<template>
  <div
    v-click-outside="
      () => {
        open = false
      }
    "
    class="relative inline-block text-left focus:outline-none"
  >
    <div>
      <BaseButton :classes="classes" :size="size" theme="flat" :loading="loading" @click="open = !open">
        <div class="flex items-center space-x-2">
          <div>
            <div v-if="!$slots.label">{{ label }}</div>
            <slot v-else name="label"></slot>
          </div>
          <svg
            class="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </BaseButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div
        v-if="open"
        :class="{ 'origin-top-left left-0': direction === 'right', 'origin-top-right right-0': direction === 'left' }"
        class="absolute z-10 mt-2 bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5"
        style="min-width: 12rem;"
      >
        <div class="p-2" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" @click="open = !open">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>
