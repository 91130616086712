<script>
export default {
  name: 'Panel',
  props: {
    active: Boolean,
    clickable: Boolean,
  },
}
</script>

<template>
  <div
    :class="{
      'ring-2 ring-gray-100': !active && !clickable,
      ' hover:bg-gray-50 cursor-pointer': !active && clickable,
      'ring-gray-200 shadow ring-1': active,
    }"
    class="px-6 py-5 mb-4 -mx-6 transition-all duration-200 rounded-lg group"
    @click="!active && $emit('click')"
  >
    <slot
  /></div>
</template>
