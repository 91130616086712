import data from '@/views/data'
import metrics from '@/views/data/metrics'

export default [
  {
    path: '/data',
    name: 'Data',
    component: data,
    redirect: '/data/metrics',
    children: [
      {
        path: 'metrics',
        name: 'Metrics',
        component: metrics,
      },
    ],
  },
]
