<script>
import BaseButton from '@/components/Base/Button'
import BaseTagInput from '@/components/Base/TagInput'
import Dropdown from '@/components/Base/Dropdown'
import Panel from './panel'

export default {
  name: 'GoalsStep',
  components: {
    BaseButton,
    BaseTagInput,
    Dropdown,
    Panel,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    active: Boolean,
  },
  data() {
    return {
      goals: [],
      dirty: false,
      saving: false,
      goalTypeOptions: {
        PAGEVIEW: {
          value: 'Proof pageview',
          label: 'visited url',
        },
        EVENT: {
          value: 'EVENT',
          label: 'completed event',
        },
      },
      matchTypeOptions: {
        SIMPLE: {
          value: 'SIMPLE',
          label: 'matches',
        },
        CONTAINS: {
          value: 'CONTAINS',
          label: 'contains',
        },
      },
    }
  },
  created() {
    this.goals = this.campaign.campaignGoals
  },
  methods: {
    newGoal() {
      this.dirty = true
      this.goals.push({
        event: 'Proof pageview',
        urlMatchType: 'SIMPLE',
        urlMatchRule: null,
      })
    },
    setGoalEvent(goalIndex, event) {
      this.$emit('dirtyStatusChange')
      this.goals[goalIndex].event = event
      if (event === 'Proof pageview' && !this.goals[goalIndex].urlMatchType)
        this.goals[goalIndex].urlMatchType = 'SIMPLE'
      this.dirty = true
    },
    setUrlMatchType(goalIndex, val) {
      this.$emit('dirtyStatusChange')
      this.goals[goalIndex].urlMatchType = val
      this.dirty = true
    },
    setUrlMatchRule(goalIndex, val) {
      this.$emit('dirtyStatusChange')
      this.goals[goalIndex].urlMatchRule = val
      this.dirty = true
    },
    async saveChanges() {
      this.saving = true
      try {
        await this.$store.dispatch('CAMPAIGNS_SET_GOALS', {
          campaignId: this.campaign.id,
          goals: this.goals,
        })
        this.$emit('refresh')
        this.$toastSuccess(
          'Campaign saved',
          this.campaign.active ? 'Changes can take up to 60 seconds to deploy to our super fast servers globally' : ''
        )
        this.dirty = false
      } catch (err) {
        this.$toastError('There was a problem saving your campaign rules')
      }
      this.saving = false
    },
    async deleteGoal(goal, index) {
      try {
        if (goal.id)
          this.goals = await this.$store.dispatch('CAMPAIGNS_DELETE_GOAL', {
            campaignId: this.campaign.id,
            goalId: goal.id,
          })
        else this.goals = this.goals.filter((g, i) => i !== index)

        this.$emit('refresh')
      } catch (err) {
        this.$toastError('There was a problem removing this goal')
      }
    },
    cancel() {
      this.goals = this.campaign.campaignGoals
      this.dirty = false
      this.$emit('refresh')
    },
    getPlainEnglish(goal) {
      const action = goal.event === 'Proof pageview' ? 'visited url' : 'performed event'
      return `User has ${action}`
    },
    tagInputPlaceholder(goalIndex) {
      if (this.goals[goalIndex].urlMatchType === 'SIMPLE')
        return this.goals[goalIndex].urlMatchRule ? 'or...' : 'https://tesla.com'
      else if (this.goals[goalIndex].urlMatchType === 'CONTAINS')
        return this.goals[goalIndex].urlMatchRule ? 'or...' : 'tesla.com/blog'
      else return 'tesla.com/blog'
    },
  },
}
</script>

<template>
  <Panel :active="active" :clickable="true" @click="() => !active && $emit('openStep')">
    <div class="flex items-center">
      <div
        :class="{ 'text-neutral-700': active, 'text-gray-500': !active }"
        class="inline-flex items-center flex-grow space-x-6 text-2xl font-semibold tracking-tighter transition-all duration-200 group-hover:text-neutral-700"
      >
        <div>Goals</div>
        <div v-if="!active">
          <div v-if="!goals.length" class="px-2 py-2 text-sm font-medium tracking-tight text-gray-500"
            >no goals set</div
          >
          <div
            v-else-if="goals.length === 1"
            class="px-2 py-2 text-sm font-medium tracking-tight text-gray-500 bg-gray-100 rounded-lg"
          >
            {{ getPlainEnglish(goals[0]) }}
          </div>
          <div v-else class="px-2 py-2 text-sm font-medium tracking-tight text-gray-500 bg-gray-100 rounded-lg">
            {{ goals.length }} goals
          </div>
        </div>
      </div>
      <div v-if="dirty" class="flex-initial">
        <BaseButton theme="flat" classes="mr-2" @click="cancel">Cancel</BaseButton>
        <BaseButton :disabled="!dirty" :loading="saving" @click="saveChanges">Save</BaseButton>
      </div>
    </div>

    <div v-if="active">
      <div class="mt-5 text-gray-500">
        Understand how your campaign drives people to take action on your website.
      </div>
      <div v-for="(g, i) in goals" :key="i" class="flex items-start py-2 mt-5 mb-3 space-x-2">
        <div class="flex items-center flex-initial space-x-2">
          <div
            class="w-10 h-10 p-2 mr-3 text-sm font-bold text-center text-gray-300 border-2 border-gray-200 rounded-lg"
            >{{ i + 1 }}</div
          >
          <div class="text-gray-600">User has</div>
          <div>
            <Dropdown size="sm" :label="g.event === 'Proof pageview' ? 'visited url' : 'completed event'">
              <button
                v-for="o in goalTypeOptions"
                :key="o.value"
                class="flex items-center w-full px-3 py-2 space-x-2 text-sm font-medium text-gray-600 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                @click="setGoalEvent(i, o.value)"
              >
                <div>{{ o.label }}</div>
              </button>
            </Dropdown>
          </div>
          <div v-if="g.event === 'Proof pageview'" class="text-gray-600">that</div>
          <div v-if="g.event === 'Proof pageview'">
            <Dropdown
              size="sm"
              :label="matchTypeOptions[g.urlMatchType] ? matchTypeOptions[g.urlMatchType].label : 'Filter by URL'"
            >
              <button
                v-for="o in matchTypeOptions"
                :key="o.value"
                class="flex items-center w-full px-3 py-2 space-x-2 text-sm font-medium text-gray-600 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                @click="setUrlMatchType(i, o.value)"
              >
                <div>{{ o.label }}</div>
              </button>
            </Dropdown>
          </div>
        </div>
        <div v-if="g.event === 'Proof pageview'" class="flex-grow">
          <BaseTagInput
            :value="g.urlMatchRule"
            :placeholder="tagInputPlaceholder(i)"
            @input="(val) => setUrlMatchRule(i, val)"
          />
        </div>
        <div>
          <button
            class="p-2 text-gray-400 transition-all duration-200 rounded-lg outline-none cursor-pointer hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-400 focus:outline-none"
            @click="deleteGoal(g, i)"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
      </div>

      <div>
        <BaseButton theme="flat" classes="mt-4" @click="newGoal">
          <div class="flex items-center space-x-2">
            <div
              ><svg
                class="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                ></path></svg
            ></div>
            <div>Add goal</div>
          </div>
        </BaseButton>
      </div>
    </div>
  </Panel>
</template>
