import api from './utils/client'
import _get from 'lodash/get'

const getList = (
  workspaceId,
  offset = 0,
  limit = 10,
  filterByStatus,
  query,
  orderBy = 'updated_at',
  orderByDirection = 'desc'
) => {
  return api
    .query(
      `CampaignsList($workspaceId: uuid!, $offset: Int!, $limit: Int!) {
        campaigns(limit: $limit, offset: $offset, where: {workspaceId: {_eq: $workspaceId }${
          query ? `, name: {_ilike: "%${query}%"}` : ''
        }${
        filterByStatus ? `, active: {_eq: ${filterByStatus === 'LIVE' ? 'true' : 'false'}}` : ''
      }}, order_by: {${orderBy}: ${orderByDirection}}) {
          id
          name
          active
          updated_at
          campaignVariants {
            type
          }
        }
        queriedCount: campaigns_aggregate(where: {workspaceId: {_eq: $workspaceId}${
          query ? `, name: {_ilike: "%${query}%"}` : ''
        }}) {
          aggregate {
            count
          }
        }
        queriedActiveCount: campaigns_aggregate(where: {workspaceId: {_eq: $workspaceId}, active: {_eq: true}${
          query ? `, name: {_ilike: "%${query}%"}` : ''
        }}) {
          aggregate {
            count
          }
        }
        totalCount: campaigns_aggregate(where: {workspaceId: {_eq: $workspaceId}}) {
          aggregate {
            count
          }
        }
      }`,
      {
        workspaceId,
        offset,
        limit,
      }
    )
    .then((res) => {
      return {
        list: _get(res, 'campaigns', []).map((c) => {
          return {
            ...c,
            type: _get(c, 'campaignVariants[0].type'),
          }
        }),
        queriedCount: _get(res, 'queriedCount.aggregate.count', 0),
        queriedActiveCount: _get(res, 'queriedActiveCount.aggregate.count', 0),
        queriedInactiveCount:
          _get(res, 'queriedCount.aggregate.count', 0) - _get(res, 'queriedActiveCount.aggregate.count', 0),
        totalCount: _get(res, 'totalCount.aggregate.count', 0),
      }
    })
}

export default {
  getList,
  get: (id) => {
    return api
      .query(
        `getCampaign($id: uuid!) {
          campaigns_by_pk(id: $id) {
            id
            name
            active
            updated_at
            urlMatchType
            urlMatchRule
            campaignVariants(order_by: {created_at: asc}) {
              id
              type
              js
              css
              content
              created_at
            }
            campaignGoals(order_by: {created_at: asc}) {
              id
              event
              urlMatchType
              urlMatchRule
            }
          }
        }`,
        {
          id,
        }
      )
      .then((res) => _get(res, 'campaigns_by_pk'))
  },
  create: ({ workspaceId, type }) => {
    return api
      .mutate(
        `newCampaign($workspaceId: uuid!, $type: String!) {
          insert_campaigns(objects:{workspaceId: $workspaceId, campaignVariants: {data: {type: $type, workspaceId: $workspaceId}}}) {
            affected_rows
            returning {
              id
              name
            }
          }
        }`,
        { workspaceId, type }
      )
      .then((res) => _get(res, 'insert_campaigns.returning[0]', {}))
  },
  createVariant: (workspaceId, campaignId, type) => {
    return api
      .mutate(
        `newCampaign($workspaceId: uuid!, $campaignId: uuid!, $type: String!) {
          insert_campaignVariants(objects:{workspaceId: $workspaceId, campaignId: $campaignId, type: $type}) {
            affected_rows
            returning {
              id
              name
            }
          }
        }`,
        { workspaceId, campaignId, type }
      )
      .then((res) => _get(res, 'insert_campaigns.returning[0]', {}))
  },
  updateVariant: (id, js, content) => {
    return api.mutate(
      `updateVariant($id: uuid!, $js: String, $content: jsonb) {
          update_campaignVariants(where: {id: {_eq: $id}}, _set: {js: $js, content: $content}) {
            affected_rows
          }
        }`,
      { id, js, content }
    )
  },
  createGoals: (workspaceId, campaignId, goals) => {
    const campaignGoals = goals
      .map((g) => {
        return `{
        workspaceId: "${workspaceId}",
        campaignId: "${campaignId}",
        ${g.id ? `id: "${g.id}",` : ''}
        event: "${g.event}",
        urlMatchType: "${g.urlMatchType}",
        urlMatchRule: "${g.urlMatchRule}",
      }`
      })
      .join(',')
    return api.mutate(
      `newCampaignGoal {
        insert_campaignGoals(objects:[${campaignGoals}],
        on_conflict: {
          update_columns: [event, urlMatchType, urlMatchRule],
          constraint: campaignGoals_pkey
        }) {
          affected_rows
        }
      }`
    )
  },
  deleteGoal: (campaignId, id) => {
    return api
      .mutate(
        `deleteGoal($id: uuid!) {
        delete_campaignGoals(where: {id: {_eq: $id}}) {
          affected_rows
        }
      }`,
        {
          id,
        }
      )
      .then(() =>
        api
          .query(
            `getCampaignGoals($campaignId: uuid!) {
            campaignGoals(where: {campaignId: {_eq: $campaignId}}) {
              id
              event
              urlMatchType
              urlMatchRule
            }
          }`,
            {
              campaignId,
            }
          )
          .then((res) => res.campaignGoals)
      )
  },
  updateStatus: (id, active) =>
    api.mutate(
      `updateCampaign($id: uuid!, $active: Boolean) {
        update_campaigns(where: {id: {_eq: $id}}, _set: {active: $active}) {
          affected_rows
        }
      }`,
      { id, active }
    ),
  updateUrlMatchRule: (id, urlMatchType, urlMatchRule) =>
    api.mutate(
      `updateUrlMatchRule($id: uuid!, $urlMatchType: String!, $urlMatchRule: String) {
        update_campaigns(where: {id: {_eq: $id}}, _set: {urlMatchType: $urlMatchType, urlMatchRule: $urlMatchRule}) {
          affected_rows
        }
      }`,
      { id, urlMatchType, urlMatchRule }
    ),
  updateName: (id, name) => {
    return api.mutate(
      `updateCampaignName($id: uuid!, $name: String) {
        update_campaigns(where: {id: {_eq: $id}}, _set: {name: $name}) {
          affected_rows
        }
      }`,
      { id, name }
    )
  },
  delete: (id) =>
    api.mutate(
      `deleteCampaign($id: uuid!) {
        delete_campaigns(where: {id: {_eq: $id}}) {
          affected_rows
        }
      }`,
      {
        id,
      }
    ),
  refreshQuery: (workspaceId, params, id, days) => {
    return api
      .mutate(
        `refreshQuery($workspaceId: uuid!, $params: jsonb!, $id: String!, $requested_at: timestamptz!, $days: numeric) {
          insert_cachedQueries(
            objects: {
              workspaceId: $workspaceId,
              params: $params,
              id: $id,
              requested_at: $requested_at,
              days: $days
            },
            on_conflict: {
              update_columns: [requested_at, days, params],
              constraint: cachedQueries_pkey
            }) {
            affected_rows
            returning {
              result
              updated_at
              requested_at
              days
            }
          }
        }`,
        {
          workspaceId,
          params,
          id,
          requested_at: new Date().toISOString(),
          days,
        }
      )
      .then((res) => _get(res, 'insert_cachedQueries.returning[0]'))
  },
  getQuery: (workspaceId, id) => {
    return api.query(
      `getQuery($workspaceId: uuid!, $id: String!) {
        cachedQueries_by_pk(workspaceId: $workspaceId, id: $id) {
          result
          updated_at
          requested_at
        }
      }`,
      {
        workspaceId,
        id,
      }
    )
  },
  watchQuery: (workspaceId, id, callback) => {
    return api.subscribe(
      `watchQuery($workspaceId: uuid!, $id: String!) {
        cachedQueries_by_pk(workspaceId: $workspaceId, id: $id) {
          result
          updated_at
          requested_at
        }
      }`,
      {
        workspaceId,
        id,
      },
      (res) => {
        callback(_get(res, 'cachedQueries_by_pk'))
      }
    )
  },
}
