<script>
import BaseButton from '@/components/Base/Button'

export default {
  name: 'Survey',
  components: {
    BaseButton,
  },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      product: null,
    }
  },
}
</script>

<template>
  <div>
    <div class="max-w-2xl px-5 mx-auto space-y-6 md:grid md:grid-cols-2 md:gap-4 md:space-y-0">
      <button
        :class="{ 'bg-gray-50 border-gray-300 shadow-outline-blue': product === 'pulse' }"
        class="w-full px-6 py-10 transition-all duration-200 border-2 border-gray-200 rounded-lg outline-none focus:outline-none hover:shadow-xl focus:ring-blue-400 focus:ring-offset-2 focus:ring-2"
        @click="product = 'pulse'"
      >
        <div class="text-2xl font-semibold text-pink-500">
          Pulse
        </div>
        <p class="mt-2 text-lg text-gray-600">
          Increase conversions by displaying real people taking action on your site.
        </p>
        <p class="mt-2 text-sm text-gray-400">Free plan available </p>
      </button>
      <button
        :class="{ 'bg-gray-100 shadow-outline-blue': product === 'experiences' }"
        class="w-full px-6 py-10 transition-all duration-200 border-2 border-gray-200 rounded-lg outline-none focus:outline-none hover:shadow-xl focus:ring-blue-400 focus:ring-offset-2 focus:ring-2"
        @click="product = 'experiences'"
      >
        <div class="text-2xl font-semibold text-blue-500">
          Experiences
        </div>
        <p class="mt-2 text-lg text-gray-600">
          Tailor your website to each unique visitor and increase conversions.
        </p>
        <p class="mt-2 text-sm text-gray-400">Starting at $499/mo </p>
      </button>
    </div>
    <div class="max-w-2xl px-5 mx-auto mt-6 text-center md:mt-12">
      <BaseButton
        size="xl"
        classes="w-full md:w-auto"
        :disabled="!product"
        :loading="loading"
        @click="$emit('submit', product)"
      >
        <span class="inline-flex items-center">
          <div class="mr-3">Get started</div>
          <div>
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </span>
      </BaseButton>
    </div>
  </div>
</template>
