<script>
import BaseButton from '@/components/Base/Button'
const copy = require('clipboard-copy')

export default {
  name: 'Snippet',
  components: {
    BaseButton,
  },
  data() {
    return {
      alt: true,
    }
  },
  computed: {
    snippet() {
      // eslint-disable-next-line no-useless-escape
      // return `<script src="${process.env.VUE_APP_CDN_BASE_URL}/${this.$user().workspace.pixelId}.js"><\/script>`
      return [
        `<script>!function(e,n){var o=e.proof=e.proof||{};o.invoked?e.console&&console.error&&console.error("Proof snippet included twice"):(o.invoked=!0,o.load=function(e){var t=n.createElement("script");t.type="text/javascript",t.async=!0;var a=n.getElementsByTagName("script")[0];t.src="${process.env.VUE_APP_CDN_BASE_URL}/"+e+".js/"+window.location.href,a.parentNode.insertBefore(t,a)},`,
        `o.load("${this.$user().workspace.pixelId}")`,
        // eslint-disable-next-line no-useless-escape
        ')}(window,document);<\/script>',
      ].join('')
    },
  },
  methods: {
    copySnippet(snippet) {
      copy(snippet)
      this.$toastSuccess('Snippet copied to clipboard')
    },
  },
}
</script>

<template>
  <div>
    <input type="text" :value="snippet" readonly class="mb-4 font-medium text-gray-600 form-input bg-gray-50 text-md" />
    <BaseButton theme="forward" @click="copySnippet(snippet)">
      <span class="inline-flex items-center">
        <div>
          <svg
            class="w-5 h-5"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
            ></path>
          </svg>
        </div>
        <div class="ml-2">Copy snippet</div>
      </span>
    </BaseButton>
  </div>
</template>
