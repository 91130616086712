<script>
import BaseButton from '@/components/Base/Button'

export default {
  name: 'NewVersionAlert',
  components: {
    BaseButton,
  },
  props: {
    visible: Boolean,
  },
}
</script>

<template>
  <div class="fixed inset-0 z-10 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6">
    <transition
      enter-active-class="transition duration-300 ease-out transform"
      enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-y-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-y-0"
      leave-active-class="transition duration-100 ease-in"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="visible" class="w-full max-w-sm rounded-lg shadow-lg pointer-events-auto bg-neutral-900">
        <div class="overflow-hidden">
          <div class="px-4 py-3">
            <div class="flex items-center">
              <div class="flex flex-grow">
                <p class="font-medium leading-5 text-white">
                  New app version available
                </p>
              </div>
              <div class="flex flex-initial ml-4">
                <BaseButton theme="dark" @click="$emit('click')">
                  <div class="flex items-center space-x-2">
                    <svg
                      class="w-5 h-5"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                      ></path>
                    </svg>
                    <div>Refresh </div>
                  </div>
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
