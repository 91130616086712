import axios from 'axios'
import store from '@/store'

axios.interceptors.response.use(
  (response) => {
    return response.data
  },
  function (error) {
    return Promise.reject(error && error.response && error.response.data)
  }
)

export default {
  get: async (path, params = {}) => {
    const idToken = store.state.user.idToken

    return axios({
      method: 'get',
      url: path,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      params,
    })
  },
  post: async (path, data) => {
    const idToken = store.state.user.idToken
    return axios({
      method: 'post',
      url: path,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      data,
    })
  },
  put: async (path, data) => {
    const idToken = store.state.user.idToken
    return axios({
      method: 'put',
      url: path,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      data,
    })
  },
  patch: async (path, data) => {
    const idToken = store.state.user.idToken
    return axios({
      method: 'patch',
      url: path,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      data,
    })
  },
  delete: async (path) => {
    const idToken = store.state.user.idToken
    return axios({
      method: 'delete',
      url: path,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
  },
}
