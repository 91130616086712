<script>
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

export default {
  name: 'CodeEditor',
  components: {
    PrismEditor,
  },
  props: {
    code: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inFocus: false,
    }
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js) // languages.<insert language> to return html with markup
    },
    handleFocus() {
      this.inFocus = true
    },
    handleBlur() {
      this.inFocus = false
    },
  },
}
</script>

<template>
  <prism-editor
    :value="code"
    :class="{ 'ring-1 ring-blue-400 border-blue-400': inFocus, 'border-gray-200': !inFocus }"
    class="p-3 mb-5 text-base font-medium leading-6 text-gray-600 transition-all duration-200 bg-gray-100 border rounded-lg my-editor"
    :highlight="highlighter"
    line-numbers
    @input="(val) => $emit('input', val)"
    @focus="handleFocus"
    @blur="handleBlur"
  ></prism-editor>
</template>

<style>
.my-editor {
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
}

.prism-editor__textarea:focus {
  @apply outline-none;
}
</style>
