<script>
import Pill from '@/components/Base/Pill'
import BaseButton from '@/components/Base/Button'
import BaseDropdown from '@/components/Base/Dropdown'

export default {
  name: 'CampaignHeader',
  components: {
    Pill,
    BaseButton,
    BaseDropdown,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    isEditMode() {
      return this.$route.path.indexOf('/edit') !== -1
    },
  },
  methods: {
    editMode(c) {
      this.$router.push(`/campaigns/view/${c.id}/edit`)
    },
    async updateName(name) {
      try {
        await this.$store.dispatch('CAMPAIGNS_SET_NAME', { id: this.campaign.id, name })
        this.$emit('refresh')
      } catch (err) {
        this.$toastError('There was a problem saving your changes')
      }
    },
    deleteCampaign(c) {
      return this.$store
        .dispatch('CAMPAIGNS_DELETE', c.id)
        .then(() => {
          this.$toastWarning('Campaign has been deleted')
          this.$router.push('/campaigns')
        })
        .catch(() => {
          this.$toastError('Campaign could not be deleted')
        })
    },
    toggleStatus(c) {
      return this.$store
        .dispatch('CAMPAIGNS_SET_STATUS', { id: c.id, active: !c.active })
        .then(() => {
          if (!c.active)
            this.$toastSuccess(
              'Campaign set live',
              'Changes can take up to 60 seconds to deploy to our super fast servers globally',
              5000
            )
          else this.$toast('Campaign paused')
          this.$emit('refresh')
        })
        .catch(() => {
          this.$toastError('There was a problem setting this campaign live')
        })
    },
  },
}
</script>

<template>
  <div class="px-12 py-8">
    <div class="flex items-center">
      <h1 class="relative flex-grow mt-1 mb-2">
        <input
          id="title"
          type="text"
          :value="campaign.name"
          class="flex-1 block w-full py-2 m-0 -ml-3 text-3xl font-extrabold tracking-tight transition-all duration-200 ease-in-out bg-transparent border-none rounded-md outline-none hover:bg-gray-100 hover:ring-gray-200 focus:ring-2 focus:ring-blue-400 text-neutral-700 focus:bg-white focus:outline-none"
          placeholder="Untitled"
          autocomplete="off"
          @blur="(e) => updateName(e.target.value)"
        />
      </h1>
      <div class="flex items-center space-x-2">
        <BaseDropdown v-if="!isEditMode" direction="left">
          <button
            class="flex items-center w-full px-3 py-2 space-x-2 text-sm font-medium text-gray-600 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            @click="$toast('Duplication coming soon')"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
              ></path>
            </svg>
            <div>Duplicate</div>
          </button>
          <button
            class="flex items-center w-full px-3 py-2 space-x-2 text-sm font-medium text-gray-600 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            @click="deleteCampaign(campaign)"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              ></path>
            </svg>

            <div>Delete</div>
          </button>
        </BaseDropdown>
        <BaseButton
          v-if="!isEditMode"
          :theme="campaign.active ? 'secondary' : 'forward'"
          @click="toggleStatus(campaign)"
        >
          <div class="flex items-center space-x-2">
            <svg
              v-if="!campaign.active"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <svg v-else class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <div>{{ campaign.active ? 'Pause' : 'Set live' }}</div>
          </div>
        </BaseButton>

        <BaseButton v-if="$route.path.indexOf('/edit') === -1" @click="editMode(campaign)">
          <div class="flex items-center space-x-2">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
              ></path>
            </svg>
            <div>Edit</div>
          </div>
        </BaseButton>

        <BaseButton v-if="$route.path.indexOf('/edit') !== -1" theme="secondary" @click="$emit('done')">
          <div class="flex items-center space-x-2">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <div>Done</div>
          </div>
        </BaseButton>
      </div>
    </div>

    <div class="flex flex-col mt-3 sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-4">
      <div>
        <Pill v-if="campaign.active" size="sm" color="green">Live</Pill>
        <Pill v-else-if="!campaign.active && !campaign.firstSetLive" size="sm" color="gray">Draft</Pill>
        <Pill v-else size="sm" color="magenta">Paused</Pill>
      </div>
      <div class="flex items-center text-sm text-gray-500">
        <svg
          class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
            clip-rule="evenodd"
          ></path>
        </svg>
        Updated {{ campaign.updated_at | fromNow }}
      </div>
      <div v-if="campaign.firstSetLive" class="flex items-center text-sm text-gray-500">
        <svg
          class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
            clip-rule="evenodd"
          ></path>
        </svg>
        First set live {{ campaign.firstSetLive | fromNow }}
      </div>
    </div>
  </div>
</template>
