import api from './utils/client'
import _get from 'lodash/get'

const getUsers = (workspaceId) => {
  return api.query(
    `getUsers($workspaceId: uuid!) {
        users(where: {workspaceUsers: {workspaceId: {_eq: $workspaceId}}}) {
          id
          firstName
          lastName
          email
        }
        invites: workspaceInvites(where: {workspaceId: {_eq: $workspaceId}}) {
          email
          created_at
        }
      }`,
    {
      workspaceId,
    }
  )
}

export default {
  getList: (query) => {
    const queryCondition = query ? `(where: { name: { _ilike: "%${query}%"} })` : ''
    return api
      .query(
        `getWorkspaces {
          workspaces${queryCondition} {
            id
            name
            domain
            pixelId
            campaignCount: campaigns_aggregate {
              aggregate {
                count
              }
            }
          }
        }`
      )
      .then((res) => {
        return res.workspaces.map((ws) => {
          return {
            ...ws,
            campaignCount: _get(ws, 'campaignCount.aggregate.count', 0),
          }
        })
      })
  },
  getUsers,
  inviteUser: (workspaceId, email) =>
    api
      .mutate(
        `inviteUser($email: String!, $workspaceId: uuid!) {
          insert_workspaceInvites(objects: {email: $email, workspaceId: $workspaceId}, on_conflict: {constraint: workspaceInvites_email_workspaceId_key, update_columns: email}) {
            returning {
              email
              workspaceId
            }
          }
        }`,
        {
          email,
          workspaceId,
        }
      )
      .then(() => getUsers(workspaceId)),
  removeUser: (workspaceId, email) =>
    api
      .mutate(
        `removeUser($workspaceId: uuid!, $email: String!) {
          delete_workspaceUsers(where: {user: {email: {_eq: $email}}, workspaceId: {_eq: $workspaceId}}) {
            affected_rows
          }
          delete_workspaceInvites(where: {workspaceId: {_eq: $workspaceId}, email: {_eq: $email}}) {
            affected_rows
          }
        }`,
        {
          workspaceId,
          email,
        }
      )
      .then(() => getUsers(workspaceId)),
  updateWorkspace: (workspaceId, { name, domain }) =>
    api
      .mutate(
        `updateWorkspace($workspaceId: uuid!, $name: String!, $domain: String!) {
          update_workspaces(where: {id: {_eq: $workspaceId}}, _set: {domain: $domain, name: $name}) {
            returning {
              id
              name
              domain
              pixelId
            }
          }
        }`,
        {
          workspaceId,
          name,
          domain,
        }
      )
      .then((res) => _get(res, 'update_workspaces.returning[0]')),
}
