<script>
import BaseButton from '@/components/Base/Button'

export default {
  name: 'WorkspaceSettings',
  components: {
    BaseButton,
  },
  props: {},
  data() {
    return {
      newEmail: '',
      name: this.$user().workspace.name,
      domain: this.$user().workspace.domain,
      saving: false,
    }
  },
  computed: {
    debugMode() {
      return this.$store.state.user.workspace.debug
    },
  },
  created() {
    this.$store.dispatch('USER_GET')
  },
  methods: {
    async saveWorkspace(name, domain) {
      this.saving = true
      try {
        await this.$store.dispatch('WORKSPACE_UPDATE', { name, domain })
        this.$toastSuccess('Workspace changes saved')
      } catch (e) {
        this.$toastError('There was an problem saving your changes')
      }
      this.saving = false
    },
  },
}
</script>

<template>
  <div>
    <div class="mt-5 md:mt-0">
      <form @submit.prevent="saveWorkspace(name, domain)">
        <div class="bg-white ring-1 ring-black ring-opacity-5 sm:rounded-lg sm:overflow-hidden">
          <div class="px-4 py-5 sm:p-6">
            <h3 class="text-xl font-medium leading-6 text-neutral-700">Company Information</h3>
            <p class="mt-2 mb-6 leading-5 text-gray-500">
              Tell us about your business
            </p>
            <div class="mt-6">
              <label for="companyName" class="block mb-1 text-sm font-medium leading-5 text-gray-700">
                Company name
              </label>
              <input id="companyName" v-model="name" type="text" class="block w-full form-input" />
            </div>
            <div class="mt-6">
              <label for="company_domain" class="block text-sm font-medium leading-5 text-gray-700"
                >Primary website domain</label
              >
              <div class="relative mt-1 rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <span class="text-gray-500">
                    http://
                  </span>
                </div>
                <input
                  id="company_domain"
                  v-model="domain"
                  type="text"
                  class="block w-full pl-16 form-input"
                  placeholder="example.com"
                />
              </div>
            </div>
            <div class="mt-5">
              <BaseButton :loading="saving">Save</BaseButton>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="my-8 overflow-hidden bg-white rounded-lg ring-1 ring-black ring-opacity-5">
      <div class="p-6">
        <h3 class="text-xl font-medium leading-6 text-neutral-700">Debug mode</h3>
        <p class="mt-2 leading-5 text-gray-500">
          Place your workspace in debug mode to see detailed console logs in your browser to help trouble shoot issues
          on your website.
        </p>
        <div class="mt-5">
          <BaseButton :theme="debugMode ? 'danger' : 'secondary'" :loading="saving">Activate debug mode</BaseButton>
        </div>
      </div>
    </div>

    <!-- <div class="p-6 my-8 bg-white rounded-lg ring-1 ring-black ring-opacity-5">
      <h3 class="text-lg font-medium leading-6 text-gray-900">Delete account</h3>
      <p class="mt-1 mb-6 text-sm leading-5 text-gray-500">
        Tread lightly. Deleting your account is irriversable and will cause you to lose all your data.
      </p>
      <BaseButton theme="secondary" size="sm">Delete account</BaseButton>
    </div> -->
  </div>
</template>
