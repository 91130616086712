let timer = null
let wait = null
export default {
  install(Vue, { store }) {
    function toast(type, msg, more, expire = 3000) {
      if (type === 'error') expire = 4000
      if (timer) clearTimeout(timer)
      if (wait) clearTimeout(wait)
      if (store.state.toast) {
        store.commit('TOAST_SET')
        wait = setTimeout(() => {
          store.commit('TOAST_SET', { msg, more, type })
          if (expire !== 0)
            timer = setTimeout(() => {
              store.commit('TOAST_SET')
            }, expire)
        }, 300)
      } else {
        store.commit('TOAST_SET', { msg, more, type })
        if (expire !== 0)
          timer = setTimeout(() => {
            store.commit('TOAST_SET')
          }, expire)
      }
    }

    Vue.prototype.$toast = (msg, more, expire) => toast('primary', msg, more, expire)
    Vue.prototype.$toastSuccess = (msg, more, expire) => toast('success', msg, more, expire)
    Vue.prototype.$toastWarning = (msg, more, expire) => toast('warning', msg, more, expire)
    Vue.prototype.$toastError = (msg, more, expire) => toast('error', msg, more, expire)
  },
}
