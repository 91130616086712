import gql from 'graphql-tag'
import store from '@/store'
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost'
import { WebSocketLink } from 'apollo-link-ws'

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const httpLink = new HttpLink({ uri: process.env.VUE_APP_GRAPHQL_URI })

const authLink = (token) => {
  return new ApolloLink((operation, forward) => {
    // Use the setContext method to set the HTTP headers.
    operation.setContext({
      headers: {
        ...(!process.env.VUE_APP_ADMIN_SECRET && { authorization: token ? `Bearer ${token}` : '' }),
        'x-hasura-role': process.env.VUE_APP_ADMIN_SECRET ? 'admin' : 'user',
        ...(process.env.VUE_APP_ADMIN_SECRET && { 'x-hasura-admin-secret': process.env.VUE_APP_ADMIN_SECRET }),
      },
    })

    // Call the next link in the middleware chain.
    return forward(operation)
  })
}

// Create a WebSocket link:
const wsLink = (token) => {
  return new WebSocketLink({
    uri: process.env.VUE_APP_GRAPHQL_WS_URI,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          ...(!process.env.VUE_APP_ADMIN_SECRET && { authorization: token ? `Bearer ${token}` : '' }),
          'x-hasura-role': process.env.VUE_APP_ADMIN_SECRET ? 'admin' : 'user',
          ...(process.env.VUE_APP_ADMIN_SECRET && { 'x-hasura-admin-secret': process.env.VUE_APP_ADMIN_SECRET }),
        },
      },
    },
  })
}

const httpClient = (token) => {
  return new ApolloClient({
    link: authLink(token).concat(httpLink), // Chain it with the HttpLink
    cache: new InMemoryCache(),
    defaultOptions,
  })
}

const wsClient = (token) => {
  return new ApolloClient({
    link: wsLink(token),
    cache: new InMemoryCache(),
    defaultOptions,
  })
}

export default {
  query: async (q, v = {}, fragment = '') => {
    return httpClient(store.state.user.idToken)
      .query({
        query: gql`
          ${fragment}
          query ${q}
          `,
        variables: v,
      })
      .then((res) => {
        if (res && res.errors) {
          // handle hasura errors
          throw res.errors
        } else {
          return res.data
        }
      })
      .catch((errs) => {
        // eslint-disable-next-line no-console
        console.error('HASURA ERRORS')
        if (errs && errs.map)
          errs.map((e) => {
            // eslint-disable-next-line no-console
            console.error(e.extensions ? e.extensions.code : '', e.message, e.extensions)
          })
        // eslint-disable-next-line no-console
        else console.error(errs)
        throw new Error('There was a problem connecting to the database')
      })
  },
  mutate: async (m, v = {}, fragment = '') => {
    return httpClient(store.state.user.idToken)
      .mutate({
        mutation: gql`
        ${fragment}
        mutation ${m}`,
        variables: v,
      })
      .then((res) => {
        if (res && res.errors) {
          // handle hasura errors
          throw res.errors
        } else {
          return res.data
        }
      })
      .catch((errs) => {
        // eslint-disable-next-line no-console
        console.error('HASURA ERRORS')
        if (errs && errs.map)
          errs.map((e) => {
            // eslint-disable-next-line no-console
            console.error(e.extensions ? e.extensions.code : '', e.message, e.extensions)
          })
        // eslint-disable-next-line no-console
        else console.error(errs)
        throw new Error('There was a problem connecting to the database')
      })
  },
  subscribe: async (s, v = {}, callback) => {
    const ws = wsClient(store.state.user.idToken)
    ws.subscribe({
      query: gql`subscription ${s}`,
      variables: v,
    }).subscribe({
      next(result) {
        callback(result && result.data)
      },
      error(errs) {
        // eslint-disable-next-line no-console
        console.error('HASURA ERRORS')
        if (errs && errs.map)
          errs.map((e) => {
            // eslint-disable-next-line no-console
            console.error(e.extensions ? e.extensions.code : '', e.message, e.extensions)
          })
        // eslint-disable-next-line no-console
        else console.error(errs)
        throw new Error('There was a problem connecting to the database')
      },
    })
    return ws.link.subscriptionClient
  },
}
