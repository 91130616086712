<script>
export default {
  name: 'BillingOverlay',
  props: {
    show: Boolean,
    classes: {
      type: String,
      default: 'w-screen ml-32',
    },
    layer: {
      type: String,
      default: '1',
    },
  },
}
</script>

<template>
  <div class="fixed inset-0 overflow-hidden pointer-events-none" :style="{ zIndex: `100${layer || '1'}` }">
    <div class="absolute inset-0 overflow-hidden">
      <transition
        enter-active-class="duration-500 ease-in-out"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-500 ease-in-out"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          class="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75 pointer-events-auto"
          @click="$emit('close', null)"
        ></div>
      </transition>

      <section class="absolute inset-y-0 right-0 flex max-w-full">
        <transition
          enter-active-class="transition duration-500 ease-in-out transform sm:duration-700"
          enter-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transition duration-500 ease-in-out transform sm:duration-700"
          leave-class="translate-x-0"
          leave-to-class="translate-x-full"
        >
          <div v-if="show" :class="classes" class="pointer-events-auto">
            <div class="relative flex flex-col h-full py-6 space-y-6 overflow-y-scroll bg-white shadow-xl">
              <header class="absolute flex items-center w-10 h-10 top-3 left-3">
                <button
                  aria-label="Close panel"
                  class="w-10 h-10 mx-auto text-center text-gray-500 transition duration-200 ease-in-out rounded-full outline-none cursor-pointer focus:ring-2 focus:ring-blue-400 focus:outline-none hover:text-gray-600 bg-gray-50 hover:bg-gray-100"
                  @click="$emit('close', null)"
                >
                  <svg
                    class="w-6 h-6 mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </header>
              <div class="relative flex-1">
                <slot />
              </div>
            </div>
          </div>
        </transition>
      </section>
    </div>
  </div>
</template>
