import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '@/store'

import index from '@/views'
import Home from '@/views/home'
import campaigns from './campaigns'
import audiences from './audiences'
import data from './data'
import settings from './settings'
import publicRoutes from './public'

Vue.use(VueRouter)

const routes = [
  {
    path: '/setup',
    component: index,
    children: [
      {
        path: '/',
        name: 'Setup',
        component: Home,
      },
      ...campaigns,
      ...audiences,
      ...data,
      ...settings,
    ],
  },
  ...publicRoutes,
  {
    path: '*',
    redirect: '/campaigns',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = `Proof - ${to.name}`
  next()
})

export default router
