import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user'
import campaigns from './modules/campaigns'
import settings from './modules/settings'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showWorkspacesList: false,
    toast: null,
    planChangeDrawer: null,
  },
  plugins: [
    createPersistedState({
      key: 'pulse',
      paths: ['user'],
    }),
  ],
  mutations: {
    HIDE_WORKSPACES_LIST: (state) => {
      state.showWorkspacesList = false
    },
    SHOW_WORKSPACES_LIST: (state) => {
      state.showWorkspacesList = true
    },
    TOAST_SET: (state, toast) => {
      state.toast = toast
    },
    PLAN_CHANGE_DRAWER_SET: (state, view) => {
      state.planChangeDrawer = view
    },
  },
  actions: {},
  modules: {
    user,
    campaigns,
    settings,
  },
})
