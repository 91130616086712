import invitation from '@/views/public/invitation'
import UserRegistration from '@/views/public/signup/UserRegistration'

export default [
  {
    path: '/invite/:inviteId',
    name: 'Accept invite',
    component: invitation,
    meta: { public: true },
  },
  {
    path: '/signup',
    component: UserRegistration,
    meta: { public: true },
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    meta: { onboarding: true },
  },
]
