/* eslint-disable vue/valid-v-bind */
<script>
import interact from 'interactjs'

export default {
  name: 'BrowserPreview',
  props: {
    html: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      width: '100%',
      resizing: false,
    }
  },
  mounted() {
    interact('.resize-drag').resizable({
      // resize from all edges and corners
      edges: { right: true },

      listeners: {
        move(event) {
          var target = event.target
          target.style.width = event.rect.width + 'px'
        },
      },
      modifiers: [
        // keep the edges inside the parent
        interact.modifiers.restrictEdges({
          outer: 'parent',
        }),

        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 400 },
        }),
      ],

      inertia: true,
    })
  },
}
</script>

<template>
  <div>
    <div class="bg-white border border-gray-200 rounded-md shadow-sm resize-drag" style="touch-action: none;">
      <div>
        <div class="flex p-4 space-x-3">
          <div class="flex-initial w-3 h-3 rounded-full ring-1 ring-gray-200"></div>
          <div class="flex-initial w-3 h-3 rounded-full ring-1 ring-gray-200"></div>
          <div class="flex-initial w-3 h-3 rounded-full ring-1 ring-gray-200"></div>
        </div>
        <div class="flex items-center p-3 space-x-3 text-gray-200 border-t border-gray-200">
          <div>
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div>
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div>
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          </div>
          <div class="flex-grow">
            <div class="w-full rounded-full h-7 ring-1 ring-gray-200"></div>
          </div>
          <div>
            <svg class="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="relative overflow-hidden border-t border-gray-200 h-60 rounded-b-md">
        <div class="flex items-center p-6 space-x-6">
          <div class="w-10 h-10 bg-gray-100 rounded-full"></div>
          <div class="h-6 bg-gray-100 rounded w-36"></div>
          <div class="flex-grow"></div>
          <div class="text-gray-100">
            <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <div class="h-3 mx-auto mt-10 bg-gray-100 rounded w-72"></div>
        <div class="h-3 mx-auto mt-5 bg-gray-100 rounded w-28"></div>

        <!-- PREVIEW -->
        <div class="absolute inset-0 pointer-events-none">
          <div :style="'max-width: 100%; width: ' + width" class="relative">
            <iframe
              id="iFrameResizer1"
              x-ref="iframe"
              class="w-full h-60"
              :srcdoc="`<!DOCTYPE html><meta charset='utf-8' /><meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' /><link rel='stylesheet' href='https://rsms.me/inter/inter.css' /><link rel='stylesheet' href='https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css' /><script src='https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.3.5/dist/alpine.js' defer></script><body class='font-sans antialiased bg-transparent'>
                ${html}</body>`"
              style="overflow: auto;"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
