<script>
export default {
  name: 'Data',
  components: {},
  props: {},
  methods: {},
}
</script>

<template>
  <div class="pb-8">
    <div class="px-12 pt-8 bg-white border-b border-gray-200">
      <h1 class="mb-4 text-3xl font-bold tracking-tight text-neutral-700">Data</h1>
      <div>
        <div class="">
          <nav class="flex -mb-px">
            <router-link
              to="/data/metrics"
              active-class="text-blue-400 border-blue-400"
              class="px-1 py-4 font-medium leading-5 text-gray-500 whitespace-no-wrap border-b-2 border-transparent focus:outline-none hover:text-gray-700 hover:border-gray-300 focus:text-blue-500 focus:border-blue-400"
            >
              Metrics
            </router-link>
            <router-link
              to="/data/log"
              active-class="text-blue-400 border-blue-400"
              class="px-1 py-4 ml-8 font-medium leading-5 text-gray-500 whitespace-no-wrap border-b-2 border-transparent hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-blue-500 focus:border-blue-400"
            >
              Event log
            </router-link>

            <router-link
              to="/data/events"
              active-class="text-blue-400 border-blue-400"
              class="px-1 py-4 ml-8 font-medium leading-5 text-gray-500 whitespace-no-wrap border-b-2 border-transparent hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-blue-500 focus:border-blue-400"
            >
              Events
            </router-link>

            <!-- <router-link
              to="/data/event-properties"
              active-class="text-blue-400 border-blue-400"
              class="px-1 py-4 ml-8 font-medium leading-5 text-gray-500 whitespace-no-wrap border-b-2 border-transparent hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-blue-500 focus:border-blue-400"
            >
              Event properties
            </router-link> -->

            <router-link
              to="/data/traits"
              active-class="text-blue-400 border-blue-400"
              class="px-1 py-4 ml-8 font-medium leading-5 text-gray-500 whitespace-no-wrap border-b-2 border-transparent hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-blue-500 focus:border-blue-400"
            >
              User traits
            </router-link>
          </nav>
        </div>
      </div>
    </div>

    <router-view />
  </div>
</template>
