<script>
import BaseButton from '@/components/Base/Button'
import Overlay from './overlay'

export default {
  name: 'PlanCart',
  components: {
    BaseButton,
    Overlay,
  },
  props: {
    plan: {
      type: Object,
      default: null,
    },
  },
  methods: {
    back() {
      this.$emit('close')
    },
  },
}
</script>

<template>
  <Overlay layer="2" classes="max-w-md" :show="!!plan" @close="back">
    <div v-if="plan" class="p-6">
      <h2 class="text-3xl font-extrabold leading-10 text-gray-900 sm:leading-none sm:tracking-tight">Checkout</h2>
      <p class="mt-3 text-lg leading-7 text-gray-500">
        Review your plan and traffic selections before starting your trial.
      </p>

      <div class="px-4 py-5 mt-5 border border-gray-200 rounded-md shadow-sm">
        <div class="flex flex-row items-center w-full mb-5">
          <div class="flex-grow font-semibold text-gray-600 text-md">Selected plan</div>
          <div class="flex-initial text-sm text-right text-green-500">
            <svg
              class="inline-block w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>

        <div class="text-lg font-medium text-gray-700">
          {{ plan.label }}
        </div>
        <div class="text-sm text-gray-500">
          {{ plan.description }}
        </div>
      </div>

      <div class="px-4 py-5 mt-5 border border-gray-200 rounded-md shadow-sm">
        <div class="flex flex-row items-center w-full mb-3">
          <div class="flex-grow font-semibold text-gray-600 text-md">Unique monthly visitors</div>
          <div class="flex-initial text-sm text-right text-green-500">
            <svg
              class="inline-block w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <select
          id="location"
          class="block w-full py-2 pl-3 pr-10 text-base leading-6 text-gray-700 border-gray-300 rounded-md form-select focus:outline-none focus:ring-blue-400 focus:border-blue-300 sm:text-lg sm:leading-6"
        >
          <option>10,000</option>
          <option selected>25,000 (included)</option>
          <option>50,000</option>
        </select>
        <div class="mt-3 text-sm text-gray-500">The number of unique visitors that come to your website each month</div>
      </div>
      <div class="mt-12">
        <div class="flex-grow font-semibold text-gray-600 text-md">Your free 14 day trial</div>
        <hr class="my-4 border-0 border-b border-gray-200" />
        <div class="flex flex-row items-center w-full mb-3">
          <div class="flex-grow text-gray-600 text-md">Total after trial</div>
          <div class="flex-initial text-sm text-right text-gray-600">${{ plan.price['monthly'] }}/mo</div>
        </div>
        <div class="flex flex-row items-center w-full mb-3 text-lg">
          <div class="flex-grow font-bold text-gray-600">Due today</div>
          <div class="flex-initial font-bold text-right text-gray-600">$0</div>
        </div>
        <BaseButton classes="w-full mt-5">
          <span class="inline-flex items-center">
            <div>
              <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <div class="ml-2">Confirm plan</div>
          </span>
        </BaseButton>
      </div>
    </div>
  </Overlay>
</template>
