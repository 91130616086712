import settings from '@/views/settings'
import personal from '@/views/settings/personal'
import workspace from '@/views/settings/workspace'
import team from '@/views/settings/team'
import billing from '@/views/settings/billing'

export default [
  {
    path: '/settings',
    redirect: '/settings/workspace',
    component: settings,
    children: [
      {
        path: 'workspace',
        name: 'Workspace settings',
        component: workspace,
      },
      {
        path: 'personal',
        name: 'Personal settings',
        component: personal,
      },
      {
        path: 'team',
        name: 'Team settings',
        component: team,
      },
      {
        path: 'billing',
        name: 'Usage & billing',
        component: billing,
      },
    ],
  },
]
