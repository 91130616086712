<script>
import browserPreview from './browserPreview'
import bannerPreview from '../contentPreview/bannerSticky'

export default {
  name: 'BannerEditor',
  components: {
    browserPreview,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {},
    }
  },
  computed: {
    html() {
      return bannerPreview(this.options)
    },
  },
  created() {
    this.options = JSON.parse(JSON.stringify(this.content || {}))
  },
  methods: {
    handlePropertyChange(prop, val) {
      this.$set(this.options, prop, val)
      this.$emit('change', this.options)
    },
  },
}
</script>

<template>
  <div>
    <browserPreview :html="html" />
    <div class="grid grid-cols-3 gap-8 py-4 mt-6 font-medium text-left">
      <div class="col-span-1">
        <h3 class="mb-3 text-lg font-semibold text-gray-700">Settings</h3>
        <div>
          <label for="bannerMessage" class="block mb-2 font-medium text-gray-600 sr-only">Banner message</label>
          <input
            id="bannerMessage"
            v-model="options.text"
            placeholder="Your message"
            type="text"
            class="mb-4 form-input"
            @input="(e) => handlePropertyChange('text', e.target.value)"
          />
        </div>
        <div>
          <label for="mobileText" class="block mb-2 font-medium text-gray-600 sr-only">Mobile banner message</label>
          <input
            id="mobileText"
            v-model="options.mobileText"
            placeholder="Optional message for smaller screens"
            type="text"
            class="mb-4 form-input"
            @input="(e) => handlePropertyChange('mobileText', e.target.value)"
          />
        </div>
        <div class="flex items-center">
          <button
            type="button"
            aria-pressed="false"
            aria-labelledby="toggleLabel"
            :class="{ 'bg-blue-400': options.dismissable, 'bg-gray-200': !options.dismissable }"
            class="relative flex items-center transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
            @click="handlePropertyChange('dismissable', !options.dismissable)"
          >
            <span class="sr-only">Dismissable</span>
            <span
              aria-hidden="true"
              :class="{ 'translate-x-5': options.dismissable, 'translate-x-0': !options.dismissable }"
              class="relative z-10 block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full ring-0"
            ></span>
          </button>
          <span id="toggleLabel" class="ml-3">
            <span class="font-medium text-gray-700">Show a dismiss button</span>
          </span>
        </div>
      </div>
      <div class="col-span-1">
        <h3 class="mb-3 text-lg font-semibold text-gray-700">Action</h3>
        <div>
          <label for="actionText" class="block mb-2 font-medium text-gray-600 sr-only">Action text</label>
          <input
            id="actionText"
            v-model="options.actionText"
            placeholder="Enter action text"
            type="text"
            class="mb-4 form-input"
            @input="(e) => handlePropertyChange('actionText', e.target.value)"
          />
        </div>
        <div>
          <label for="actionText" class="block mb-2 font-medium text-gray-600 sr-only">Action URL</label>
          <input
            id="actionText"
            v-model="options.actionUrl"
            placeholder="Enter action URL"
            type="url"
            class="mb-4 form-input"
            @input="(e) => handlePropertyChange('actionUrl', e.target.value)"
          />
        </div>
        <div class="flex items-center mb-4">
          <button
            type="button"
            aria-pressed="false"
            aria-labelledby="toggleLabel"
            :class="{ 'bg-blue-400': options.actionOpenNewTab, 'bg-gray-200': !options.actionOpenNewTab }"
            class="relative flex items-center transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
            @click="handlePropertyChange('actionOpenNewTab', !options.actionOpenNewTab)"
          >
            <span class="sr-only">Dismissable</span>
            <span
              aria-hidden="true"
              :class="{ 'translate-x-5': options.actionOpenNewTab, 'translate-x-0': !options.actionOpenNewTab }"
              class="relative z-10 block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full ring-0"
            ></span>
          </button>
          <span id="toggleLabel" class="ml-3">
            <span class="font-medium text-gray-700">Open URL in a new tab</span>
          </span>
        </div>
        <div class="flex items-center">
          <button
            type="button"
            aria-pressed="false"
            aria-labelledby="toggleLabel"
            :class="{ 'bg-blue-400': options.actionDismissOnClick, 'bg-gray-200': !options.actionDismissOnClick }"
            class="relative flex items-center transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
            @click="handlePropertyChange('actionDismissOnClick', !options.actionDismissOnClick)"
          >
            <span class="sr-only">Dismissable</span>
            <span
              aria-hidden="true"
              :class="{ 'translate-x-5': options.actionDismissOnClick, 'translate-x-0': !options.actionDismissOnClick }"
              class="relative z-10 block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full ring-0"
            ></span>
          </button>
          <span id="toggleLabel" class="ml-3">
            <span class="font-medium text-gray-700">Dismiss the banner on click</span>
          </span>
        </div>
      </div>
      <div class="col-span-1">
        <h3 class="mb-3 text-lg font-semibold text-gray-700">Style</h3>
        <div class="flex items-center mb-4 space-x-1 text-gray-700 rounded-md ring-2 ring-gray-100 ring-offset-2">
          <button
            :class="{ 'bg-blue-400 text-white z-10': options.styleType === 'edgeToEdge' }"
            class="relative w-full p-1 font-medium transition-all duration-200 bg-gray-100 rounded-md outline-none focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 focus:z-10"
            @click="handlePropertyChange('styleType', 'edgeToEdge')"
          >
            Edge to edge
          </button>
          <button
            :class="{ 'bg-blue-400 text-white z-10': options.styleType === 'floating' }"
            class="relative w-full p-1 font-medium transition-all duration-200 bg-gray-100 rounded-md outline-none focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 focus:z-10"
            @click="handlePropertyChange('styleType', 'floating')"
          >
            Floating
          </button>
        </div>
        <div class="flex items-center mb-4 space-x-1 text-gray-700 rounded-md ring-2 ring-gray-100 ring-offset-2">
          <button
            :class="{ 'bg-blue-400 text-white z-10': options.position === 'top' }"
            class="relative w-full p-1 font-medium transition-all duration-200 bg-gray-100 rounded-md outline-none focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 focus:z-10"
            @click="handlePropertyChange('position', 'top')"
          >
            Top
          </button>
          <button
            :class="{ 'bg-blue-400 text-white z-10': options.position === 'bottom' }"
            class="relative w-full p-1 font-medium transition-all duration-200 bg-gray-100 rounded-md outline-none focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 focus:z-10"
            @click="handlePropertyChange('position', 'bottom')"
          >
            Bottom
          </button>
        </div>
        <div>
          <label for="actionText" class="block mb-2 font-medium text-gray-600">Color</label>
          <div class="inline-flex items-center space-x-3">
            <button
              :class="{ 'ring-2 ring-offset-2 ring-blue-400': options.bgColor === '#94A3B8' }"
              style="background-color: #94a3b8;"
              class="w-6 h-6 transition-all duration-200 rounded-full outline-none hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              @click="handlePropertyChange('bgColor', '#94A3B8')"
            ></button>
            <button
              :class="{ 'ring-2 ring-offset-2 ring-blue-400': options.bgColor === 'rgb(51, 65, 85)' }"
              class="w-6 h-6 transition-all duration-200 bg-gray-700 rounded-full outline-none hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              @click="handlePropertyChange('bgColor', 'rgb(51, 65, 85)')"
            ></button>
            <button
              :class="{ 'ring-2 ring-offset-2 ring-blue-400': options.bgColor === 'rgb(8, 8, 61)' }"
              class="w-6 h-6 transition-all duration-200 rounded-full outline-none bg-neutral-800 hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              @click="handlePropertyChange('bgColor', 'rgb(8, 8, 61)')"
            ></button>
            <button
              :class="{ 'ring-2 ring-offset-2 ring-blue-400': options.bgColor === '#2563EB' }"
              style="background-color: #2563eb;"
              class="w-6 h-6 transition-all duration-200 rounded-full outline-none hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              @click="handlePropertyChange('bgColor', '#2563EB')"
            ></button>
            <button
              :class="{ 'ring-2 ring-offset-2 ring-blue-400': options.bgColor === '#7C3AED' }"
              style="background-color: #7c3aed;"
              class="w-6 h-6 transition-all duration-200 rounded-full outline-none hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              @click="handlePropertyChange('bgColor', '#7C3AED')"
            ></button>
            <button
              :class="{ 'ring-2 ring-offset-2 ring-blue-400': options.bgColor === 'rgb(22, 163, 74)' }"
              class="w-6 h-6 transition-all duration-200 bg-green-600 rounded-full outline-none hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              @click="handlePropertyChange('bgColor', 'rgb(22, 163, 74)')"
            ></button>
            <button
              :class="{ 'ring-2 ring-offset-2 ring-blue-400': options.bgColor === '#14B8A6' }"
              style="background-color: #14b8a6;"
              class="w-6 h-6 transition-all duration-200 rounded-full outline-none hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              @click="handlePropertyChange('bgColor', '#14B8A6')"
            ></button>
            <button
              :class="{ 'ring-2 ring-offset-2 ring-blue-400': options.bgColor === 'rgb(217, 119, 6)' }"
              class="w-6 h-6 transition-all duration-200 bg-yellow-600 rounded-full outline-none hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              @click="handlePropertyChange('bgColor', 'rgb(217, 119, 6)')"
            ></button>
            <button
              :class="{ 'ring-2 ring-offset-2 ring-blue-400': options.bgColor === '#E11D48' }"
              style="background-color: #e11d48;"
              class="w-6 h-6 transition-all duration-200 rounded-full outline-none hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              @click="handlePropertyChange('bgColor', '#E11D48')"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
