export default ({
  text,
  mobileText,
  position = 'bottom',
  styleType,
  bgColor,
  imageUrl,
  dismissable,
  actionText,
} = {}) => {
  const actionOpenNewTab = true
  const actionUrl = '#'
  return `
<div ${
    styleType === 'edgeToEdge'
      ? `class="absolute inset-x-0 ${position === 'top' ? 'top-0' : 'bottom-0'} bg-gray-800" style="background-color:${
          bgColor || ''
        }"`
      : `class="absolute inset-x-0 ${
          position === 'top' ? 'top-0' : 'bottom-0'
        } max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 ${position === 'top' ? 'pt-6' : 'pb-6'}"`
  }>
  <div ${
    styleType === 'edgeToEdge'
      ? 'class="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8"'
      : `class="p-2 rounded-lg bg-gray-800 shadow-lg sm:p-3" style="background-color:${bgColor || ''}"`
  }>
    <div class="flex flex-wrap items-center justify-between">
      <div class="flex items-center flex-1 w-0">
        <span class="flex p-2 bg-black bg-opacity-50 rounded-full">
          <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
        </span>
        <p class="ml-4 font-medium text-white truncate">
          <span class="md:hidden">
            ${mobileText || text || 'Your message'}
          </span>
          <span class="hidden md:inline">
            ${text || 'Your message'}
          </span>
        </p>
      </div>
      ${
        actionText
          ? `<div class="flex-shrink-0 order-3 w-full mt-2 sm:order-2 sm:mt-0 sm:w-auto">
            <a href="${actionUrl || ''}" ${actionOpenNewTab ? 'target="_blank"' : ''}
              style="color: ${bgColor || ''}"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-800 bg-white border border-transparent rounded-md shadow-sm hover:bg-gray-50"
            >
              ${actionText || ''}
            </a>
          </div>`
          : ''
      }

      <div class="flex-shrink-0 order-2 sm:order-3 sm:ml-3 ${!dismissable ? 'opacity-0 w-1' : ''}">
        <button
          type="button"
          class="flex p-2 rounded-md hover:bg-white hover:bg-opacity-25 focus:outline-none focus:ring-2 focus:ring-white"
        >
          <span class="sr-only">Dismiss</span>
          <svg
            class="w-6 h-6 text-white"
            x-description="Heroicon name: x"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
`
}
