import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import utils from '@/lib/utils'
import { version } from '../package.json'
import toast from '@/components/plugins/toast/install'

import VueTippy, { TippyComponent } from 'vue-tippy'
// import 'tippy.js/themes/light-border.css'

Vue.use(VueTippy, {
  // theme: 'light-border',
  animation: 'shift-away',
})
Vue.component('tippy', TippyComponent)

/* global window */
// eslint-disable-next-line
console.debug(`%c Proof v${version} `, 'background: #7e3af2; color: white; padding:2px;')

Vue.config.productionTip = false

// Wipe localstorage if new app version detected
const ls = window.localStorage
const cachedVersion = ls.getItem('pulse:version')
if (cachedVersion !== version) {
  // eslint-disable-next-line
  console.debug('New version: wipe cache')
  ls.removeItem('pulse:app')
  ls.setItem('pulse:version', version)
}
if (!cachedVersion) ls.setItem('pulse:version', version)

Vue.prototype.$version = () => {
  return version
}
// Give all Vue components access to user data
Vue.prototype.$user = () => {
  return store.state.user
}

// Global filters
Vue.filter('fromNow', (val) => utils.fromNow(val))
Vue.filter('formatDate', (val) => utils.formatDate(val))
Vue.filter('formatNumber', (val) => utils.thousandsSeperator(val))

Vue.use(toast, {
  store,
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
