<script>
import BaseButton from './Base/Button'
import Avatar from './Base/Avatar'
import Spinner from '@/components/Base/Spinner'

export default {
  name: 'Workspaces',
  components: {
    BaseButton,
    Avatar,
    Spinner,
  },
  data() {
    return {
      workspaces: [],
      workspacesQueried: [],
      search: '',
      loading: true,
      searching: false,
      acceptingInvite: null,
    }
  },
  computed: {
    invites() {
      return this.$store.state.user.invites || []
    },
  },
  async created() {
    this.getInvites()
    await this.getWorkspaces()
    this.loading = false
  },
  methods: {
    async getWorkspaces(query) {
      if (query) {
        this.searching = true
        this.workspacesQueried = await this.$store.dispatch('WORKSPACES_GET_LIST', query)
      } else {
        this.workspaces = await this.$store.dispatch('WORKSPACES_GET_LIST')
        this.workspacesQueried = this.workspaces
      }
      this.searching = false
    },
    async getInvites() {
      await this.$store.dispatch('USER_GET_INVITES', this.$user().email)
    },
    async acceptInvite(workspaceName, inviteId) {
      try {
        this.acceptingInvite = inviteId
        await this.$store.dispatch('USER_ACCEPT_INVITE', inviteId)
        this.$toastSuccess(`${workspaceName} workspace joined`)
        await this.getWorkspaces()
      } catch (e) {
        this.$toastError('There was a problem joining the workspace')
      }
      this.acceptingInvite = null
    },
  },
}
</script>

<template>
  <div class="relative">
    <!-- LOADING INDICATOR -->
    <transition name="fade">
      <div v-if="loading || searching" class="absolute z-20 top-4 left-4">
        <Spinner classes="h-8 w-8 text-teal-500" />
      </div>
    </transition>

    <div class="py-12 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto sm:px-6 lg:px-8">
        <div
          v-if="!workspaces.length && !invites.length && !loading"
          class="max-w-xl p-6 mx-auto mb-12 bg-white rounded-lg"
        >
          <h1 class="mb-3 text-3xl font-bold tracking-tight text-neutral-700">No workspaces found</h1>
          <p class="text-gray-500">
            It looks like you do not have access to any workspaces on Proof Pulse. You can either request a teammate to
            invite you or create a new one.
          </p>
          <BaseButton classes="mt-6 mr-4" @click="$router.push('/onboarding')">New workspace</BaseButton>
          <BaseButton classes="mt-4" theme="secondary" @click="$store.dispatch('USER_LOGOUT')">Sign out</BaseButton>
        </div>

        <div
          v-else-if="(invites.length || workspaces.length) && !loading"
          class="relative pt-8 pb-12 mb-6 bg-white rounded-lg shadow-xl pl-28 pr-9"
        >
          <div class="absolute top-8 left-7">
            <div class="inline-block p-3 mb-3 text-center text-teal-500 bg-gray-100 rounded-full">
              <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20">
                <path
                  d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
                ></path>
              </svg>
            </div>
          </div>
          <div>
            <div v-if="invites.length" class="mb-8">
              <h3 class="mb-2 text-lg font-semibold tracking-tight text-gray-700">New invitations</h3>
              <ul class="bg-white border border-gray-200 rounded-md">
                <li
                  v-for="(invite, i) in invites"
                  :key="invite.id"
                  :class="{ 'border-t border-gray-200': i !== 0 }"
                  class="flex items-center justify-between py-3 pl-3 pr-4 text-sm leading-5"
                >
                  <div class="inline-flex items-center flex-1 w-0">
                    <svg class="flex-shrink-0 w-5 h-5 text-teal-400" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"
                      ></path>
                      <path
                        d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"
                      ></path>
                    </svg>
                    <span class="ml-2 truncate">
                      {{ invite.workspace.name }}
                    </span>
                    <span class="ml-4 text-gray-500"> Invited {{ invite.created_at | fromNow }} </span>
                  </div>
                  <div class="flex-shrink-0 ml-4">
                    <BaseButton
                      theme="flat"
                      :loading="acceptingInvite === invite.id"
                      @click="acceptInvite(invite.workspace.name, invite.id)"
                    >
                      Join
                    </BaseButton>
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="workspaces.length">
              <h3 class="mb-1 text-2xl font-semibold tracking-tight">Active workspaces</h3>
              <p class="text-gray-400">
                You are an active member of the workspaces listed below
              </p>
              <div class="px-4 mt-4 sm:px-0">
                <label for="search" class="sr-only">Search</label>
                <div class="relative mt-1 rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      class="w-5 h-5 text-gray-400"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </div>
                  <input
                    id="search"
                    v-model="search"
                    type="search"
                    autocomplete="off"
                    class="block w-full pl-10 form-input sm:text-sm sm:leading-5"
                    placeholder="Search"
                    @input="(e) => getWorkspaces(e.target.value)"
                  />
                </div>
              </div>
              <div class="px-4 mt-5 sm:px-0">
                <button
                  v-for="ws in workspacesQueried"
                  :key="ws.id"
                  class="inline-flex items-center w-full px-4 py-2 mb-3 text-center transition duration-150 ease-in-out bg-white border-2 border-transparent rounded-lg ring-1 ring-black ring-opacity-5 hover:border-purple-400 sm:p-3 hover:shadow-xl focus:outline-none focus:ring-purple-500"
                  @click="$emit('open', ws)"
                >
                  <div class="flex items-center align-middle">
                    <div class="m-auto"><Avatar size="2" :name="ws.name" /></div>
                  </div>
                  <div class="ml-5 font-semibold tracking-tight text-gray-700 truncate">{{ ws.name }}</div>
                  <div>
                    <p class="ml-5 text-sm text-gray-400">{{ ws.campaignCount }} campaigns</p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
