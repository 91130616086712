<script>
import BaseButton from '@/components/Base/Button'

export default {
  name: 'WorkspaceInfo',
  components: {
    BaseButton,
  },
  props: {
    creatingWorkspace: Boolean,
    name: {
      type: String,
      default: '',
    },
    domain: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      wsName: '',
      wsDomain: '',
    }
  },
  created() {
    this.wsName = this.name
    this.wsDomain = this.domain
  },
}
</script>

<template>
  <form class="max-w-xl px-5 mx-auto space-y-6" @submit.prevent="$emit('submit', { name: wsName, domain: wsDomain })">
    <div class="space-y-6 md:grid md:grid-cols-2 md:gap-3 md:space-y-0">
      <div>
        <label for="name" class="block text-sm font-medium leading-5 text-gray-700">
          Company name
        </label>
        <div class="mt-2 rounded-md shadow-sm">
          <input
            id="name"
            v-model="wsName"
            autocomplete="company-name"
            type="text"
            placeholder="SpaceX"
            required
            class="block w-full form-input"
          />
        </div>
      </div>
      <div>
        <label for="domain" class="block text-sm font-medium leading-5 text-gray-700">
          Domain
        </label>
        <div class="mt-2 rounded-md shadow-sm">
          <input
            id="domain"
            v-model="wsDomain"
            type="text"
            autocomplete="domain"
            required
            placeholder="spacex.com"
            class="block w-full form-input"
          />
        </div>
      </div>
    </div>
    <div class="text-center">
      <BaseButton size="xl" classes="w-full md:w-auto" :loading="creatingWorkspace">
        <span class="inline-flex items-center">
          <div class="mr-3">Continue</div>
          <div>
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </span>
      </BaseButton>
    </div>
  </form>
</template>
