<script>
import overlay from './overlay'
import plans from './plans'

export default {
  name: 'Billing',
  components: {
    overlay,
    plans,
  },
  computed: {
    view() {
      return this.$store.state.planChangeDrawer
    },
  },
  methods: {
    setOverlay(view) {
      this.$store.commit('PLAN_CHANGE_DRAWER_SET', view)
    },
  },
}
</script>

<template>
  <overlay :show="!!view" @close="setOverlay">
    <plans v-if="view === 'plans'" />
  </overlay>
</template>
