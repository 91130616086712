<script>
import BaseButton from '@/components/Base/Button'

export default {
  name: 'PersonalSettings',
  components: {
    BaseButton,
  },
  props: {},
  data() {
    return {
      newEmail: '',
      firstName: this.$user().firstName,
      lastName: this.$user().lastName,
      savingUser: false,
    }
  },
  created() {
    this.$store.dispatch('USER_GET')
  },
  methods: {
    changeEmail(newEmail) {
      this.$toast('Change email option coming soon...')
    },
    async saveUser(firstName, lastName) {
      this.savingUser = true
      try {
        await this.$store.dispatch('USER_UPDATE', { firstName, lastName })
        this.$toastSuccess('Profile changes saved')
      } catch (e) {
        this.$toastError('There was an problem saving your changes')
      }
      this.savingUser = false
    },
  },
}
</script>

<template>
  <div>
    <div class="mt-5 md:mt-0">
      <form @submit.prevent="saveUser(firstName, lastName)">
        <div class="bg-white ring-1 ring-black ring-opacity-5 sm:rounded-lg sm:overflow-hidden">
          <div class="px-4 py-5 sm:p-6">
            <h3 class="text-xl font-medium leading-6 text-neutral-700">Personal Information</h3>
            <p class="mt-2 mb-6 leading-5 text-gray-500">
              Tell us a little about yourself
            </p>

            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 sm:col-span-3">
                <label for="first_name" class="block mb-1 text-sm font-medium leading-5 text-gray-700"
                  >First name</label
                >
                <input
                  id="first_name"
                  v-model="firstName"
                  autocomplete="off"
                  type="text"
                  class="block w-full form-input"
                />
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label for="last_name" class="block mb-1 text-sm font-medium leading-5 text-gray-700">Last name</label>
                <input
                  id="last_name"
                  v-model="lastName"
                  autocomplete="off"
                  type="text"
                  class="block w-full form-input"
                />
              </div>
            </div>
            <div class="mt-5">
              <BaseButton :loading="savingUser">Save</BaseButton>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="mt-8">
      <div class="mt-5 md:mt-0">
        <form @submit.prevent="changeEmail(newEmail)">
          <div class="overflow-hidden bg-white ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <div class="px-4 py-5 sm:p-6">
              <h3 class="text-xl font-medium leading-6 text-neutral-700">Authentication</h3>
              <p class="mt-2 mb-6 leading-5 text-gray-500">
                Update the email you use to login
              </p>
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-3">
                  <label for="existing_email" class="block mb-1 text-sm font-medium leading-5 text-gray-700">
                    Existing email
                  </label>
                  <input
                    id="existing_email"
                    type="text"
                    :value="$user().email"
                    disabled
                    class="block w-full bg-gray-100 form-input"
                  />
                </div>
                <div class="col-span-3">
                  <label for="new_email" class="block mb-1 text-sm font-medium leading-5 text-gray-700">
                    New email
                  </label>
                  <input
                    id="new_email"
                    v-model="newEmail"
                    type="email"
                    autocomplete="off"
                    required
                    class="block w-full form-input"
                  />
                </div>
              </div>
              <div class="mt-5">
                <BaseButton theme="secondary">Change email</BaseButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
