<script>
import BaseButton from '@/components/Base/Button'
import Spinner from '@/components/Base/Spinner'

export default {
  name: 'UserInvite',
  components: {
    BaseButton,
    Spinner,
  },
  props: {},
  data() {
    return {
      invite: null,
      acceptingInvite: false,
      loading: false,
      loggingIn: false,
      userDataError: null,
      email: '',
      password: '',
      registering: false,
      firstName: '',
      lastName: '',
    }
  },
  async created() {
    this.loading = true
    try {
      this.invite = await this.$store.dispatch('USER_GET_INVITE', this.$route.params.inviteId)
      this.email = this.invite.email
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
    this.loading = false
  },
  methods: {
    async acceptInvite(inviteId) {
      try {
        this.acceptingInvite = true
        const { workspace } = await this.$store.dispatch('USER_ACCEPT_INVITE', inviteId)
        this.$store.dispatch('USER_SET_WORKSPACE', workspace)
        this.$toastSuccess(`Invitation accepted`)
        if (this.$route.path !== '/setup') this.$router.push('/setup')
      } catch (e) {
        this.$toastError('There was a problem joining the workspace')
      }
      this.acceptingInvite = false
    },

    async login(email, password) {
      this.loggingIn = true
      this.userDataError = null
      try {
        window.localStorage.setItem('emailForSignIn', email)
        await this.$store.dispatch('USER_LOGIN', { email, password })
        await this.acceptInvite(this.invite.id)
      } catch (err) {
        if (err && err.code === 'auth/user-not-found')
          this.userDataError = 'User not found, please try a different email'
        else if (err && err.code === 'auth/wrong-password') this.userDataError = 'Incorrect username & password'
        else if (err && err.message) this.userDataError = err.message
        else this.userDataError = 'There was a problem signing you in.  Please refresh the page and try again.'
      }
      this.loggingIn = false
    },
    async register(firstName, lastName, password) {
      try {
        this.registering = true
        await this.$store.dispatch('USER_SIGNUP', { email: this.invite.email, password })
        await this.$store.dispatch('USER_CREATE', { firstName, lastName })
        await this.acceptInvite(this.invite.id)
        this.$emit('success')
        this.$toastSuccess('Registered successfully')
      } catch (e) {
        this.$toastError('Error', e.message)
      }
      this.registering = false
    },
  },
}
</script>

<template>
  <div>
    <!-- LOADING SPINNER -->
    <transition name="fade-in">
      <div v-if="loading" class="flex items-center justify-center min-h-screen px-4 py-12 sm:px-6 lg:px-8">
        <Spinner classes="h-8 w-8 text-teal-500" />
      </div>
    </transition>

    <!-- Invite does not exist -->
    <transition name="fade-in">
      <div v-if="!loading && !invite" class="flex items-center justify-center min-h-screen px-4 py-12 sm:px-6 lg:px-8">
        <div class="max-w-xs p-8 text-center bg-white rounded">
          <div class="mb-4">
            <svg
              class="m-auto text-purple-600 w-14 h-14"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
              ></path>
            </svg>
          </div>
          <div class="text-lg font-medium text-gray-700">
            This invitation is invalid or no longer exists.
          </div>
          <div class="block mt-8">
            <BaseButton theme="secondary" size="sm" @click="$router.push('/campaigns')">Back to the app</BaseButton>
          </div>
        </div>
      </div>
    </transition>

    <!-- ACCEPT INVITE -->
    <transition name="fade-in">
      <div v-if="!loading && invite" class="px-4 py-12 sm:px-6 lg:px-8">
        <div class="relative max-w-lg px-6 py-8 m-auto text-center">
          <h1 class="mb-2 text-3xl font-bold tracking-tighter text-gray-700">You've been invited</h1>
          <p class="mb-10 text-lg tracking-tight text-gray-600">
            to join the
            <span class="inline-block p-2 font-medium leading-4 text-purple-600 bg-purple-100 rounded">
              {{ invite.workspace.name }}
            </span>
            workspace on Proof
          </p>
        </div>
        <div v-if="!loading && invite && !invite.existingUser" class="max-w-lg m-auto">
          <div class="relative pt-8 pb-12 mb-6 bg-white rounded-lg shadow-xl pl-28 pr-9">
            <div class="absolute top-8 left-7">
              <div class="inline-block p-3 mb-3 text-center text-teal-500 bg-gray-100 rounded-full">
                <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
            <form @submit.prevent="register(firstName, lastName, password)">
              <h3 class="mb-1 text-2xl font-semibold tracking-tight">Get started</h3>
              <p class="text-gray-400"> Please fill out your details to get started. </p>
              <div class="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div class="sm:col-span-3">
                  <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">
                    First name
                  </label>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      id="first_name"
                      v-model="firstName"
                      type="text"
                      required
                      :disabled="registering"
                      class="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">
                    Last name
                  </label>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      id="last_name"
                      v-model="lastName"
                      type="text"
                      required
                      :disabled="registering"
                      class="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>

                <div class="sm:col-span-6">
                  <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
                    Email address
                  </label>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      id="email"
                      v-model="email"
                      type="email"
                      disabled
                      class="block w-full transition duration-150 ease-in-out bg-gray-100 form-input sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>

                <div class="sm:col-span-6">
                  <label for="password" class="block text-sm font-medium leading-5 text-gray-700">
                    Password
                  </label>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      id="password"
                      v-model="password"
                      type="password"
                      class="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-6 text-left">
                <BaseButton size="md" :loading="registering">Register</BaseButton>
              </div>
            </form>
          </div>
        </div>
        <div v-else-if="!loading && invite && invite.existingUser" class="text-center">
          <div v-if="!$user().id || loggingIn">
            <form class="max-w-sm m-auto" @submit.prevent="login(email, password)">
              <div>
                <label for="email" class="block text-sm font-medium leading-5 text-left text-gray-700">
                  Email address
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input id="email" v-model="email" type="email" required class="block w-full form-input" />
                </div>
              </div>

              <div class="mt-6">
                <label for="password" class="block text-sm font-medium leading-5 text-left text-gray-700">
                  Password
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input id="password" v-model="password" type="password" required class="block w-full form-input" />
                </div>
              </div>

              <div class="flex items-center justify-between mt-6">
                <div class="text-sm leading-5">
                  <a
                    href="#"
                    class="font-medium text-blue-600 transition duration-150 ease-in-out hover:text-blue-500 focus:outline-none focus:underline"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div class="mt-6">
                <span class="block w-full rounded-md shadow-sm">
                  <BaseButton :loading="loggingIn">
                    Sign in and accept
                  </BaseButton>
                </span>
              </div>
              <div class="relative">
                <p
                  v-if="userDataError"
                  class="absolute top-0 left-0 right-0 p-3 mt-4 text-sm font-medium tracking-tight text-center text-red-600 bg-red-200 rounded"
                >
                  {{ userDataError }}
                </p>
              </div>
            </form>
          </div>
          <div v-else-if="invite.email === $user().email">
            <BaseButton @click="acceptInvite(invite.id)">Accept invite</BaseButton>
          </div>
          <div v-else>
            This invitation is for a different user.
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
